<template>
  <div class="content">

    <validation-observer ref="VFormDokter">

      <b-form @submit.prevent="doSubmitDokter" class="card">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-12 form-underlying">
                <!-- Accordion-->
                <div class="accordion no-slide-accordion" role="tablist">
                  <b-card no-body class="mb-1" id="subjectif">
                    <b-card-header header-tag="header" class="p-0 bor_line_bottom" role="tab">
                      <b-button block @click.stop.prevent="toggleCardCollapse($event, 'subjective')" variant="false" class="text-left">
                        <div class="label_code mr-1">
                          <h2>S</h2>
                        </div><strong>Subjective</strong>
                        <span class="when-opened">
                          <i class="icon-arrow-up12"></i>
                        </span>
                        <span class="when-closed">
                          <i class="icon-arrow-down12"></i>
                        </span>
                      </b-button>
                    </b-card-header>
                    <b-collapse class="mt-2" v-model="cardKajianCollapse['subjective']" visible role="tabpanel">
                      <b-card-body class="p-0">
                        <div class="wrap_line">
                          <!-- <h3><span>Subjective</span></h3>-->
                          <div class="row">
                            <div class="col-md">
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label>Keluhan Utama <small class="txt_mandatory">*</small></label>
                                    <b-form-textarea v-model="row.apsd_keluhan" :formatter="$parent.normalText"
                                      name="perRiwayat" id="perRiwayat" rows="2" class="form-control"
                                      placeholder="Keluhan Utama dari pasien">
                                    </b-form-textarea>

                                    <VValidate name="Keluhan Utama" v-model="row.apsd_keluhan"
                                      :rules="toValidate(mrValidation.apsd_keluhan)" />
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-group">
                                    <div>
                                      <label for="perRiwayat">Riwayat Penyakit</label>
                                      <b-form-textarea v-model="row.apsd_anamnesa" :formatter="$parent.normalText"
                                        name="perRiwayat" id="perRiwayat" rows="2" class="form-control"
                                        placeholder="Riwayat penyakit dari pasien">
                                      </b-form-textarea>
                                    </div>

                                    <VValidate name="Riwayat Penyakit" v-model="row.apsd_anamnesa"
                                      :rules="toValidate(mrValidation.apsd_anamnesa)" />
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="">Catatan Dokter</label>
                                    <textarea v-model="row.apsd_catatan_dokter" class="form-control"
                                      rows="2"></textarea>
                                  </div>
                                </div>
                              </div>

                              <div class="row">

                                <!-- Status Kehamilan, muncul hanya jika jenis kelamin adalah perempuan -->
                                <div class="col-md-8  mb-3"
                                  v-if="(row.ap_gender == 2 || row.ap_gender == 0) && row.ap_usia >= 12 && row.ap_usia <= 60">
                                  <div class="card mb-0">
                                    <div class="card-header bg_head_panel">
                                      <h6 class="card-title font-weight-semibold">Status Kehamilan</h6>
                                    </div>
                                    <div class="card-body p-3">
                                      <div class="row gx-6">
                                        <div class="col-md-6 col-lg-4 col-xl-3">
                                          <div v-if="row.show_is_hamil == 'Y'">
                                            <label for="perHamil">Pasien Sedang Hamil?<strong
                                                class="text-danger">*</strong></label>

                                            <b-form-radio-group :options="Config.mr.yesNoOptV2"
                                              v-model="row.apod_is_hamil" />
                                            <VValidate name="Pasien Hamil" v-model="row.apod_is_hamil"
                                              :rules="toValidate(mrValidation.apod_is_hamil)" />
                                          </div>

                                          <div class="mt-3" v-if="row.show_is_menyusui == 'Y'">
                                            <label for="perMenyusui">Pasien Menyusui?<strong
                                                class="text-danger">*</strong></label>
                                            <div>
                                              <b-form-radio-group :options="Config.mr.yesNoOptV2"
                                                v-model="row.apod_is_menyusui" />
                                              <VValidate name="Pasien Menyusui" v-model="row.apod_is_menyusui"
                                                :rules="toValidate(mrValidation.apod_is_menyusui)" />
                                            </div>
                                          </div>

                                          <span v-if="row.show_is_hamil !== 'Y' && row.show_is_menyusui !== 'Y'">
                                            -
                                          </span>
                                        </div>
                                        <div class="col-md border-left" v-if="row.apod_is_hamil == 'Y'">

                                          <div class="row g-2 mb-2">
                                              <div class="col-md-8">
                                                  <label for="perRiwayat">Tinggi Fundus Uteri</label>
                                                  <b-form-textarea  v-model="row.apod_tinggi_fungus" :formatter="normalText"  name="perRiwayat" id="perRiwayat" rows="2" class="form-control" placeholder="Tinggi Fundus Uteri"></b-form-textarea>
                                              </div>
                                          </div>
                                    
                                          <div class="row g-2">
                                            <div class="col-md-4">
                                              <div>
                                                <label for="perGravid">Gravid<strong
                                                    class="text-danger">*</strong></label>
                                                <b-form-input :formatter="$parent.number" v-model="row.apod_gravid"
                                                  type="text" class="form-control" />
                                                <VValidate name="Gravid" v-model="row.apod_gravid"
                                                  :rules="toValidate(mrValidation.apod_gravid)" />
                                              </div>
                                            </div>
                                            <div class="col-md-4">
                                              <div>
                                                <label for="perPara">Para<strong class="text-danger">*</strong></label>
                                                <b-form-input :formatter="$parent.number" v-model="row.apod_para"
                                                  type="text" class="form-control" />
                                                <VValidate name="Para" v-model="row.apod_para"
                                                  :rules="toValidate(mrValidation.apod_para)" />
                                              </div>
                                            </div>
                                            <div class="col-md-4">
                                              <div>
                                                <label for="perAbortus">Abortus<strong
                                                    class="text-danger">*</strong></label>
                                                <b-form-input :formatter="$parent.number" v-model="row.apod_abortus"
                                                  type="text" class="form-control" />
                                                <VValidate name="Para" v-model="row.apod_abortus"
                                                  :rules="toValidate(mrValidation.apod_abortus)" />
                                              </div>
                                            </div>
                                            <div class="col-md-4">
                                              <div>
                                                <label for="perHpht">HPHT</label>
                                                <div class="input-group mb-3">
                                                  <datepicker input-class="form-control transparent"
                                                    placeholder="Pilih Tanggal" @input="changeHPL" class="my-datepicker"
                                                    calendar-class="my-datepicker_calendar" v-model="row.apod_hpht">
                                                  </datepicker>
                                                  <div class="input-group-append calendar-group">
                                                    <span class="input-group-text" id="basic-addon2"><i
                                                        class="icon-calendar"></i></span>
                                                  </div>
                                                </div>
                                              </div>
                                              <VValidate name="HPHT" v-model="row.apod_hpht"
                                                :rules="toValidate(mrValidation.apod_hpht)" />
                                            </div>
                                            <div class="col-md-4">
                                              <div>
                                                <label for="perHpl">HPL</label>
                                                <div class="input-group mb-3">
                                                  <datepicker input-class="form-control transparent"
                                                    placeholder="Tanggal HPL" class="my-datepicker"
                                                    calendar-class="my-datepicker_calendar" v-model="row.apod_hpl">
                                                  </datepicker>
                                                  <div class="input-group-append calendar-group">
                                                    <span class="input-group-text" id="basic-addon2"><i
                                                        class="icon-calendar"></i></span>
                                                  </div>
                                                </div>
                                              </div>
                                              <VValidate name="HPL" v-model="row.apod_hpl"
                                                :rules="toValidate(mrValidation.apod_hpl)" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="text-right btn_hitory mb-3">
                                <a href="javascript:;" v-if="riwayatKeluhan.length" @click="openRiwayatKeluhan = true"
                                  class="btn alpha-info border-info text-info-800" data-toggle="modal"
                                  data-target="#keluhanHistory">
                                  <i class="icon-list align-middle mr-2"></i>
                                  <span class="align-middle">Ambil dari Riwayat</span>
                                </a>
                              </div>

                              <div class="row">
                                <div class="col-md-7">
                                  <div class="head_panel_red">
                                    <div class="d-flex justify-content-between align-items-center">
                                      <h3>ALERGI </h3>
                                      <div>
                                        <b-form-radio-group @input="changeAlergi($event)" :options="Config.mr.yesNoOpt"
                                          v-model="row.apsd_has_alergi" />
                                        <VValidate name="Alergi" v-model="row.apsd_has_alergi"
                                          :rules="toValidate(mrValidation.apsd_has_alergi)" />
                                      </div>
                                    </div>
                                  </div>

                                  <template v-if="row.apsd_has_alergi == 'Y'">
                                    <table class="table table-sm table-bordered">
                                      <thead>
                                        <tr>
                                          <th width="33%">Jenis</th>
                                          <th>Informasi Alergi</th>
                                          <th width="64"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="(v,k) in (row.apsd_alergi||[])" :key="k+'aler'">
                                          <td>
                                            <v-select placeholder="Pilih Jenis" v-model="row.apsd_alergi[k]['jenis']"
                                              :options="Config.mr.jenisAlergi" label="text" :clearable="true"
                                              :reduce="v=>v.value"></v-select>
                                            <VValidate :name="'Jenis '+(k+1)" message="Jenis Harus Diisi"
                                              v-model="row.apsd_alergi[k]['jenis']" :rules="{required:1}" />
                                          </td>
                                          <td>
                                            <vue-typeahead-bootstrap :formatter="$parent.normalText"
                                              v-model="row.apsd_alergi[k]['name']" :data="mAlergi"
                                              @input="searchAlergi(row.apsd_alergi[k]['name'])"
                                              placeholder="Pilih Alergi" />
                                            <VValidate :name="'Obat '+(k+1)" message="Obat Harus Diisi"
                                              v-model="row.apsd_alergi[k]['name']" :rules="{required:1}" />
                                          </td>
                                          <td>
                                            <a href="javascript:;" @click="removeAlergi(k)" data-popup="tooltip"
                                              title="Hapus"
                                              class="btn btn-sm btn-icon border-danger rounded-round text-danger-800 alpha-danger"><i
                                                class="icon-trash"></i></a>
                                          </td>
                                        </tr>
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td colspan="3" class="text-center">
                                            <a href="javascript:;" @click="addAlergi()"
                                              class="btn btn-sm alpha-info border-info"><i
                                                class="icon-plus2 mr-1"></i>Tambah</a>
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </template>
                                  <template v-else>
                                    <table class="table table-sm table-bordered">
                                      <thead>
                                        <tr>
                                          <th width="33%">Jenis</th>
                                          <th>Informasi Alergi</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <v-select placeholder="Pilih Jenis" disabled
                                              :options="Config.mr.jenisAlergi" label="text" :clearable="true"
                                              :reduce="v=>v.value"></v-select>
                                          </td>
                                          <td>
                                            <input disabled type="text" class="form-control"
                                              placeholder="cth. amoxicilin">
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </template>
                                </div>
                                <div class="col-md-5">
                                  <div class="head_panel_blue">
                                    <div class="d-flex justify-content-between align-items-center">
                                      <h3>OBAT RUTIN </h3>
                                      <div>
                                        <b-form-radio-group :options="Config.mr.yesNoOpt"
                                          v-model="row.apsd_has_obat_rutin" />
                                        <VValidate name="Alergi" v-model="row.apsd_has_obat_rutin"
                                          :rules="toValidate(mrValidation.apsd_has_obat_rutin)" />
                                      </div>
                                    </div>
                                  </div>

                                  <div class="row mt-2" v-if="row.apsd_has_obat_rutin == 'Y'">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label>Keterangan </label>
                                        <b-form-textarea v-model="row.apsd_keterangan_obat_rutin" rows="2" cols="3"
                                          class="form-control" placeholder="" spellcheck="false"></b-form-textarea>
                                      </div>

                                      <VValidate name="Keterangan" v-model="row.apsd_keterangan_obat_rutin"
                                        :rules="toValidate(mrValidation.apsd_keterangan_obat_rutin)" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>

                  <b-card no-body class="mb-1" id="objectif">
                    <b-card-header header-tag="header" class="p-0" role="tab">
                      <b-button block @click.stop.prevent="toggleCardCollapse($event, 'objective')" variant="false" class="text-left">
                        <div class="label_code mr-1">
                          <h2>O</h2>
                        </div><strong>Objective</strong>
                        <span class="when-opened">
                          <i class="icon-arrow-up12"></i>
                        </span>
                        <span class="when-closed">
                          <i class="icon-arrow-down12"></i>
                        </span>
                      </b-button>
                    </b-card-header>
                    <b-collapse class="mt-2" v-model="cardKajianCollapse['objective']" role="tabpanel">
                      <b-card-body class="p-0">
                        <div class="wrap_line">

                          <div class="row">
                            <div class="col-md">
                              <div class="row">
                                <div class="col-md-8">
                                  <div class="form-group">
                                    <label>Keadaan Umum</label>
                                      <b-form-textarea v-model="row.apod_desc" rows="2" cols="3"
                                      class="form-control" placeholder="" spellcheck="false">
                                      </b-form-textarea>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="card mb-0">
                                    <div class="card-header bg_head_panel">
                                      <h6 class="card-title font-weight-semibold">Tanda Tanda
                                        Vital</h6>
                                    </div>

                                    <div class="card-body p-3">
                                      <div class="row">
                                        <div class="col-md-4">
                                          <div class="form-group">
                                            <label>Tekanan Darah</label>
                                            <div class="input-group">
                                              <b-form-input :formatter="$parent.number"
                                                v-model="row.apod_ttv_tekanan_darah_min" type="text" name="name"
                                                class="form-control" placeholder="Systole" />

                                              <div class="input-group-append input-group-prepend">
                                                <span class="input-group-text">/</span>
                                              </div>

                                              <b-form-input :formatter="$parent.number" placeholder="Diastole"
                                                v-model="row.apod_ttv_tekanan_darah_max" type="text"
                                                class="form-control" />
                                              <div class="input-group-append"><span class="input-group-text">mmHG</span>
                                              </div>
                                            </div>
                                            <VValidate name="Tekanan Darah Min" v-model="row.apod_ttv_tekanan_darah_min"
                                              :rules="toValidate(mrValidation.apod_ttv_tekanan_darah_min)" />
                                            <VValidate name="Tekanan Darah Max" v-model="row.apod_ttv_tekanan_darah_max"
                                              :rules="toValidate(mrValidation.apod_ttv_tekanan_darah_max)" />
                                          </div>
                                        </div>

                                        <div class="col-md-5">
                                          <div class="form-group">
                                            <label>Nadi</label>
                                            <div class="form-row">
                                              <div class="col-md-12">
                                                <div class="input-group">
                                                  <b-form-input :formatter="$parent.number" v-model="row.apod_ttv_nadi"
                                                    type="text" class="form-control" />
                                                  <div class="input-group-append">
                                                    <span class="input-group-text">x/mnt</span>
                                                  </div>
                                                  <div class="input-group-append">
                                                    <div style="width: 140px;">
                                                      <v-select placeholder="Pilih Label" v-model="row.apod_ttv_label"
                                                        :options="Config.mr.StatusRegular" label="text"
                                                        :clearable="true" :reduce="v=>v.value"></v-select>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <VValidate name="Nadi" v-model="row.apod_ttv_nadi"
                                              :rules="toValidate(mrValidation.apod_ttv_nadi)" />

                                            <VValidate name="Label" v-model="row.apod_ttv_label"
                                              :rules="toValidate(mrValidation.apod_ttv_label)" />
                                          </div>
                                        </div>

                                        <div class="col-md-3">
                                          <div class="form-group">
                                            <label>Gula Darah</label>
                                            <div class="form-row">
                                              <div class="col-md-12">
                                                <div class="input-group">
                                                  <b-form-input :formatter="$parent.number"
                                                    v-model="row.apod_ttv_gula_darah" type="text"
                                                    class="form-control" />
                                                  <div class="input-group-append">
                                                    <span class="input-group-text">mg/dL</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <VValidate name="Gula Darah" v-model="row.apod_ttv_gula_darah"
                                              :rules="toValidate(mrValidation.apod_ttv_gula_darah)" />
                                          </div>
                                        </div>

                                        <div class="col-md-4">
                                          <div class="form-group">
                                            <label>Pernafasan</label>
                                            <div class="input-group">
                                              <b-form-input :formatter="$parent.number"
                                                v-model="row.apod_ttv_pernafasan" type="text" class="form-control" />
                                              <div class="input-group-append"><span
                                                  class="input-group-text">x/mnt</span>
                                              </div>
                                            </div>
                                          </div>

                                          <VValidate name="Pernafasan" v-model="row.apod_ttv_pernafasan"
                                            :rules="toValidate(mrValidation.apod_ttv_pernafasan)" />
                                        </div>

                                        <div class="col-md-3">
                                          <div class="form-group">
                                            <label>SPO2</label>
                                            <div class="form-row">
                                              <div class="col-md-12">
                                                <div class="input-group">
                                                  <b-form-input :formatter="$parent.number" v-model="row.apod_ttv_spo2"
                                                    type="text" class="form-control" />
                                                  <div class="input-group-append">
                                                    <span class="input-group-text">%</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <VValidate name="SPO2" v-model="row.apod_ttv_spo2"
                                              :rules="toValidate(mrValidation.apod_ttv_spo2)" />
                                          </div>
                                        </div>

                                        <div class="col-md-2">
                                          <div class="form-group">
                                            <label>Suhu</label>
                                            <div class="form-row">

                                              <div class="col-md-12">
                                                <div class="input-group">
                                                  <b-form-input :formatter="$parent.number" v-model="row.apod_ttv_suhu"
                                                    type="text" class="form-control" />
                                                  <div class="input-group-append">
                                                    <span class="input-group-text">C</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <VValidate name="Suhu" v-model="row.apod_ttv_suhu"
                                              :rules="toValidate(mrValidation.apod_ttv_suhu)" />
                                          </div>
                                        </div>

                                        <div class="col-md-2">
                                          <div class="form-group">
                                            <label>Berat Badan</label>
                                            <div class="form-row">

                                              <div class="col-md-12">
                                                <div class="input-group">
                                                  <b-form-input @input="hitungBMI()" :formatter="$parent.number"
                                                    v-model="row.apod_ttv_weight" type="text" class="form-control" />
                                                  <div class="input-group-append">
                                                    <span class="input-group-text">kg</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <VValidate name="Berat Badan" v-model="row.apod_ttv_weight"
                                              :rules="toValidate(mrValidation.apod_ttv_weight)" />
                                          </div>
                                        </div>


                                        <div class="col-md-2">
                                          <div class="form-group">
                                            <label>Tinggi</label>
                                            <div class="form-row">

                                              <div class="col-md-12">
                                                <div class="input-group">
                                                  <b-form-input @input="hitungBMI()" :formatter="$parent.number"
                                                    v-model="row.apod_ttv_height" type="text" class="form-control" />
                                                  <div class="input-group-append">
                                                    <span class="input-group-text">cm</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <VValidate name="Tinggi Badan" v-model="row.apod_ttv_height"
                                              :rules="toValidate(mrValidation.apod_ttv_height)" />
                                          </div>
                                        </div>

                                        <div class="col-md-3">
                                          <div class="form-group">
                                            <label>Lingkar Kepala</label>
                                            <div class="form-row">

                                              <div class="col-md-12">
                                                <div class="input-group">
                                                  <b-form-input :formatter="$parent.number"
                                                    v-model="row.apod_ttv_lingkar_kepala" type="text"
                                                    class="form-control" />
                                                  <div class="input-group-append">
                                                    <span class="input-group-text">cm</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <VValidate name="Lingkar Kepala" v-model="row.apod_ttv_lingkar_kepala"
                                              :rules="toValidate(mrValidation.apod_ttv_lingkar_kepala)" />
                                          </div>
                                        </div>

                                        <div class="col-md-3" v-if="row.ap_usia <= 15">
                                          <div class="form-group">
                                            <label>Luas Permukaan Tubuh Anak</label>
                                            <div class="input-group">
                                              <b-form-input :formatter="$parent.number"
                                                v-model="row.apod_ttv_luas_permukaan_anak" type="text"
                                                class="form-control" />
                                              <div class="input-group-append"><span
                                                  class="input-group-text">m<sup>2</sup></span></div>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="col-md-3" v-if="row.ap_usia >= 1">
                                          <div class="form-group">
                                            <label>BMI</label>
                                            <div class="form-row">
                                              <div class="col-md-12">
                                                <div class="input-group">
                                                  <b-form-input disabled v-model="row.apod_ttv_bmi" type="text"
                                                    class="form-control" />
                                                  <div class="input-group-append">
                                                    <span class="input-group-text">m2</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <VValidate name="BMI" v-model="row.apod_ttv_bmi"
                                              :rules="toValidate(mrValidation.apod_ttv_bmi)" />
                                          </div>
                                        </div>

                                        <div class="col-md-3">
                                          <div class="form-group">
                                            <label>Kesadaran</label>
                                            <div class="form-row">
                                              <div class="col-md-12">
                                                <v-select placeholder="Pilih Kesadaran" v-model="row.apod_ttv_kesadaran"
                                                  :options="mKesadaran" label="text" :clearable="true"
                                                  :reduce="v=>v.value">
                                                  <template slot="selected-option" slot-scope="option">
                                                    <span v-b-tooltip.hover
                                                      :title="option.text">{{ option.text }}</span>
                                                  </template>
                                                  <template slot="option" slot-scope="option">
                                                    <span v-b-tooltip.hover
                                                      :title="option.text">{{ option.text }}</span>
                                                  </template>
                                                </v-select>
                                              </div>
                                            </div>
                                            <VValidate name="Kesadaran" v-model="row.apod_ttv_kesadaran"
                                              :rules="toValidate(mrValidation.apod_ttv_kesadaran)" />
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>

                                  <div class="card mb-2">
                                    <div class="card-header bg_head_panel">
                                      <div class="d-flex align-items-center justify-content-between">
                                        <h6 class="card-title font-weight-semibold">Pemeriksaan Fisik</h6>
                                        <!-- <i class="icon-arrow-down22"></i> -->
                                      </div>
                                    </div>
                                    <div class="card-body p-3">
                                      <div class="alert alert-warning mb-0">Mohon isi Pemeriksaan Fisik jika ditemukan
                                        kelainan pada keadaan fisik pasien.</div>
                                      <div class="mt-3">
                                        <div class="card border shadow-0 mb-0">
                                          <div class="bg-light card-header c-pointer"
                                            @click="pemeriksaanFisik = !pemeriksaanFisik"
                                            :class="pemeriksaanFisik ? null : 'collapsed'"
                                            :aria-expanded="pemeriksaanFisik ? 'true' : 'false'"
                                            aria-controls="GenPartRow">
                                            <div class="d-flex align-items-center justify-content-between">
                                              <span class="card-title font-weight-semibold">Klik untuk
                                                {{ pemeriksaanFisik ? "Menyembunyikan" : "Menampilkan" }} Bagian Keadaan
                                                Umum</span>
                                              <i class="icon-arrow-down22" style="font-size: 20px;"></i>
                                            </div>
                                          </div>
                                          <b-collapse id="GenPartRow" v-model="pemeriksaanFisik">
                                            <div class="row g-0">
                                              <div class="col-md-6" v-for="(genCol, genColKey) in oddEvenGenCondition"
                                                :key="genColKey+'genCol'">
                                                <div class="gen-part" v-for="(vs,ks) in genCol" :key="ks+'sign'">
                                                  <div
                                                    class="d-flex align-items-center justify-content-between gen-part-head c-pointer"
                                                    @click="row[vs.value+'isOpen'] = !row[vs.value+'isOpen']"
                                                    :class="row[vs.value+'isOpen'] ? null : 'collapsed'">
                                                    <div class="font-weight-semibold">{{vs.label}}
                                                      <span class="text-danger" v-if="row[vs.value] == 'TN'">*</span>
                                                    </div>
                                                    <i class="icon-arrow-down22"></i>
                                                  </div>

                                                  <b-collapse :id="vs.value" v-model="row[vs.value+'isOpen']"
                                                    accordion="accKeadaanUmum">
                                                    <div class="gen-part-body mt-1">
                                                      <div>
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                          <input type="radio" v-model="row[vs.value]"
                                                            @input="changeVital(vs.value,'N')"
                                                            class="custom-control-input" :name="`formType${vs.label}`"
                                                            :id="`normalCheck${vs.label}`" value="N">
                                                          <label class="custom-control-label"
                                                            :for="`normalCheck${vs.label}`">
                                                            Normal
                                                          </label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                          <input type="radio" v-model="row[vs.value]"
                                                            @input="changeVital(vs.value,'TN')"
                                                            class="custom-control-input" :name="`formType${vs.label}`"
                                                            :id="`notNormalCheck${vs.label}`" value="TN">
                                                          <label class="custom-control-label"
                                                            :for="`notNormalCheck${vs.label}`">
                                                            Tidak Normal
                                                          </label>
                                                        </div>
                                                        <div class="custom-control custom-radio custom-control-inline">
                                                          <input type="radio" v-model="row[vs.value]"
                                                            class="custom-control-input" :name="`formType${vs.label}`"
                                                            :id="`notCheck${vs.label}`" value="TP">
                                                          <label class="custom-control-label"
                                                            :for="`notCheck${vs.label}`">
                                                            Tidak Diperiksa
                                                          </label>
                                                        </div>
                                                      </div>
                                                      <div class="gen-input mt-2" v-if="row[vs.value] !== 'TP'">
                                                        <b-textarea :disabled="row[vs.value] == 'N'"
                                                          v-model="row[vs.value+'_text']" class="form-control"
                                                          :placeholder="`cth. pembengkakan pada bagian ${vs.label} sebelah kiri`"
                                                          rows="2"></b-textarea>

                                                        <VValidate :name="`${vs.label}`" v-model="row[vs.value+'_text']"
                                                          :rules="{required:1, min:2, max:512}" />
                                                      </div>
                                                    </div>
                                                  </b-collapse>
                                                </div>
                                              </div>
                                            </div>
                                          </b-collapse>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="card mb-0">
                                    <div class="card-header bg_head_panel">
                                      <h6 class="card-title font-weight-semibold">Status Lokalis</h6>
                                    </div>
                                    <div class="card-body p-3 pb-5">
                                      <div class="row gx-2">
                                        <div class="col-lg-4">
                                          <ul class="nav nav-pills nav-pills-bordered nav-pills-toolbar flex-column">
                                            <li class="nav-item" @click="changeTabAnat(k)"
                                              v-for="(v,k) in row.apod_lokasi_nyeri||[]" :key="k+'nav'">
                                              <a href="javascript:;" data-toggle="tab"
                                                :class="activeAnat == k ? 'nav-link active':'nav-link'">
                                                <div class="d-flex justify-content-between align-items-center">
                                                  <span>{{v.name||"(Anatomi Baru)"}}</span>
                                                  <div>
                                                    <i class="icon-chevron-right"></i>
                                                  </div>
                                                </div>
                                              </a>
                                            </li>
                                            <li class="nav-item border-top">
                                              <a href="javascript:;" @click="addAnatomi"
                                                class="nav-link border-top bg-light">
                                                <div class="d-flex justify-content-between align-items-center">
                                                  <span><i class="icon-plus2 mr-2"></i>Tambah Anatomi</span>
                                                </div>
                                              </a>
                                            </li>
                                            <li class="nav-item border-top">
                                              <a href="javascript:;" @click="deleteAnatomi(activeAnat)"
                                                class="nav-link border-top bg-light">
                                                <div class="d-flex justify-content-between align-items-center">
                                                  <span><i class="icon-trash mr-2"></i>Hapus Anatomi</span>
                                                </div>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                        <div class="col-lg-8">
                                          <div class="tab-content">
                                            <div v-for="(v,k) in row.apod_lokasi_nyeri||[]" :key="k+'navAnat'"
                                              :class="activeAnat == k ? 'tab-pane fade-show active' : 'tab-pane fade'">
                                              <div class="form-group">
                                                <label for="">Jenis Anatomi<strong
                                                    class="text-danger">*</strong></label>
                                                <div class="input-group flex-nowrap">
                                                  <v-select class="flex-fill" placeholder="Pilih Status Lokalis"
                                                    @input="selectStatusLokalis($event,k)" v-model="v.type"
                                                    :disabled="!v.isEdit"
                                                    :options="getStatusLokalis(row.apod_lokasi_nyeri,k)" label="text"
                                                    :reduce="v=>v.value">
                                                  </v-select>

                                                  <div class="input-group-append">
                                                    <button @click="editAnatomi(k)" type="button"
                                                      class="btn btn-info btn-labeled btn-labeled-left">
                                                      <b><i class="icon-pencil"></i></b>
                                                      Edit
                                                    </button>
                                                  </div>

                                                  <div class="input-group-append">
                                                    <button @click="saveAnatomi(k)" type="button"
                                                      class="btn btn-success btn-labeled btn-labeled-left">
                                                      <b><i class="icon-checkmark"></i></b>
                                                      Simpan
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>

                                              <div class="wrap_anatomy_info p-0 border-0" v-if="v.type">
                                                <div class="position-relative" :id="`anatomiNyeri${k}`"
                                                  @click.stop.prevent>
                                                  <div class="body_wrapper">
                                                    <img :src="$parent.assetLocal(v.image)" class="img-fluid w-100"
                                                      alt="">
                                                  </div>
                                                  <div class="anatomy-info" v-for="(v1,k1) in v.data||[]"
                                                    :key="k1+'body'" :style="`top:${v1.y}%;left:${v1.x}%`">
                                                    <a href="javascript:;" @click="openAnat(k1,k)" class="ai-point"
                                                      v-b-tooltip.hover :html="true" :title="v1.value"></a>
                                                    <div class="anatomy-info-input" :class="{
                                                'd-block': v1.isOpen,
                                                'reversed': v1.isReversed
                                              }" :style="v1.isReversed ? 'transform: translateX('+v1.reverseCount+')' : ''"
                                                      @click.stop.prevent>
                                                      <textarea v-model="v1.value" name="anatomyInput" id="anatomyInput"
                                                        rows="2" class="form-control form-control-sm"></textarea>
                                                      <small class="text-muted"><i class="icon-info22"></i> Keluhan
                                                        harus diisi</small>

                                                      <div class="mt-2 text-right">
                                                        <a href="javascript:;" class="btn btn-sm btn-danger"
                                                          @click.prevent.stop="removeAnat(k1,k)">Hapus</a>
                                                        <a href="javascript:;" @click.prevent.stop="closeAnat(k1,k)"
                                                          class="btn btn-sm btn-success">Simpan</a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <span v-if="!(row.apod_lokasi_nyeri||[]).length">Belum Ada Data Anatomi
                                              (Klik Tambah Untuk Menambahkan)</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>

                  <b-card no-body class="mb-1" id="assesment">
                    <b-card-header header-tag="header" class="p-0" role="tab">
                      <b-button block @click.stop.prevent="toggleCardCollapse($event, 'assessment')" variant="false" class="text-left">
                        <div class="label_code mr-1">
                          <h2>A</h2>
                        </div><strong>Assessment</strong>
                        <span class="when-opened">
                          <i class="icon-arrow-up12"></i>
                        </span>
                        <span class="when-closed">
                          <i class="icon-arrow-down12"></i>
                        </span>
                      </b-button>
                    </b-card-header>
                    <b-collapse class="mt-2" v-model="cardKajianCollapse['assessment']" role="tabpanel">
                      
                      <div class="col-md">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="card border shadow-0">
                              <div class="bg-light card-header py-2">
                                <div class="d-flex align-items-center justify-content-between">
                                  <h6 class="card-title mb-0 font-weight-semibold">Diagnosis</h6>
                                  <a href="javascript:;" v-if="riwayatICD10.length" @click="openriwayatICD10 = true" class="btn btn-sm alpha-orange border-orange text-orange-800">
                                    <i class="icon-list mr-2 align-middle"></i><span class="align-middle">Pilih dari Riwayat</span>
                                  </a>
                                </div>
                              </div>
                              <div class="card-body">
                                <div class="form-group mb-0">
                                  <div class="input-group">
                                    <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                                        <v-select class="diagnosa-section select-paging" placeholder="Pilih Diagnosa"
                                          v-model="inputICD10"
                                          @search="filterICD10($event,0)"
                                          @input="selectICD10(inputICD10)"
                                          :options="mICD10" label="text"
                                          :clearable="true" :reduce="v=>v.value">
                                          
                                          <template slot="selected-option">
                                            <span v-b-tooltip.hover.right :title="inputICD10Name">
                                              {{inputICD10Name||"-"}}
                                            </span>
                                          </template>
                                          
                                          <template slot="option" slot-scope="option">
                                            <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                          </template>
                                          <template #list-footer v-if="(mICD10||[]).length >= 1">
                                            <li class="list-paging">
                                              <div class="d-flex align-items-center justify-content-between">
                                                <b-button @click="changePageICD10(mICD10[0].currentPage,0,'min','mICD10')"
                                                  size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                                    class="icon-arrow-left22 text-white"></i></b-button>
                                                <small>Halaman {{mICD10[0].currentPage}} dari
                                                  {{ceilData(mICD10[0].totalRows/5)}}</small>
                                                <b-button @click="changePageICD10(mICD10[0].currentPage,0,'plus','mICD10')"
                                                  size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                                    class="icon-arrow-right22 text-white"></i></b-button>
                                              </div>
                                            </li>
                                          </template>
                                        </v-select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <table class="table table-bordered table-sm" v-if="(dataICD10||[]).length">
                                <thead>
                                  <tr>
                                    <th>Item ICD-10 dipilih</th>
                                    <th width="64">Aksi</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(v,k) in (dataICD10||[])" :key="k">
                                    <td><span>{{v.mi10_name||"-"}}<span class="font-weight-semibold">[{{v.mi10_code||"-"}}]</span></span></td>
                                    <td>
                                      <a href="javascript:;" @click="removedataICD10(v.api10_id, k)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                                        <i class="icon-trash-alt"></i>
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div class="card-body py-2" v-else>
                                <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
                              </div>
                              <div class="card-body pt-2">
                                <div class="form-group mb-0">
                                  <label for="inputManualDiagnose">
                                    Diagnosa Lainnya <strong class="txt_mandatory" v-if="!(dataICD10||[]).length">*</strong>
                                    <span style="cursor:help;" data-popup="tooltip" title="Silakan isi kolom dibawah untuk mengisi diagnosa secara manual"><i class="icon-info22 text-indigo"></i></span>
                                  </label>
                                  <b-textarea v-model="row.apod_diagnosa" name=""
                                  id="" rows="3" class="form-control"></b-textarea>
                                  
                                <VValidate v-if="!(dataICD10||[]).length" :name="`Diagnosa`" v-model="row.apod_diagnosa"
                                  :rules="{required:1, min:2, max:512}" />
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          <div class="col-md-6">
                            <div class="card border shadow-0">
                              <div class="bg-light card-header py-2">
                                <div class="d-flex align-items-center justify-content-between">
                                  <h6 class="card-title mb-0 font-weight-semibold">Tindakan</h6>
                                  <a href="javascript:;" v-if="riwayatICD9.length" @click="openriwayatICD9 = true" class="btn btn-sm alpha-orange border-orange text-orange-800">
                                    <i class="icon-list mr-2 align-middle"></i><span class="align-middle">Pilih dari Riwayat</span>
                                  </a>
                                </div>
                              </div>
                              <div class="card-body">
                                <div class="form-group mb-0">
                                  <div class="input-group">
                                    <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                                        <v-select class="diagnosa-section select-paging" placeholder="Pilih Tindakan"
                                          v-model="inputICD9"
                                          @search="filterICD9($event,0)"
                                          @input="selectICD9(inputICD9)"
                                          :options="mICD9" label="text"
                                          :clearable="true" :reduce="v=>v.value">
                                          
                                          <template slot="selected-option">
                                            <span v-b-tooltip.hover.right :title="inputICD9Name">
                                              {{inputICD9Name||"-"}}
                                            </span>
                                          </template>
                                          
                                          <template slot="option" slot-scope="option">
                                            <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                          </template>
                                          <template #list-footer v-if="(mICD9||[]).length >= 1">
                                            <li class="list-paging">
                                              <div class="d-flex align-items-center justify-content-between">
                                                <b-button @click="changePageICD9(mICD9[0].currentPage,0,'min','mICD9')"
                                                  size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                                    class="icon-arrow-left22 text-white"></i></b-button>
                                                <small>Halaman {{mICD9[0].currentPage}} dari
                                                  {{ceilData(mICD9[0].totalRows/5)}}</small>
                                                <b-button @click="changePageICD9(mICD9[0].currentPage,0,'plus','mICD9')"
                                                  size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                                    class="icon-arrow-right22 text-white"></i></b-button>
                                              </div>
                                            </li>
                                          </template>
                                        </v-select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <table class="table table-bordered table-sm" v-if="(dataICD9||[]).length">
                                <thead>
                                  <tr>
                                    <th>Item ICD-9 dipilih</th>
                                    <th width="64">Aksi</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(v,k) in (dataICD9||[])" :key="k">
                                    <td><span>{{v.mi9_name||"-"}}<span class="font-weight-semibold">[{{v.mi9_code||"-"}}]</span></span></td>
                                    <td>
                                      <a href="javascript:;" @click="removedataICD9(v.api9_id, k)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                                        <i class="icon-trash-alt"></i>
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div class="card-body py-2" v-else>
                                <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-9-CM dipilih</label>
                              </div>
                              <div class="card-body pt-2">
                                <div class="form-group mb-0">
                                  <label for="inputManualDiagnose">
                                    Tindakan Lainnya
                                    <span style="cursor:help;" data-popup="tooltip" title="Silakan isi kolom dibawah untuk mengisi tindakan secara manual"><i class="icon-info22 text-indigo"></i></span>
                                  </label>
                                  <b-textarea v-model="row.apod_tindakan" name=""
                                  id="" rows="3" class="form-control"></b-textarea>
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </b-card>

                  <b-card no-body class="mb-1" id="planning">
                    <b-card-header header-tag="header" class="p-0" role="tab">
                      <b-button block @click.stop.prevent="toggleCardCollapse($event, 'planning')" variant="false" class="text-left">
                        <div class="label_code mr-1">
                          <h2>P</h2>
                        </div><strong>Planning</strong>
                        <span class="when-opened">
                          <i class="icon-arrow-up12"></i>
                        </span>
                        <span class="when-closed">
                          <i class="icon-arrow-down12"></i>
                        </span>
                      </b-button>
                    </b-card-header>
                    <b-collapse class="mt-2" v-model="cardKajianCollapse['planning']" role="tabpanel">
                      <b-card-body class="p-0">
                        <div class="wrap_line">
                          <div class="row">

                            <div class="col-md">
                              <div class="row g-2">
                                
                                <div class="col-md-12 col-lg-12">
                                  <div class="form-group mb-0 col-6">
                                    <label for="">Perencanaan</label>
                                    <b-textarea v-model="row.apod_plan" name=""
                                      id="" rows="3" class="form-control"></b-textarea>
                                  </div>
                                </div>
                                

                                <div class="col-md-12 col-lg-12">
                                  <div class="card mb-0">
                                    <div class="card-header bg_head_panel">
                                      <div class="row">
                                        <h6 class="card-title font-weight-semibold col-md-4">Laboratorium</h6>
                                        <div class="col-md-8">
                                          <b-form-checkbox :disabled="!canEditLab" @input="changeLab($event)"
                                            v-model="row.appdl_is_lab" value="Y" unchecked-value="N">
                                            Apakah Ada Pengkajian Laboratorium
                                          </b-form-checkbox>
                                        </div>
                                      </div>
                                    </div>
                                    <template v-if="row.appdl_is_lab == 'Y'">
                                      <div class="card-body p-3">
                                        <div class="row g-2">
                                          <div class="col-md-4">
                                            <div>
                                              <label>Prioritas Pemeriksaan <strong
                                                  class="text-danger">*</strong></label>
                                              <b-form-radio-group :disabled="!canEditLab"
                                                :options="Config.mr.configPrioritasPemeriksaan"
                                                v-model="row['appdl_prioritas']" />
                                            </div>
                                            <VValidate v-if="row.appdl_is_lab == 'Y'" :name="'Prioritas Pemeriksaan'"
                                              v-model="row.appdl_prioritas"
                                              :rules="toValidate(mrValidation.appdl_prioritas)" />
                                          </div>
                                        </div>
                                      </div>
                                      <div class="card-body p-3">
                                        <div class="req-list-resume">
                                          <div class="row g-3">
                                            <template v-for="(v,k) in row.appdl_data">
                                              <div class="col-md-4 col-lg-3" :key="k+'labData'" v-if="isShowHeadLab(k)">
                                                <div class="card shadow-0 border">
                                                  <div class="card-header py-2 bg-light">
                                                    <h6 class="card-title text-uppercase font-weight-semibold">
                                                      {{v.head||"-"}}</h6>
                                                  </div>
                                                  <div class="card-body py-2">
                                                    <template v-for="v1,k1 in (v.dubData||[])">
                                                      <div class="req-list" v-if="isShowHeadSubHeadLab(k,k1)"
                                                        :key="k1+'labdatas'">
                                                        <h6 class="text-uppercase" v-if="v1.sub">{{v1.sub||"-"}}</h6>
                                                        <template v-for="v2,k2 in (v1.data||[])">
                                                          <div v-if="isShowLab(k,k1,k2)" :key="k2+'labsubdatas'">
                                                            <i
                                                              class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                                            <b class="align-middle font-weight-semibold">{{v2.text||"-"}}</b>
                                                            <span class="" v-if="v2.notes">, {{v2.notes}}</span>
                                                          </div>
                                                        </template>
                                                      </div>
                                                    </template>
                                                  </div>
                                                </div>
                                              </div>
                                            </template>

                                            <div class="col-md-4 col-lg-3" v-if="row.appdl_hasil_lainnya">
                                              <div class="card shadow-0 border">
                                                <div class="card-header py-2 bg-light">
                                                  <h6 class="card-title text-uppercase font-weight-semibold">Item
                                                    Pemeriksaan Lainnya</h6>
                                                </div>
                                                <div class="card-body py-2">
                                                  <div class="req-list">
                                                    <div>
                                                      <i
                                                        class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                                      <b class="align-middle font-weight-semibold">{{row.appdl_hasil_lainnya||"-"}}</b>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <span v-if="!selectedLabInput" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                                          </div>
                                        </div>
                                        <div class="text-center mt-3" v-if="canEditLab">
                                          <a href="javascript:;" @click="doOpenLab" class="btn alpha-blue"
                                            data-toggle="modal" data-target="#labItemsChecklist"><i
                                              class="icon-plus2 mr-2"></i>Tambah Pemeriksaan Laboratorium</a>
                                        </div>
                                      </div>

                                      <div class="card-body p-3">
                                        <div class="row">
                                          <div class="col-md-12">
                                            <table class="table table-bordered table-striped table-sm patient-table">
                                              <thead>
                                                <tr>
                                                  <th>Hasil Pemeriksaan </th>
                                                  <th>Upload Hasil</th>
                                                  <th>Aksi</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr v-for="(v,k) in (row.appdl_hasil||[])" :key="k+'hasil'">
                                                  <td>
                                                    <b-form-input v-model="row.appdl_hasil[k]['value']" type="text"
                                                      name="name" class="form-control" />
                                                  </td>
                                                  <td>
                                                    <Uploader v-model="row.appdl_hasil[k]['file']" isDocument
                                                      type="docimagewithdocx" />
                                                    <VValidate :name="'file Pemeriksaan #'+(k+1)" v-model="row.appdl_hasil[k]['file']"
                                                      :rules="{required:1}" />
                                                  </td>
                                                  <td>
                                                    <a href="javascript:;" class="list-icons-item"
                                                      @click="row.appdl_hasil.splice(k,1)" data-toggle="tooltip"
                                                      data-placement="top" title="Delete"><i class="icon-bin"></i></a>
                                                  </td>
                                                </tr>
                                                <tr v-if="!(row.appdl_hasil||[]).length">
                                                  <td colspan="99" class="text-center">Tidak Ada Data</td>
                                                </tr>
                                              </tbody>
                                            </table>

                                            <div class="more_data" v-if="canEditLab">
                                              <a href="javascript:;"
                                                @click="row.appdl_hasil.push({value: null,file: null})"><i
                                                  class="icon-plus-circle2"></i>
                                                Tambah</a>
                                            </div>
                                          </div>

                                        </div>
                                      </div>

                                      <div class="card-body p-3">
                                        <div class="form-group">
                                          <label for="">Apakah Pasien Harus Berpuasa? <strong
                                              class="text-danger">*</strong></label>
                                          <b-form-radio-group :disabled="!canEditLab" :options="Config.mr.yesNoOptV2"
                                            v-model="row.appdl_fasting" />

                                          <VValidate v-if="row.appdl_is_lab == 'Y'" :name="'Prioritas Pemeriksaan Lab'"
                                            v-model="row.appdl_fasting"
                                            :rules="toValidate(mrValidation.appdl_fasting)" />
                                        </div>
                                        <div class="row">
                                          <div class="form-group mb-0 col-6">
                                            <label for="">Catatan Permintaan <small>(Opsional)</small></label>
                                            <b-textarea :disabled="!canEditLab" v-model="row.appdl_catatan" name=""
                                              id="" rows="3" class="form-control"></b-textarea>
                                          </div>
                                          
                                          <div class="form-group mb-0 col-6">
                                            <label for="">Catatan Parsial <small>(Opsional)</small></label>
                                            <b-textarea :disabled="!canEditLab" v-model="row.appdl_pemeriksaan_parsial" name=""
                                              id="" rows="3" class="form-control"></b-textarea>
                                          </div>

                                          <div class="col-md-4">
                                            <label for="">Tanggal Pemeriksaan</label>
                                            <div class="input-group">
                                              <div class="input-group mb-3">
                                                <datepicker :disabledDates="disabledDates"
                                                  input-class="form-control transparent" placeholder="Laboratorium"
                                                  class="my-datepicker" calendar-class="my-datepicker_calendar"
                                                  v-model="row.appdl_penunjang_date"
                                                  @changedMonth="checkDateIsLibur($event)">
                                                </datepicker>
                                                <div class="input-group-append calendar-group">
                                                  <span class="input-group-text" id="basic-addon2"><i
                                                      class="icon-calendar"></i></span>
                                                </div>
                                              </div>
                                            </div>
                                            <VValidate v-if="row.appdl_is_lab == 'Y'" :name="'Tanggal Pemeriksaan Lab'"
                                            v-model="row.appdl_penunjang_date" :rules="{required : 1}" />
                                          </div>
                                        </div>
                                      </div>
                                    </template>
                                  </div>
                                </div>


                                <div class="col-md-12 col-lg-12">
                                  <div class="card mb-0">
                                    <div class="card-header bg_head_panel">
                                      <div class="row">
                                        <h6 class="card-title font-weight-semibold col-md-4">Radiologi</h6>
                                        <div class="col-md-8">
                                          <b-form-checkbox @input="changeRadiologi($event)" v-model="row.appdr_is_radio"
                                            value="Y" :disabled="!canEditRadiologi" unchecked-value="N">
                                            Apakah Ada Pengkajian Radiologi
                                          </b-form-checkbox>
                                        </div>
                                      </div>
                                    </div>
                                    <template v-if="row.appdr_is_radio == 'Y'">
                                      <div class="card-body p-3">
                                        <div class="row g-2">
                                          <div class="col-md-4">
                                            <div>
                                              <label>Prioritas Pemeriksaan <strong
                                                  class="text-danger">*</strong></label>
                                              <b-form-radio-group :disabled="!canEditRadiologi"
                                                :options="Config.mr.configPrioritasPemeriksaan"
                                                v-model="row['appdr_prioritas']" />
                                            </div>
                                            <VValidate v-if="row.appdr_is_radio == 'Y'"
                                              :name="'Prioritas Pemeriksaan Radiologi'" v-model="row.appdr_prioritas"
                                              :rules="toValidate(mrValidation.appdr_prioritas)" />
                                          </div>
                                        </div>
                                      </div>
                                      <div class="card-body p-3">
                                        <div class="req-list-resume">
                                          <div class="row g-3">
                                            <template v-for="(v,k) in row.appdr_data">
                                              <div class="col-md-4 col-lg-3" :key="k+'radioData'"
                                                v-if="isShowHeadRadiologi(k)">
                                                <div class="card shadow-0 border">
                                                  <div class="card-header py-2 bg-light">
                                                    <h6 class="card-title text-uppercase font-weight-semibold">{{
                                            getConfigDynamic(Config.mr.KategoriRadiologi,v.head)||"-"}}</h6>
                                                  </div>
                                                  <div class="card-body py-2">
                                                    <template v-for="v1,k1 in (v.data||[])">
                                                      <div v-if="isShowRadiologi(k,k1)" :key="k1+'radiosubdatas'">
                                                        <i
                                                          class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                                        <b class="align-middle font-weight-semibold">{{v1.text||"-"}}</b>
                                                        <span class="" v-if="v1.notes">, {{v1.notes}}</span>
                                                      </div>
                                                    </template>
                                                  </div>
                                                </div>
                                              </div>
                                            </template>
                                            <div class="col-md-4 col-lg-3" v-if="row.appdr_hasil_lainnya">
                                              <div class="card shadow-0 border">
                                                <div class="card-header py-2 bg-light">
                                                  <h6 class="card-title text-uppercase font-weight-semibold">Item
                                                    Pemeriksaan Lainnya</h6>
                                                </div>
                                                <div class="card-body py-2">
                                                  <div class="req-list">
                                                    <div>
                                                      <i
                                                        class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                                      <b class="align-middle font-weight-semibold">{{row.appdr_hasil_lainnya||"-"}}</b>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <span v-if="!selectedRadioInput" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                                          </div>
                                        </div>
                                        <div class="text-center mt-3" v-if="canEditRadiologi">
                                          <a href="javascript:;" @click="doOpenRadiologi()" class="btn alpha-blue"
                                            data-toggle="modal" data-target="#radioItemsChecklist"><i
                                              class="icon-plus2 mr-2"></i>Tambah Pemeriksaan Radiologi</a>
                                        </div>
                                      </div>


                                      <div class="card-body p-3">
                                        <div class="row">
                                          <div class="col-md-12">
                                            <table class="table table-bordered table-striped table-sm patient-table">
                                              <thead>
                                                <tr>
                                                  <th>Hasil Pemeriksaan </th>
                                                  <th>Upload Hasil</th>
                                                  <th>Aksi</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr v-for="(v,k) in (row.appdr_hasil||[])" :key="k+'hasil'">
                                                  <td>
                                                    <b-form-input v-model="row.appdr_hasil[k]['value']" type="text"
                                                      name="name" class="form-control" />
                                                  </td>
                                                  <td>
                                                    <Uploader v-model="row.appdr_hasil[k]['file']" isDocument
                                                      type="docimagewithdocx" />
                                                    <VValidate :name="'file Pemeriksaan #'+(k+1)" v-model="row.appdr_hasil[k]['file']"
                                                      :rules="{required:1}" />
                                                    
                                                  </td>
                                                  <td>
                                                    <a href="javascript:;" class="list-icons-item"
                                                      @click="row.appdr_hasil.splice(k,1)" data-toggle="tooltip"
                                                      data-placement="top" title="Delete"><i class="icon-bin"></i></a>
                                                  </td>
                                                </tr>
                                                <tr v-if="!(row.appdr_hasil||[]).length">
                                                  <td colspan="99" class="text-center">Tidak Ada Data</td>
                                                </tr>
                                              </tbody>
                                            </table>

                                            <div class="more_data" v-if="canEditRadiologi">
                                              <a href="javascript:;"
                                                @click="row.appdr_hasil.push({value: null,file: null,dokter:null})"><i
                                                  class="icon-plus-circle2"></i>
                                                Tambah</a>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                      <div class="card-body p-3">
                                        <div class="form-group">
                                          <label for="">Pasien Memiliki Alergi Terhadap Bahan Kontras/Zat Lainnya?
                                            <strong class="text-danger">*</strong></label>
                                          <b-form-radio-group :disabled="!canEditRadiologi"
                                            :options="Config.mr.yesNoOptV2" v-model="row.appdr_alergi" />

                                          <VValidate v-if="row.appdr_is_radio == 'Y'" :name="'Prioritas Pemeriksaan'"
                                            v-model="row.appdr_alergi" :rules="toValidate(mrValidation.appdr_alergi)" />
                                        </div>

                                        <div class="form-group">
                                          <label for="">Pasien akan Dialihkan Ke Radiologi? <strong
                                              class="text-danger">*</strong></label>
                                          <b-form-radio-group :disabled="!canEditRadiologi"
                                            :options="Config.mr.yesNoOptV2" v-model="row.appdr_to_radio" />

                                          <VValidate v-if="row.appdr_is_radio == 'Y'" :name="'Prioritas Pemeriksaan'"
                                            v-model="row.appdr_to_radio" :rules="{required : 1}" />
                                        </div>
                                        <div class="row">
                                          <div class="form-group mb-0 col-6">
                                            <label for="">Catatan Permintaan <small>(Opsional)</small></label>
                                            <b-textarea :disabled="!canEditRadiologi" v-model="row.appdr_catatan"
                                              name="" id="" rows="3" class="form-control"></b-textarea>
                                          </div>
                                          
                                          <div class="form-group mb-0 col-6">
                                            <label for="">Catatan Parsial <small>(Opsional)</small></label>
                                            <b-textarea :disabled="!canEditRadiologi" v-model="row.appdr_pemeriksaan_parsial" name=""
                                              id="" rows="3" class="form-control"></b-textarea>
                                          </div>

                                          <div class="col-md-4">
                                            <label for="">Tanggal Pemeriksaan</label>
                                            <div class="input-group">
                                              <div class="input-group mb-3">
                                                <datepicker :disabledDates="disabledDates" 
                                                  input-class="form-control transparent" placeholder="Laboratorium"
                                                  class="my-datepicker" calendar-class="my-datepicker_calendar"
                                                  v-model="row.appdr_penunjang_date"
                                                  @changedMonth="checkDateIsLibur($event)">
                                                </datepicker>
                                                <div class="input-group-append calendar-group">
                                                  <span class="input-group-text" id="basic-addon2"><i
                                                      class="icon-calendar"></i></span>
                                                </div>
                                              </div>
                                            </div>
                                            <VValidate v-if="row.appdr_is_radio == 'Y'" :name="'Tanggal Pemeriksaan Radiologi'"
                                            v-model="row.appdr_penunjang_date" :rules="{required : 1}" />
                                          </div>
                                        </div>

                                      </div>
                                    </template>
                                  </div>
                                </div>


                                <div class="col-12">
                                  <div class="card">
                                    <div class="card-header bg_head_panel">
                                      <h6 class="card-title font-weight-semibold">Resep</h6>
                                    </div>

                                    <div class="card-body p-3"
                                      v-if="row.apsd_has_alergi == 'Y' || row.apsd_has_obat_rutin == 'Y'">
                                      <div class="row">
                                        <div class="col-md-6" v-if="row.apsd_has_alergi == 'Y'">
                                          <div class="card border mb-0">
                                            <div class="card-header bg- head_panel_red p-2">
                                              <h6 class="font-weight-semibold text-dark card-title">Catatan Alergi
                                                Pasien</h6>
                                            </div>
                                            <div class="card-body py-2 px-3">
                                              <ul class="mb-0">
                                                <li class="my-1" v-for="(al, alKey) in (row.apsd_alergi || [])"
                                                  :key="alKey">
                                                  <span class="font-weight-semibold"
                                                    v-if="row.apsd_alergi[alKey]['jenis']">
                                                    ({{ row.apsd_alergi[alKey]['jenis'] }})</span>
                                                  <span>{{ row.apsd_alergi[alKey]['name']||"-" }}
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-6" v-if="row.apsd_has_obat_rutin == 'Y'">
                                          <div class="card border mb-0">
                                            <div class="card-header bg- head_panel_blue p-2">
                                              <h6 class="font-weight-semibold text-dark card-title">Catatan Obat Rutin
                                                Pasien</h6>
                                            </div>
                                            <div class="card-body py-2 px-3">
                                              <span class="my-1">{{ row.apsd_keterangan_obat_rutin||"-" }}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <table class="table table-striped table-sm table-hover table-bordered">
                                      <thead>
                                        <tr>
                                          <th width="48">Aksi</th>
                                          <th width="160">Jenis</th>
                                          <th width="320">Nama</th>
                                          <th width="150">Jumlah</th>
                                          <th width="210">Frekuensi</th>
                                          <th>Keterangan</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="(v,k) in resepDokter" :key="'resepDokter'+k">
                                          <td>
                                            <a href="javascript:;" @click="removeObat(v,k)"
                                              class="btn btn-sm alpha-danger border-danger text-danger-800 btn-icon rounded-round"><i
                                                class="icon-trash"></i></a>
                                          </td>
                                          <td>
                                            <v-select placeholder="Pilih Item" v-model="resepDokter[k].ard_jenis"
                                              :options="Config.mr.configResep" label="text"
                                              @input="changeJenis($event,k)" :reduce="v=>v.value">
                                            </v-select>
                                            <VValidate :name="'Jenis '+(k+1)" message="Jenis Harus Diisi"
                                              v-model="resepDokter[k].ard_jenis" :rules="{required : 1}" />
                                          </td>
                                          <td>
                                            <div class="d-flex">
                                              <template v-if="resepDokter[k]['ard_jenis'] == 1">
                                                <v-select style="flex: 1;" class="med-selection select-paging"
                                                  placeholder="Pilih Item"
                                                  :class="obatLen <= 5 ? 'sp-col diagnosa-section': 'sp-col'"
                                                  @open="countLen" @search="filterObat($event,k);countLen();"
                                                  v-model="resepDokter[k].ard_item_id" :options="mObatPreferensi"
                                                  label="text" @input="selectJumlahSatuan($event,k)" :clearable="true"
                                                  :reduce="v=>v.value"
                                                  :selectable="v=>v.value !== 'pd' && v.value !== 'ol'">
                                                  <template slot="selected-option">
                                                    <span
                                                      :class="isAlergiLabel(v.ard_nama)?'text-warning':''">{{ v.ard_nama }}</span>
                                                  </template>
                                                  <template slot="option" slot-scope="option">
                                                    <span :class="isAlergiLabel(option.text)?'text-warning':''"
                                                      v-if="option.preferensi"><b>{{ option.text }}</b></span>
                                                    <span :class="isAlergiLabel(option.text)?'text-warning':''"
                                                      v-else>{{ option.text }}</span>
                                                  </template>
                                                  <template #list-footer>
                                                    <li class="list-paging">
                                                      <div class="d-flex align-items-center justify-content-between">
                                                        <b-button
                                                          @click="changePageObat(v.currentPage,k,'min','resepDokter')"
                                                          size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                                          <i class="icon-arrow-left22 text-white"></i></b-button>
                                                        <small>Halaman {{v.currentPage}} dari
                                                          {{ceilData(v.totalRows/10)}}</small>
                                                        <b-button
                                                          @click="changePageObat(v.currentPage,k,'plus','resepDokter')"
                                                          size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                                          <i class="icon-arrow-right22 text-white"></i></b-button>
                                                      </div>
                                                    </li>
                                                  </template>
                                                </v-select>
                                                <br />
                                              </template>

                                              <template v-else-if="resepDokter[k]['ard_jenis'] == 2">
                                                <v-select style="flex: 1;" class="med-selection select-paging"
                                                  placeholder="Pilih Item" v-model="resepDokter[k].ard_item_id"
                                                  :options="mAlatKesehatan" label="text"
                                                  @input="selectJumlahSatuanAlkes($event,k)" :clearable="true"
                                                  :reduce="v=>v.value">
                                                  <template slot="selected-option" slot-scope="option">
                                                    <span>{{ option.text }}</span>
                                                  </template>
                                                  <template slot="option" slot-scope="option">
                                                    <span>{{ option.text }}</span>
                                                  </template>
                                                </v-select>
                                              </template>

                                              <template v-else-if="resepDokter[k]['ard_jenis'] == 3">
                                                <v-select style="flex: 1;" class="med-selection select-paging"
                                                  placeholder="Pilih Item" v-model="resepDokter[k].ard_item_id"
                                                  :options="mRacikan" label="text"
                                                  @input="selectJumlahSatuanRacikan($event,k)" :clearable="true"
                                                  :reduce="v=>v.value">
                                                  <template slot="selected-option" slot-scope="option">
                                                    <span>{{ option.text }}</span>
                                                  </template>
                                                  <template slot="option" slot-scope="option">
                                                    <span>{{ option.text }}</span>
                                                  </template>
                                                </v-select>
                                              </template>
                                              <a href="javascript:;" v-if="resepDokter[k]['ard_jenis'] !== 3"
                                                @click="resepDokter[k]['ard_item_id'] = 99999; resepDokter[k]['ard_nama'] = 'Lainnya'"
                                                v-b-tooltip.hover="'Pilih Lainnya'"
                                                class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i
                                                  class="icon-plus-circle2"></i></a>
                                            </div>
                                            <VValidate :name="'Nama Item '+(k+1)" message="Nama Item Harus Diisi"
                                              v-model="resepDokter[k]['ard_item_id']" :rules="{required : 1}" />

                                            <div v-if="resepDokter[k]['ard_item_id'] == 99999">
                                              <b-form-input type="text" v-model="resepDokter[k]['ard_item_lainnya']"
                                                class="form-control" id="itemCount" />
                                              <VValidate :name="'Item Lainnya '+(k+1)"
                                                message="Nama Item Lainnya Harus Diisi"
                                                v-model="resepDokter[k]['ard_item_lainnya']" :rules="{required : 1}" />
                                            </div>

                                            <a v-if="resepDokter[k].ard_jenis == 3 && resepDokter[k]['ard_item_id']"
                                              href="javascript:;" @click="toRacikan(v)" data-popup="tooltip" title=""
                                              data-toggle="modal" data-target="#modalRacikanDetail"
                                              class="font-weight-semibold border-bottom"
                                              data-original-title="Lihat Detail Resep">Lihat Detail Racikan</a>

                                            <small class="text-warning"
                                              v-if="isAlergiLabel(resepDokter[k].ard_nama) ">Obat ini termasuk dalam
                                              kategori alergi</small>
                                          </td>
                                          <td>
                                            <div class="input-group">
                                              <b-form-input type="text" v-model="resepDokter[k]['ard_jumlah']"
                                                :formatter="$parent.number" class="form-control" id="itemCount" />
                                              <div class="input-group-append">
                                                <span class="input-group-text">{{resepDokter[k]['ard_satuan']}}</span>
                                              </div>
                                            </div>

                                            <VValidate :name="'Jumlah '+(k+1)" message="Jumlah Harus Diisi"
                                              v-model="resepDokter[k]['ard_jumlah']" :rules="{required : 1}" />
                                          </td>
                                          <td>
                                            <span class="d-flex">
                                              <v-select style="flex: 1;" class="med-selection"
                                                placeholder="Pilih Frekuensi" v-model="resepDokter[k].ard_frekuensi"
                                                :options="mDosis" label="text" :clearable="true" :reduce="v=>v.value">
                                              </v-select>
                                              <a href="javascript:;" @click="resepDokter[k]['ard_frekuensi'] = 99999"
                                                v-b-tooltip.hover="'Pilih Lainnya'"
                                                class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i
                                                  class="icon-plus-circle2"></i></a>
                                            </span>
                                            <VValidate :name="'Frekuensi '+(k+1)" message="Frekuensi Harus Diisi"
                                              v-model="resepDokter[k]['ard_frekuensi']" :rules="{required : 1}" />

                                            <div v-if="resepDokter[k]['ard_frekuensi'] == 99999">
                                              <b-form-input type="text"
                                                v-model="resepDokter[k]['ard_frekuensi_lainnya']" class="form-control"
                                                id="itemCount" />
                                              <VValidate :name="'Frekuensi Lainnya '+(k+1)"
                                                message="Frekuensi Lainnya Harus Diisi"
                                                v-model="resepDokter[k]['ard_frekuensi_lainnya']"
                                                :rules="{required : 1}" />
                                            </div>
                                          </td>
                                          <td>
                                            <b-textarea v-model="resepDokter[k].ard_keterangan" name="itemNotes"
                                              id="itemNotes" rows="2" placeholder="cth. obat dihabiskan"
                                              class="form-control"></b-textarea>
                                          </td>
                                        </tr>
                                      </tbody>
                                      <tfoot v-if="canEditFarmasi">
                                        <tr>
                                          <td class="text-center" colspan="99">
                                            <a href="javascript:;" @click="openObat()" class="btn alpha-blue"
                                              data-toggle="modal" data-target="#modalResep">
                                              <i class="icon-plus2 mr-1 align-middle"></i>
                                              <span class="align-middle">Tambah Resep</span>
                                            </a>
                                            <a href="javascript:;" v-if="riwayatObat.length"
                                              @click="openRiwayatObat = true" class="btn alpha-orange ml-1"
                                              data-toggle="modal" data-target="#modalResepRiwayat">
                                              <i class="icon-plus2 mr-1 align-middle"></i>
                                              <span class="align-middle">Pilih dari Riwayat</span>
                                            </a>
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </table>

                                    <div class="card-body p-3">
                                      <div class="row">
                                        <div class="col-md-8">
                                          <div>
                                            <label for="">Catatan Resep</label>
                                            <textarea :disabled="!canEditFarmasi" v-model="row.apsd_catatan_obat"
                                              class="form-control" rows="3"></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                  </div>
                                </div>

                                <div class="col-12" v-if="row.is_tbc == 'Y' && row.ap_usia <= 12">
                                  <div class="card">
                                    <div class="card-header bg_head_panel">
                                      <h6 class="card-title font-weight-semibold">Kajian TBC</h6>
                                    </div>
                                    <div class="card-body p-3">
                                      <div class="row">
                                        <div class="col-md-3">
                                          <div class="form-group">
                                            <label for="">Hasil Skoring Penilaian TBC Anak</label>
                                            <input v-model="row.atld_hasil_scoring_tbc" type="text" class="form-control">
                                          </div>

                                          <VValidate :name="'Hasil Skoring Penilaian TBC Anak'"
                                            v-model="row.atld_hasil_scoring_tbc"
                                            :rules="{required : 1, max : 128}" />
                                        </div>
                                        <div class="w-100"></div>
                                        <div class="col-md-8">
                                          <div class="form-group">
                                            <label for="tbcTerapi">Terapi/Pengobatan</label>
                                            <textarea v-model="row.atld_tbc_terapi"
                                              class="form-control" rows="3"></textarea>
                                          </div>
                                          
                                          <VValidate :name="'Terapi/Pengobatan'"
                                            v-model="row.atld_tbc_terapi"
                                            :rules="{required : 1, max : 128}" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>


                                    
                                    
                                <div class="col-12">

                                  <div class="card">
                                    <div class="card-header bg_head_panel">
                                      <h6 class="card-title font-weight-semibold">Tindak Lanjut</h6>
                                    </div>
                                  
                                    <div class="card-body p-3">
                                    
                                      <div class="row g-3">
                                        <div class="col-2">
                                          <div class="form-check align-middle">
                                            <label class="form-check-label">
                                              <b-form-checkbox v-model="row.atld_is_edukasi" value="Y"
                                                unchecked-value="N" class="form-check-input-styled p-0" name="edukasi">
                                                Edukasi
                                              </b-form-checkbox>
                                            </label>
                                          </div>
                                        </div>
                                        
                                        <div class="col-2">
                                          <div class="form-check align-middle">
                                            <label class="form-check-label">
                                              <b-form-checkbox v-model="row.atld_is_done" value="Y"
                                                unchecked-value="N" class="form-check-input-styled p-0"
                                                name="tidakKontrol">
                                                Selesai
                                              </b-form-checkbox>
                                            </label>
                                          </div>
                                        </div>
                                        
                                        <div class="col-2">
                                          <div class="form-check align-middle">
                                            <label class="form-check-label">
                                              <b-form-checkbox v-model="row.atld_is_dalam_perawatan" value="Y"
                                                unchecked-value="N" class="form-check-input-styled p-0"
                                                name="tidakKontrol">
                                                Dalam Perawatan
                                              </b-form-checkbox>
                                            </label>
                                          </div>
                                        </div>

                                        <div class="col-2">
                                          <div class="form-check align-middle">
                                            <label class="form-check-label">
                                              <b-form-checkbox v-model="row.atld_is_rawat_inap" value="Y"
                                                unchecked-value="N" class="form-check-input-styled p-0"
                                                name="rawatInap">
                                                Rawat Inap
                                              </b-form-checkbox>
                                            </label>
                                          </div>
                                        </div>

                                      </div>

                                      
                                      <template v-if="row.atld_is_dalam_perawatan == 'Y' || row.atld_is_done == 'Y'">
                                        <div class="row g-3 mt-2" v-if="row.atld_is_dalam_perawatan == 'Y'">
                                          <div class="col-md-12">
                                              <b-form-group>
                                                  <label>Pilihan Perawatan?<span
                                                      class="text-danger mr5">*</span></label>
                                                  <b-form-radio-group @input="changeRujuk($event)" :options="Config.mr.KontrolRujuk"
                                                  v-model="rowReg.ar_is_rujukan" />
                                              </b-form-group>
                                          </div>
                                        </div>

                                        <template v-if="rowReg.ar_is_rujukan == 'Y'">                                      
                                          <div class="row mb-2 mt-3" v-if="$parent.isRegistration && (rowReg.ar_mcp_id == 137 || rowReg.ar_mcp_id == 138)">
                                            <div class="col-4">
                                                <label for="">Nomor Rujukan</label>
                                                <b-form-input disabled v-model="row.absd_no_rujuk" type="text" class="form-control" />
                                            </div>

                                            <div class="col-4">
                                                <label for="">No SEP</label>
                                                <b-form-input disabled v-model="row.absd_no_sep" type="text" class="form-control" />
                                            </div>
                                          </div>

                                          
                                          <div class="row mt-2" v-if="rowReg.ar_is_rujukan == 'Y'">
                                            <div class="col-12">
                                              <label for=""><strong>Rujuk Pasien</strong></label>
                                              <b-form-group>
                                                <b-form-radio-group
                                                  id="radio-group-2"
                                                  v-model="row.artlr_type"
                                                  name="rujukanRad"
                                                >
                                                  <b-form-radio v-model="row.artlr_type" v-if="row.atld_is_dalam_perawatan == 'Y'" value="RI">Rujuk Internal</b-form-radio>
                                                  
                                                  <b-form-radio v-model="row.artlr_type" v-if="row.atld_is_dalam_perawatan == 'Y'" value="RP">Rujuk Parsial</b-form-radio>
                                                  
                                                  <b-form-radio v-model="row.artlr_type" v-if="row.atld_is_done == 'Y'" value="RK">Rujuk Kasus
                                                  </b-form-radio>

                                                  <b-form-radio v-model="row.artlr_type" v-if="row.atld_is_done == 'Y'" value="RB">Rujuk Balik (PRB)</b-form-radio>

                                                  <b-form-radio v-model="row.artlr_type" v-if="row.atld_is_done == 'Y'" value="RBN">Rujuk Balik (NON PRB)</b-form-radio>
                                                </b-form-radio-group>
                                              </b-form-group>

                                              <VValidate :name="'Rujuk Pasien'"
                                                v-model="row.artlr_type"
                                                :rules="{required : 1}" />
                                            </div>
                                          </div>
                                        </template>
                                      </template>
                                      
                                      <div class="row mt-2" v-if="row.atld_is_dalam_perawatan == 'Y'">
                                        <!--
                                        <div class="col-2">
                                          <div class="form-check align-middle">
                                            <label class="form-check-label">
                                              <b-form-checkbox @input="changeKontrol($event)" v-model="row.atld_is_kontrol" value="Y"
                                                unchecked-value="N" class="form-check-input-styled p-0" name="kontrol">
                                                Kontrol
                                              </b-form-checkbox>
                                            </label>
                                          </div>
                                        </div>
                                        -->
                                      </div>

                                      <template v-if="rowReg.ar_is_rujukan == 'Y'">
                                        <div class="row mt-3" v-if="row.artlr_type == 'RI'" id="formRujukInternal">
                                          <div class="col-md-4">
                                            <div class="form-group">
                                              <label for="poliSelection">Pilih Poli</label>

                                              <v-select placeholder="Pilih Poli"
                                                v-model="row.artlr_poli" @input="selectDokter($event)"
                                                :options="mPoli" label="text" :clearable="true"
                                                :reduce="v=>v.value"></v-select>
                                                
                                              <VValidate :name="'Poli'"
                                                v-model="row.artlr_poli"
                                                :rules="{required : 1}" />
                                            </div>
                                          </div>
                                          <div class="col-md-4">
                                            <div class="form-group">
                                              <label for="docSelection">Pilih Dokter</label>
                                              <v-select placeholder="Pilih Dokter" v-model="row.artlr_dokter"
                                                :options="mDokter" label="text" :clearable="true"
                                                :reduce="v=>v.value">
                                              </v-select>
                                              <VValidate :name="'Dokter'"
                                                v-model="row.artlr_dokter"
                                                :rules="{required : 1}" />
                                            </div>
                                          </div>

                                          <div class="col-md-8">
                                            <div class="form-group">
                                              <label> Catatan<small class="txt_mandatory">*</small></label>
                                              <textarea v-model="row.artlr_notes" rows="3" cols="3" class="form-control" placeholder="" spellcheck="false"></textarea>
                                              <VValidate :name="'Catatan'"
                                                v-model="row.artlr_notes"
                                                :rules="{required : 1, min: 2}" />
                                            </div>
                                          </div>
                                        </div>

                                        <div class="row mt-3" v-else-if="row.artlr_type == 'RK' || row.artlr_type == 'RP'" id="formRujukKasus">
                                          <div class="col-md-3">
                                            <div class="form-group">
                                              <label>Alasan Merujuk<small class="txt_mandatory">*</small></label>
                                              <v-select placeholder="Alasan Rujuk" v-model="row.artlr_alasan_rujuk"
                                                :options="Config.mr.alasanRujuk" label="text" :clearable="true"
                                                :reduce="v=>v.value">
                                              </v-select>
                                              <VValidate :name="'Alasan Rujuk'"
                                                v-model="row.artlr_alasan_rujuk"
                                                :rules="{required : 1}" />
                                            </div>
                                          </div>

                                          <div class="col-md-3" v-if="row.artlr_alasan_rujuk">
                                            <div class="form-group">
                                              <label>{{getConfigDynamic(Config.mr.alasanRujuk, row.artlr_alasan_rujuk)||"-"}}<small class="txt_mandatory">*</small></label>
                                              <div class="clearfix"></div>
                                              <b-form-input v-model="row.artlr_alasan_rujuk_text" type="text" class="form-control" />

                                              <VValidate :name="'Alasan DiRujuk'"
                                              v-model="row.artlr_alasan_rujuk_text"
                                              :rules="{required : 1}" />
                                            </div>
                                          </div>

                                          <div class="col-md-3">
                                            <div class="form-group">
                                              <label for="poliSelection">Pilih Fakes Rujukan</label>

                                              <v-select placeholder="Pilih Fakes Rujukan"
                                                @input="selectPoliRujukan($event)" 
                                                v-model="row.artlr_faskes_rujuk"
                                                :options="mFaskes" label="text" :clearable="true"
                                                :reduce="v=>v.value"></v-select>

                                              <button type="button" class="btn btn-light mr-3 mt-2" @click="openFaskes = true">Tambah Faskes Rujukan</button>
                                                
                                              <VValidate :name="'Faskes'"
                                                v-model="row.artlr_faskes_rujuk"
                                                :rules="{required : 1}" />

                                                
                                            </div>
                                          </div>
                                          
                                          <div class="col-md-3">
                                            <div class="form-group">
                                              <label for="docSelection">Pilih Poli</label>
                                              <v-select placeholder="Pilih Poli"
                                              @input="selectDokterRujukan($event)" v-model="row.artlr_poli_rujuk"
                                                :options="mPoliRujukan" label="text" :clearable="true"
                                                :reduce="v=>v.value">
                                              </v-select>
                                              <VValidate :name="'Poli'"
                                                v-model="row.artlr_poli_rujuk"
                                                :rules="{required : 1}" />
                                            </div>
                                          </div>

                                          <div class="col-md-3">
                                            <div class="form-group">
                                              <label for="docSelection">Nama Dokter</label>
                                                <b-form-input v-model="row.artlr_nama_dokter_rujuk" type="text" class="form-control" />
                                            </div>
                                          </div>
                                        
                                        <div class="col-md-12">
                                          <div class="row">
                                            <div class="col-md-6">
                                              <div class="form-row">
                                                <div class="form-group col-md-5">
                                                  <label>Peralatan Media yang Terpasang<small class="txt_mandatory">*</small></label>
                                                  <b-form-radio-group :options="Config.mr.yesNoOptV2"
                                                    v-model="row.artlr_is_media" />
                                                  <VValidate :name="'Peralatan Media yang Terpasang'"
                                                  v-model="row.artlr_is_media"
                                                  :rules="{required : 1}" />
                                                </div>
                                                <div class="form-group col-md-4 mt-3" v-if="row.artlr_is_media == 'Y'">
                                                  <v-select placeholder="Pilih Media" v-model="row.artlr_media"
                                                    :options="Config.mr.mediaTerpasang" label="text" :clearable="true"
                                                    :reduce="v=>v.value">
                                                  </v-select>
                                                  <VValidate :name="'Media'"
                                                  v-model="row.artlr_media"
                                                  :rules="{required : 1}" />
                                                </div>
                                              </div>

                                            </div>

                                          </div>
                                        </div>
                                        
                                        <div class="col-md-8">
                                          <div class="form-group">
                                            <label> Perawatan Pasien yang dibutuhkan<small class="txt_mandatory">*</small></label>
                                            <textarea v-model="row.artlr_perawatan_pasien" rows="3" cols="3" class="form-control" placeholder="" spellcheck="false"></textarea>
                                            
                                            <VValidate :name="'Perawatan Pasien yang dibutuhkan'"
                                            v-model="row.artlr_perawatan_pasien"
                                            :rules="{required: 1, min: 3, max: 128}" />
                                          </div>
                                        </div>

                                        </div>

                                        <div class="row mt-3" v-else id="formRujukBalik">
                                          <div class="col-md-4">
                                            <div class="form-group">
                                              <label>Nomor Rujukan<small class="txt_mandatory">*</small></label>
                                              <div class="clearfix"></div>
                                              <input class="form-control" v-model="row.absd_no_rujuk" readonly placeholder="No Rujukan Faskes I">
                                            </div>
                                          </div>

                                          <div class="col-md-4" v-if="row.artlr_type == 'RB'">
                                            <div class="form-group">
                                              <label>Diagnosa Rujuk Balik<small class="txt_mandatory">*</small></label>
                                              <div class="clearfix"></div>
                                              
                                              <v-select placeholder="Diagnosa Rujuk Balik" v-model="row.artlr_diagnosa_rujuk_balik"
                                              :options="Config.mr.diagnosaRujukBalik" label="text" :clearable="true"
                                              :reduce="v=>v.value"></v-select>
                                              <VValidate 
                                                  name="Diagnosa Rujuk Balik" 
                                                  v-model="row.artlr_diagnosa_rujuk_balik" 
                                                  :rules="{required : 1}"
                                              />
                                            </div>
                                          </div>

                                          <div class="col-md-8">
                                            <div class="form-group">
                                              <label> Catatan<small class="txt_mandatory">*</small></label>
                                              <textarea v-model="row.artlr_notes" rows="3" cols="3" class="form-control" placeholder="" spellcheck="false"></textarea>
                                              <VValidate :name="'Catatan'"
                                                v-model="row.artlr_notes"
                                                :rules="{required : 1, min: 2}" />
                                            </div>
                                          </div>
                                        </div>
                                      </template>


                                      <div class="row">
                                        <div class="col-md-4 col-lg-4 mt-3" v-if="row['atld_is_edukasi'] == 'Y'">
                                          <div>
                                            <label for="stepNotes">Catatan (Edukasi)</label>
                                            <b-textarea v-model="row['atld_notes_edukasi']" name="stepNotes"
                                              id="stepNotes" rows="2" class="form-control">
                                            </b-textarea>
                                          </div>
                                        </div>

                                        <template v-if="row['atld_is_kontrol'] == 'Y'">
                                          <div class="col-md-3 col-lg-3 mt-3" >
                                            <div>
                                              <label for="stepNotes">Tanggal Kontrol <span
                                                    class="text-danger mr5">*</span></label>
                                              <div class="input-group">
                                                <div class="input-group mb-3">
                                                  <datepicker :disabledDates="disabledDates"
                                                    input-class="form-control transparent"
                                                    placeholder="Tanggal Kontrol" class="my-datepicker"
                                                    calendar-class="my-datepicker_calendar"
                                                    v-model="row.atld_notes_kontrol"
                                                    @changedMonth="checkDateIsLibur($event)"
                                                    >
                                                  </datepicker>
                                                  <div class="input-group-append calendar-group">
                                                    <span class="input-group-text" id="basic-addon2"><i
                                                        class="icon-calendar"></i></span>
                                                  </div>
                                                </div>
                                              </div>
                                              <VValidate :name="`Tanggal Kontrol`" 
                                              v-if="row.atld_is_kontrol=='Y'" v-model="row.atld_notes_kontrol"
                                              :rules="{required: 1}" />
                                            </div>
                                          </div>

                                          <div class="col-md-3 col-lg-3 mt-3" >
                                            <div>
                                              <label for="stepNotes">Catatan Kontrol</label>
                                              <b-textarea v-model="row['atld_catatan_kontrol']" name="stepNotes"
                                              id="stepNotes" rows="2" class="form-control">
                                            </b-textarea>
                                            </div>
                                          </div>
                                          
                                          <template v-if="$parent.isRegistration && (rowReg.ar_mcp_id == 137 || rowReg.ar_mcp_id == 138)">
                                            <div class="col-md-3 col-lg-3 mt-3">
                                                <label for="">Nomor Rujukan</label>
                                                <b-form-input v-model="row.absd_no_rujuk" disabled type="text" class="form-control" />
                                            </div>

                                            <div class="col-md-3 col-lg-3 mt-3">
                                                <label for="">No SEP</label>
                                                <b-form-input v-model="row.absd_no_sep" disabled type="text" class="form-control" />
                                            </div>
                                            
                                            <div class="col-md-3 col-lg-3 mt-3">
                                                <label for="">Rujukan Berakhir</label>
                                                <div class="input-group mb-3">
                                                  <datepicker input-class="form-control transparent"
                                                    placeholder="Pilih Tanggal" class="my-datepicker"
                                                    calendar-class="my-datepicker_calendar" v-model="row.absd_tanggal_berlaku_rujukan">
                                                  </datepicker>
                                                  <div class="input-group-append calendar-group">
                                                    <span class="input-group-text" id="basic-addon2"><i
                                                        class="icon-calendar"></i></span>
                                                  </div>
                                                </div>
                                            </div>
                                                     
                                                               
                                          </template>


                                        </template>

                                        <template v-if="row['atld_is_rawat_inap'] == 'Y'">
                                          <div class="col-md-3 col-lg-3 mt-3">
                                            <div>
                                              <label for="stepNotes">Ruangan<span
                                                    class="text-danger mr5">*</span></label>
                                              <v-select placeholder="Pilih Ruangan" v-model="row.atld_ruangan"
                                                :options="mRuangan" label="text" :clearable="true"
                                                :reduce="v=>v.value">
                                              </v-select>
                                            </div>
                                          </div>
                                          
                                          <div class="col-md-3 col-lg-3 mt-3">
                                            <div>
                                              <label for="stepNotes">Dokter<span
                                                    class="text-danger mr5">*</span></label>
                                              <v-select placeholder="Pilih Dokter" v-model="row.atld_dokter_ranap"
                                                :options="mDokterRanap" label="text" :clearable="true"
                                                :reduce="v=>v.value">
                                              </v-select>
                                            </div>
                                          </div>

                                          <div class="col-md-4 col-lg-4 mt-3">
                                            <div>
                                              <label for="stepNotes">Catatan (Rawat Inap)</label>
                                              <b-textarea v-model="row['atld_notes_rawat_inap']" name="stepNotes"
                                                id="stepNotes" rows="2" class="form-control">
                                              </b-textarea>
                                            </div>
                                          </div>
                                        </template>
                                      </div>

                                      <div class="row mt-3">
                                        <div class="col-md-12">
                                          <b-form-group>
                                              <label>Cara Pulang Pasien?<span
                                                class="text-danger mr5">*</span></label>
                                              <b-form-checkbox-group
                                                  v-model="row.atld_cara_pulang"
                                                  :options="Config.mr.mCaraPulang"
                                                  name="cara_pulang"
                                              ></b-form-checkbox-group>
                                          </b-form-group>
                                        </div>
                                      </div>

                                      <!-- v-if="row.is_to_gizi" -->
                                      <div class="row mt-3">
                                        <div class="col-md-8 ml-2">
                                          <label for=""><strong>Konsultasi Dietisien</strong></label><br />
                                          <span>Pasien memiliki kemungkinan kelainan gizi. Apakah Anda ingin
                                            mengkonsultasikan pasien ke dietisien</span>
                                          <b-form-radio-group class="mt-1" :options="Config.mr.yesNoOptV2"
                                            v-model="row.atld_dietisien" />
                                        </div>
                                      </div>

                                      <!--
                                      <div class="col-12 mt-3">
                                        <div class="mt-3">
                                          <label for=""><strong>Rujukan</strong></label>
                                          <div class="row">
                                            <div class="col-md-12">
                                              <b-form-group>
                                                <label>Apakah akan dirujuk ke internal?<span
                                                    class="text-danger mr5">*</span></label>
                                                <b-form-radio-group :options="Config.mr.yesNoOptV2"
                                                  v-model="rowReg.ar_is_rujukan" />
                                              </b-form-group>

                                              <div class="form-row" v-if="rowReg.ar_is_rujukan == 'Y'">
                                                <div class="col-md-3">
                                                  <div class="form-group">
                                                    <label for="poliSelection">Pilih Poli</label>

                                                    <v-select placeholder="Pilih Poli"
                                                      v-model="rowReg.ar_rujukan_poli_to" @input="selectDokter($event)"
                                                      :options="mPoli" label="text" :clearable="true"
                                                      :reduce="v=>v.value"></v-select>
                                                  </div>
                                                </div>
                                                <div class="col-md-3">
                                                  <div class="form-group">
                                                    <label for="docSelection">Pilih Dokter</label>

                                                    <v-select placeholder="Pilih Dokter" v-model="rowReg.ar_rujukan_to"
                                                      :options="mDokter" label="text" :clearable="true"
                                                      :reduce="v=>v.value">
                                                    </v-select>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      -->
                                    </div>
                                  </div>
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
                <!-- End Accordion-->
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="card border shadow-0 mt-3" v-if="row.isEdit">
          <div class="card-header bg-info">
            <h5 class="card-title font-weight-semibold">Keterangan Perubahan</h5>
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for="addInfoTindakan">Keterangan Perubahan</label>
              <b-textarea v-model="row.arm_notes" :formatter="$parent.normalText" rows="6" class="form-control"
                placeholder="Keterangan Perubahan">
              </b-textarea>


              <VValidate :name="`Keterangan Perubahan`" v-model="row.arm_notes"
                :rules="{required: 1, min: 2, max:512}" />
            </div>
          </div>
        </div>
        -->

        <div class="card-footer">
          <div class="text-right">
            <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
          </div>
        </div>
      </b-form>
    </validation-observer>

    <b-modal v-model="openRiwayatObat" :title="'Pilih Obat dari Riwayat'" size="lg" hide-footer>
      <div class="modal-body p-0">
        <div class="mb-3">
          <div class="row">
            <div class="col-md-5 col-lg-4">
              <div class="d-flex w-100">
                <div class="form-group form-group-feedback form-group-feedback-left flex-fill mb-0 mr-2">
                  <input v-model="searchObat" @input="searchObatData" class="form-control"
                    placeholder="Ketik Nama Dokter / No. Registrasi" />
                  <div class="form-control-feedback">
                    <i class="icon-search4 text-indigo"></i>
                  </div>
                </div>
                <button @click="resetData()" class="btn alpha-warning border-orange text-warning-600">Reset</button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-sm table-hover table-bordered">
            <thead>
              <tr>
                <th>
                </th>
                <th>Tgl</th>
                <th>No Reg</th>
                <th>Nama Dokter</th>
                <th>Jenis</th>
                <th>Nama</th>
                <th>Dosis</th>
                <th>Jumlah</th>
                <th>Frekuensi</th>
                <th>Keterangan</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v,k) in riwayatObat" :key="k+'obat'">
                <td v-if="v.isHead" :rowspan="v.rowspan">
                  <div v-if="$parent.user.id == v.bu_id" class="custom-control custom-control-inline custom-checkbox">
                    <input type="radio" :value="v.arm_id" v-model="selectedObat" name="obatSelection" :id="`obat${k+1}`"
                      class="custom-control-input">
                    <label :for="`obat${k+1}`" class="custom-control-label"></label>
                  </div>
                </td>
                <td v-if="v.isHead" :rowspan="v.rowspan">{{v.ar_reg_date |moment("DD MMM YYYY")}}</td>
                <td v-if="v.isHead" :rowspan="v.rowspan">{{v.ar_reg_code||"-"}}</td>
                <td v-if="v.isHead" :rowspan="v.rowspan">{{v.bu_full_name||"-"}}</td>
                <td>{{v.ard_jenis == 1 ? 'Obat' : v.ard_jenis == 2 ? 'Alat Kesehatan' : 'Racikan'}}</td>
                <td>
                  <span>{{v.ard_nama||"-"}}</span>
                </td>
                <td>{{v.ard_dosis || "-"}} {{v.ard_satuan_dosis}}</td>
                <td>{{v.ard_jumlah||"-"}} {{v.ard_satuan||"-"}}</td>
                <td>
                  <span v-if="v.ard_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
                  <span v-else>{{v.ard_frekuensi_lainnya || "-"}}</span>
                </td>
                <td>{{v.ard_keterangan || "-"}}</td>
              </tr>
              <tr v-if="!riwayatObat.length" class="table-info">
                <td class="text-center" colspan="99">Tidak Ada Data</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer p-0 mt-3">
        <button type="button" @click="openRiwayatObat = false" class="btn btn-link" data-dismiss="modal">Batal</button>
        <button type="button" :disabled="!selectedObat" @click="saveObat"
          class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b>Salin Resep</button>
      </div>
    </b-modal>

    <b-modal v-model="openRiwayatKeluhan" :title="'Pilih Keluhan dari Riwayat Pasien'" size="xl" hide-footer>
      <div class="modal-body p-0">
        <div class="mb-3">
          <div class="row">
            <div class="col-md-5 col-lg-4">
              <div class="d-flex w-100">
                <div class="form-group form-group-feedback form-group-feedback-left flex-fill mb-0 mr-2">
                  <input v-model="searchKeluhan" @input="searchKeluhanData" class="form-control"
                    placeholder="Ketik Nama Dokter / No. Registrasi" />
                  <div class="form-control-feedback">
                    <i class="icon-search4 text-indigo"></i>
                  </div>
                </div>
                <button @click="resetData()" class="btn alpha-warning border-orange text-warning-600">Reset</button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered table-sm patient-table">
            <thead>
              <tr>
                <th width="64">#</th>
                <th>No. Registrasi</th>
                <th>Tanggal</th>
                <th>Dokter</th>
                <th>Keluhan</th>
                <th>Riwayat Penyakit</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(v,k) in (riwayatKeluhan||[])">
                <tr :class="selectedKeluhan == v.apsd_id? 'table-info':''" :key="k+'riwayat'">
                  <td>
                    <div class="custom-control custom-control-inline custom-checkbox">
                      <input type="radio" :value="v.apsd_id" v-model="selectedKeluhan" name="historySelection"
                        :id="`hist${k+1}`" class="custom-control-input">
                      <label :for="`hist${k+1}`" class="custom-control-label"></label>
                    </div>
                  </td>
                  <td>
                    <span class="font-weight-semibold">{{v.ar_reg_code||"-"}}</span>
                  </td>
                  <td>{{v.ar_reg_date | moment("DD MMM YYYY")}}</td>
                  <td><strong>{{v.bu_full_name||"-"}} <small><br>{{v.mpo_name||"-"}}</small></strong></td>
                  <td>{{v.apsd_keluhan||"-"}}</td>
                  <td>{{v.apsd_anamnesa||"-"}}</td>
                </tr>
              </template>
              <tr v-if="!(riwayatKeluhan||[]).length">
                <td colspan="99" class="text-center">Tidak ada data</td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer p-0 mt-3">
        <div class="text-right">
          <button data-dismiss="modal" type="button" @click="openRiwayatKeluhan = false" class="btn">Batal</button>
          <button data-dismiss="modal" :disabled="!selectedKeluhan" @click="saveKeluhan()" type="button"
            class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b>Simpan
            Pilihan</button>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="openriwayatICD10" :title="'Pilih ICD10 dari Riwayat Pasien'" size="xl" hide-footer>
      <div class="modal-body p-0">
        <div class="mb-3">
          <div class="row">
            <div class="col-md-5 col-lg-4">
              <div class="d-flex w-100">
                <div class="form-group form-group-feedback form-group-feedback-left flex-fill mb-0 mr-2">
                  <input v-model="searchICD10" @input="searchICD10Data" class="form-control"
                    placeholder="Ketik Nama Dokter / No. Registrasi" />
                  <div class="form-control-feedback">
                    <i class="icon-search4 text-indigo"></i>
                  </div>
                </div>
                <button @click="resetData()" class="btn alpha-warning border-orange text-warning-600">Reset</button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered table-sm patient-table">
            <thead>
              <tr>
                <th width="64">#</th>
                <th>No. Registrasi</th>
                <th>Tanggal</th>
                <th>Dokter</th>
                <th>ICD10</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(v,k) in riwayatICD10">
                <tr :class="selectedICD10 == v.arm_id ? 'table-info' : ''" :key="k+'diagnosa'">
                  <td v-if="v.isHead" :rowspan="v.rowspan">
                    <div class="custom-control custom-control-inline custom-checkbox">
                      <input type="radio" :value="v.arm_id" v-model="selectedICD10" name="historySelectionDiagnose"
                        :id="`histdiag${k+1}`" class="custom-control-input">
                      <label :for="`histdiag${k+1}`" class="custom-control-label"></label>
                    </div>

                  </td>

                  <td v-if="v.isHead" :rowspan="v.rowspan">
                    <span class="font-weight-semibold">{{v.ar_reg_code||"-"}}</span>
                  </td>
                  <td v-if="v.isHead" :rowspan="v.rowspan">{{v.ar_reg_date | moment("DD MMM YYYY")}}</td>
                  <td v-if="v.isHead" :rowspan="v.rowspan"><strong>{{v.bu_full_name||"-"}}
                      <small><br>{{v.mpo_name||"-"}}</small></strong></td>
                  <td>{{v.mi10_name||"-"}},{{v.api10_diagnosis||"-"}}</td>
                </tr>
              </template>
              <tr v-if="!(riwayatICD10||[]).length">
                <td colspan="99" class="text-center">Tidak ada data</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer p-0 mt-3">
        <div class="text-right">
          <button data-dismiss="modal" @click="openriwayatICD10 = false" class="btn">Batal</button>
          <button data-dismiss="modal" @click="saveICD10" :disabled="!selectedICD10"
            class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b>Simpan
            Pilihan</button>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="openriwayatICD9" :title="'Pilih ICD9 dari Riwayat Pasien'" size="xl" hide-footer>
      <div class="modal-body p-0">
        <div class="mb-3">
          <div class="row">
            <div class="col-md-5 col-lg-4">
              <div class="d-flex w-100">
                <div class="form-group form-group-feedback form-group-feedback-left flex-fill mb-0 mr-2">
                  <input v-model="searchICD9" @input="searchICD9Data" class="form-control"
                    placeholder="Ketik Nama Dokter / No. Registrasi" />
                  <div class="form-control-feedback">
                    <i class="icon-search4 text-indigo"></i>
                  </div>
                </div>
                <button @click="resetData()" class="btn alpha-warning border-orange text-warning-600">Reset</button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-bordered table-sm patient-table">
            <thead>
              <tr>
                <th width="64">#</th>
                <th>No. Registrasi</th>
                <th>Tanggal</th>
                <th>Dokter</th>
                <th>ICD9</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(v,k) in riwayatICD9">

                <tr :class="selectedICD9 == v.arm_id ? 'table-info' : ''" :key="k+'diagnosa'">
                  <td v-if="v.isHead" :rowspan="v.rowspan">
                    <div class="custom-control custom-control-inline custom-checkbox">
                      <input type="radio" :value="v.arm_id" v-model="selectedICD9" name="historySelectionDiagnose"
                        :id="`histdiag${k+1}`" class="custom-control-input">
                      <label :for="`histdiag${k+1}`" class="custom-control-label"></label>
                    </div>

                  </td>

                  <td v-if="v.isHead" :rowspan="v.rowspan">
                    <span class="font-weight-semibold">{{v.ar_reg_code||"-"}}</span>
                  </td>
                  <td v-if="v.isHead" :rowspan="v.rowspan">{{v.ar_reg_date | moment("DD MMM YYYY")}}</td>
                  <td v-if="v.isHead" :rowspan="v.rowspan"><strong>{{v.bu_full_name||"-"}}
                      <small><br>{{v.mpo_name||"-"}}</small></strong></td>
                  <td>{{v.mi9_name||"-"}},{{v.api9_tindakan||"-"}}</td>
                </tr>
              </template>
              <tr v-if="!(riwayatICD9||[]).length">
                <td colspan="99" class="text-center">Tidak ada data</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer p-0 mt-3">
        <div class="text-right">
          <button data-dismiss="modal" @click="openriwayatICD9 = false" class="btn">Batal</button>
          <button data-dismiss="modal" @click="saveICD9" :disabled="!selectedICD9"
            class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b>Simpan
            Pilihan</button>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="openRacikan" :title="'Komposisi Racikan'" size="md" hide-footer>
      <div class="modal-body p-0 table-responsive">
        <p>{{rowRacikan.ard_jumlah||"-"}} {{rowRacikan.ard_satuan||"-"}} {{rowRacikan.mrd_nama_racikan||"-"}} terdiri
          dari:</p>
        <table class="table table-striped table-sm table-hover table-bordered">
          <thead>
            <tr>
              <th width="40">#</th>
              <th>Nama Obat</th>
              <th>Qty</th>
              <th>Frekuensi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(v,k) in rowRacikan.ard_racikan" :key="k+'rowracik'">
              <td>{{k+1}}</td>
              <td>{{getObat(v.value)||"-"}}</td>
              <td>{{v.jumlah||"-"}} {{v.satuan_jumlah||"-"}}</td>
              <td>{{v.dosis||"-"}} {{v.satuan_dosis||"-"}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer p-0 mt-3">

        <a href="javascript:;" @click="doOpenFormRacikan()" data-toggle="modal" data-target="#updateRacikanFarmasi"
          class="btn btn-link" data-dismiss="modal">
          <div class="icon-pencil7"></div>
          Ubah Komposisi Racikan
        </a>
        <button type="button" @click="openRacikan = false" class="btn btn-success btn-labeled btn-labeled-left"
          data-dismiss="modal"><b><i class="icon-checkmark"></i></b>Selesai</button>
      </div>
    </b-modal>

    <validation-observer ref="VFormRacikan">
      <b-form @submit.prevent="doSubmitRacikan">
        <b-modal v-model="openFormRacikan" :title="'Ubah Komposisi Racikan'" size="lg" hide-footer>
          <div class="modal-body p-0">
            <div class="alert alert-info">
              <span>Saat ini Anda sedang melakukan perubahan komposisi racikan
                <strong>{{rowRacikan.mrd_nama_racikan||"-"}}</strong> milik
                <strong>{{rowRacikan.bu_full_name||"-"}}</strong></span>
            </div>
            <div class="form-group">
              <label for="">Komposisi Racikan <strong class="text-danger">*</strong></label>
              <table class="table table-bordered table-striped table-sm">
                <thead>
                  <tr>
                    <th width="48">#</th>
                    <th width="33%">Obat</th>
                    <th width="33%">Dosis</th>
                    <th width="33%">Jumlah</th>
                    <th width="33%">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v,k) in rowRacikan.ard_racikan" :key="k">
                    <td>{{k + 1}}</td>
                    <td>
                      <v-select placeholder="Pilih Obat" @input="selectObat($event,k)"
                        v-model="rowRacikan.ard_racikan[k]['value']" :options="getMasterObat(rowRacikan.ard_racikan,k)"
                        label="text" :clearable="true" :reduce="v=>v.value"></v-select>

                      <VValidate :name="'Obat #'+(k+1)" message="Obat Harus Diisi"
                        v-model="rowRacikan.ard_racikan[k]['value']" :rules="{required : 1}" />
                    </td>
                    <td>
                      <div class="input-group">
                        <input v-model="rowRacikan.ard_racikan[k]['dosis']" type="text" class="form-control"
                          placeholder="cth. 120">
                        <div class="input-group-append"><input style="width: 90px;"
                            v-model="rowRacikan.ard_racikan[k]['satuan_dosis']" type="text" class="form-control"
                            placeholder="cth. Kaplet"></div>
                      </div>
                    </td>
                    <td>
                      <div class="input-group">
                        <b-form-input type="text" v-model="rowRacikan.ard_racikan[k]['jumlah']"
                          :formatter="$parent.number" class="form-control" placeholder="cth. 5" />
                        <div class="input-group-append"><span
                            class="input-group-text">{{rowRacikan.ard_racikan[k]['satuan_jumlah']||"-"}}</span></div>
                      </div>

                      <VValidate :name="'Jumlah #'+(k+1)" message="Jumlah Harus Diisi"
                        v-model="rowRacikan.ard_racikan[k]['jumlah']" :rules="{required: 1}" />
                    </td>
                    <td>
                      <a href="javascript:;" @click="rowRacikan.ard_racikan.splice(k,1)"
                        class="btn btn-icon btn-sm rounded-round alpha-danger border-danger text-danger-800"
                        data-popup="tooltip" title="Hapus Obat"><i class="icon-trash"></i></a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="9" class="text-center">
                      <a href="javascript:;" @click="addObat" class="btn btn-outline-info">
                        <i class="icon-plus2 align-middle mr-1"></i>
                        <span class="align-middle">Tambah Obat</span>
                      </a>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div class="w-100"></div>
            <div class="col-md-4">
              <div>
                <label for="">Jumlah & Satuan<strong class="text-danger">*</strong></label>
                <div class="input-group">
                  <b-form-input type="text" :formatter="$parent.number" v-model="rowRacikan.ard_jumlah"
                    class="form-control" id="cth. Racikan Amlodipin" placeholder="Jumlah Default Racikan" />
                  <div class="input-group-append">
                    <v-select placeholder="Pilih Satuan" style="width: 160px;" v-model="rowRacikan.ard_satuan"
                      :options="mTypeSatuan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </div>
                </div>
                <VValidate name="Jumlah" v-model="rowRacikan.ard_jumlah" :rules="{required : 1}" />
                <VValidate name="Satuan" v-model="rowRacikan.ard_satuan" :rules="{required : 1}" />
              </div>
            </div>
          </div>
          <div class="modal-footer p-0 mt-3">
            <a href="javascript:;" @click="resetData" data-toggle="modal" data-target="#updateRacikanFarmasi"
              class="btn btn-link" data-dismiss="modal">
              Batal
            </a>
            <button type="submit" @click="doSubmitRacikan" class="btn btn-success btn-labeled btn-labeled-left"
              data-dismiss="modal"><b><i class="icon-checkmark"></i></b>Simpan</button>
          </div>
        </b-modal>
      </b-form>
    </validation-observer>

    <b-modal v-model="openLab" :title="'Pilih Item Pemeriksaan Laboratorium'" size="xl" hide-footer>
      <div class="modal-header d-block p-0 mt-3">
        <ul class="nav nav-tabs mb-0 nav-tabs-bottom nav-justified">
          <li v-for="(v,k) in row.appdl_data" :key="k" @click="changeTabLab(k)" class="nav-item">
            <a href="javascript:;" data-toggle="tab"
              :class="k == activeTabLab ? 'nav-link active' : 'nav-link'">{{v.head||"-"}}</a>
          </li>
        </ul>
      </div>
      <div class="modal-body p-0 mt-3">
        <div class="tab-content">
          <div v-for="(v,k) in row.appdl_data" :key="k+'labform'"
            :class="k == activeTabLab ? 'tab-pane fade show active' : 'tab-pane fade'">
            <template v-for="(v1,k1) in (v.dubData||[])">
              <div :key="k1+'sss'">
                <div v-if="v1.sub" class="selection-control font-weight-semibold bg-light text-uppercase">
                  {{v1.sub}}</div>
                <div class="row sc-row g-0">
                  <template v-for="(v2,k2) in (v1.data||[])">
                      <div class="col-md-3" v-if="v2.id" :key="k2+'xdx'">
                          <div class="selection-control">
                          <b-form-checkbox :name="'tabLab'+String(v2.id)" v-model="v2['selected']">{{v2.text||"-"}}
                          </b-form-checkbox>
                          <b-form-input type="text" v-if="v2['selected']" v-model="v2['notes']" class="form-control form-control-sm d-block" placeholder="Catatan Permintaan" />
                          </div>
                      </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="row ml-1 mt-2">
          <div class="col-md-4">
            <div class="form-group row">
              <label>Lainnya</label>
              <b-form-input v-model="row.appdl_hasil_lainnya" type="text" class="form-control" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer p-0 mt-3 bg-white flex-column justify-content-start align-items-start">
        <div class="font-weight-semibold m-0">Item Pemeriksaan Dipilih:</div>
        <span class="m-0" v-if="selectedLabInput">{{selectedLabInput||"-"}}</span>
        <span v-else> - </span>
      </div>
      <div class="modal-footer p-0 mt-3 bg-white">
        <div class="text-right">
          <button @click="openLab = false" data-dismiss="modal" class="btn">Selesai</button>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="openRadiologi" :title="'Pilih Item Pemeriksaan Radiologi'" size="xl" hide-footer>
      <div class="modal-header d-block p-0 mt-3">
        <ul class="nav nav-tabs mb-0 nav-tabs-bottom nav-justified">
          <li v-for="(v,k) in row.appdr_data" :key="k" @click="changeTabRadiologi(k)" class="nav-item">
            <a href="javascript:;" data-toggle="tab"
              :class="k == activeTabRadiologi ? 'nav-link active' : 'nav-link'">{{getConfigDynamic(Config.mr.KategoriRadiologi, v.head)||"-"}}</a>
          </li>
        </ul>
      </div>

      <div class="modal-body p-0 mt-3">
        <div class="tab-content">
          <div v-for="(v,k) in row.appdr_data" :key="k+'radform'"
            :class="k == activeTabRadiologi ? 'tab-pane fade show active' : 'tab-pane fade'">
            <div class="row sc-row g-0">
              <div class="col-md-3" v-for="(v1,k1) in (v.data||[])" :key="k1+'radio'">
                <div class="selection-control">
                  <b-form-checkbox :name="'tabrad'+String(v1.id)" v-model="v1['selected']">{{v1.text||"-"}}
                  </b-form-checkbox>
                  <b-form-input type="text" v-if="v1['selected']" v-model="v1['notes']" class="form-control form-control-sm d-block" placeholder="Catatan Permintaan" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row ml-1 mt-2">
          <div class="col-md-4">
            <div class="form-group row">
              <label>Lainnya</label>
              <b-form-input v-model="row.appdr_hasil_lainnya" type="text" class="form-control" />
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer p-0 mt-3 bg-white flex-column justify-content-start align-items-start">
        <div class="font-weight-semibold m-0">Item Pemeriksaan Dipilih:</div>
        <span class="m-0" v-if="selectedRadioInput">{{selectedRadioInput}}</span>
        <span v-else> - </span>
      </div>
      <div class="modal-footer p-0 mt-3 bg-white">
        <div class="text-right">
          <button @click="openRadiologi = false" data-dismiss="modal" class="btn">Selesai</button>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="openFaskes" :title="'Tambah Faskes Tujuan'" size="sm" hide-footer>
      <div class="modal-body p-0">
        <div class="mb-3">
          <div class="row">
            
            <div class="col-md-12">
                <label for="perGravid">Cari Nama Faskes<strong
                class="text-danger">*</strong></label>
                <div class="input-group">
                  <b-form-input v-model="faskesSearch"
                    type="text" class="form-control" />
                    <div class="input-group-append">
                      <button @click="searchFaskesData" type="button"
                        class="btn btn-info btn-labeled btn-labeled-left">
                        <b><i class="icon-search4"></i></b>
                        Cari
                      </button>
                    </div>
                </div>
            </div>

            <div class="col-md-12" v-for="(v,k) in (listFaskesBPJS||[])" :key="k+'radiox'">
              <div class="selection-control">
                <b-form-checkbox v-model="v['selected']">{{v.kode}} - {{v.nama}}
                </b-form-checkbox>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="modal-footer p-0 mt-3 bg-white" v-if="(listFaskesBPJS||[]).length">
        <div class="text-right">
          <button @click="addFaskes" data-dismiss="modal" class="btn">Selesai</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'
  import Datepicker from 'vuejs-datepicker'
  

  const moment = require('moment')
  import {
    soundex
  } from 'soundex-code'
  // import GlobalVue from '@/libs/Global.vue'
import { stringSimilarity } from "string-similarity-js"

  export default {
    // extends: GlobalVue,
    components: {
      Datepicker
    },
    props: {
      row: Object,
      rowReg: Object,
      mrValidation: Object,
      Config: Object,
      mKesadaran: Array,
      diagnosa: Array,
      mICD9: Array,
      mICD10: Array,
      mLab: Array,
      mRadiologi: Array,
      resepDokter: Array,
      mDosis: Array,
      mObatPreferensi: Array,
      mAlatKesehatan: Array,
      mRacikan: Array,
      mObat: Array,

      riwayatObat: Array,
      riwayatKeluhan: Array,
      riwayatDiagnosa: Array,

      dataICD9: Array,
      dataICD10: Array,
      riwayatICD9: Array,
      riwayatICD10: Array,
      mTypeSatuan: Array,

      mPoli: Array,
      mDokter: Array,
      mAlergi: Array,
      
      mFaskes: Array,
      mDokterRujukan: Array,
      mPoliRujukan: Array,

      mHariLibur: Array,

      mRuangan: Array,
      mDokterRanap: Array,
    },
    computed: {
      disabledDates() {
        let datesDisabled = []
        for (let i = 0; i < this.mHariLibur.length; i++) {
          datesDisabled.push(new Date(moment(this.mHariLibur[i]['mhl_date'])))
        }
        return {
          to: new Date(moment().format('YYYY-MM-DD')),
          dates: datesDisabled,
          days: [0],

        }
      },
      canEditLab() {
        // return this.rowReg.ar_is_done_ppa_lab !== 'Y'
        return true

      },
      canEditRadiologi() {
        // return this.rowReg.ar_is_done_ppa_radiologi !== 'Y'
        return true
      },
      canEditFarmasi() {
        return true
      },
      selectedLabInput() {
        let data = []
        for (let ik = 0; ik < (this.row.appdl_data || []).length; ik++) {
          for (let jk = 0; jk < (this.row.appdl_data[ik]['dubData'] || []).length; jk++) {
            for (let kk = 0; kk < (this.row.appdl_data[ik]['dubData'][jk]['data'] || []).length; kk++) {
              if (this.row.appdl_data[ik]['dubData'][jk]['data'][kk]['selected']) {
                data.push(this.row.appdl_data[ik]['dubData'][jk]['data'][kk]['text'])
              }
            }
          }
        }
        if (this.row.appdl_hasil_lainnya) {
          data.push(this.row.appdl_hasil_lainnya)
        }
        return data.join(", ")
      },
      selectedRadioInput() {
        let data = []
        for (let i = 0; i < (this.row.appdr_data || []).length; i++) {
          for (let j = 0; j < (this.row.appdr_data[i]['data'] || []).length; j++) {
            if (this.row.appdr_data[i]['data'][j]['selected']) {
              data.push(this.row.appdr_data[i]['data'][j]['text'])
            }
          }
        }
        if (this.row.appdr_hasil_lainnya) {
          data.push(this.row.appdr_hasil_lainnya)
        }
        return data.join(", ")
      },

      selectedFaskesInput() {
        let data = []
        for (let i = 0; i < (this.listFaskesBPJS || []).length; i++) {
          if(this.listFaskesBPJS[i]['selected']){
            data.push(this.listFaskesBPJS[i])
          }
        }
        return data
      },

      oddEvenGenCondition() {
        let odd = this.Config.mr.configVitalSign.filter((el, index) => index % 2)
        let even = this.Config.mr.configVitalSign.filter((el, index) => !(index % 2))
        let res = []
        res.push(even)
        res.push(odd)
        return res
      }
    },
    data() {
      return {
        mFaskesBPJS : [],
        faskesSearch : null,
        openAddObat: false,
        rowEdit: {},
        activeAnat: 0,
        openRacikan: false,
        rowRacikan: {},

        openRiwayatKeluhan: false,
        openRiwayatObat: false,

        openriwayatICD10: false,
        openriwayatICD9: false,

        selectedKeluhan: '',
        selectedObat: '',
        selectedICD9: '',
        selectedICD10: '',


        searchKeluhan: '',
        searchObat: '',
        searchICD10: '',
        searchICD9: '',

        isRadiologi: false,
        isLab: false,
        openLab: false,
        openRadiologi: false,

        activeTabLab: 0,
        activeTabRadiologi: 0,
        openFormRacikan: false,
        obatLen: 0,
        currentPage: 1,

        inpICD10: "",
        pemeriksaanFisik: false,

        inputICD9: null,
        inputICD10: null,
        
        inputICD9Name: null,
        inputICD10Name: null,
        searchICD10Name: null,
        searchICD9Name: null,

      cardKajianCollapse: {
          subjective: true,
          objective: false,
          assessment: false,
          planning: false,
        },

        openFaskes : false,
        listFaskesBPJS: []
      }
    },
    methods: {
      changeKontrol(e){
        if(e == 'Y'){
          this.rowReg.ar_is_rujukan = "N"
          this.row.artlr_type = null
        }
      },
      // changeCaraPulang(e){
      //   if(e.indexOf('P') != -1){
      //     this.row.atld_is_edukasi = "Y"
      //   }else{
      //     this.row.atld_is_edukasi = "N"
      //   }
        
      //   if(e.indexOf('K') != -1){
      //     this.row.atld_is_dalam_perawatan = "Y"
      //   }else{
      //     this.row.atld_is_dalam_perawatan = "N"
      //   }

      //   if(e.indexOf('RU') != -1){
      //     this.row.atld_is_done = "Y"
      //   }else{
      //     this.row.atld_is_done = "N"
      //   }

      //   if(e.indexOf('RI') != -1){
      //     this.row.atld_is_rawat_inap = "Y"
      //   }else{
      //     this.row.atld_is_rawat_inap = "N"
      //   }
      // },
      addFaskes(){
        this.$parent.loadingOverlay = true
        let data = {
          faskesData: this.selectedFaskesInput,
          type: 'submit-faskes-data'
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          this.$parent.loadingOverlay = false
          this.$swal({
            icon: 'success',
            title: 'Data Faskes berhasil ditambahkan',
          })
          this.$parent.apiGet()
          this.openFaskes = false
        })
      },
      searchFaskesData(){
        this.$parent.loadingOverlay = true
        let data = {
          search: this.faskesSearch,
          type: 'search-faskes-data'
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          this.$parent.loadingOverlay = false
          this.listFaskesBPJS = res.data
          console.log(this.listFaskesBPJS)
        })
      },
      changeRujuk(e){
        if(e == "Y"){
          this.row.atld_is_kontrol = "N"
          this.row.atld_is_rawat_inap = "N"
          // this.row.atld_is_done = "N"
        }else{
          this.row.atld_is_kontrol = "Y"
        }
      },
      test(){
        this.cardKajianCollapse['planning'] = true
      },
      toggleCardCollapse(e, collapseParamKey){
        e.stopPropagation()
        this.cardKajianCollapse[collapseParamKey] = !this.cardKajianCollapse[collapseParamKey]
        if(this.cardKajianCollapse[collapseParamKey]){
          const mainTarget = e.target.classList.contains('card-header') ? e.target : e.target.parentElement
          const pageHeaderOpen = document.querySelector('.page-header-sticky.page-header-sticky-open')
          const addCount = pageHeaderOpen ? pageHeaderOpen.offsetHeight : 0
          let count, addHeight = document.querySelector('.navbar').offsetHeight + addCount + 20
          setTimeout(() => {
            count = Math.round(mainTarget.parentElement.getBoundingClientRect().top + document.documentElement.scrollTop) - addHeight
            $("html, body").scrollTop(count)
          }, 50)
        }
      },
      selectICD9(e) {
        let index = this.mICD9.findIndex(x => x.value == e)
        if (index !== -1) {
          this.inputICD9Name =  this.mICD9[index]['text']
          this.searchICD9Name = null
        }else{
          this.inputICD9Name =  null
        }
        this.addNewICD9()
      },

      selectICD10(e) {
        let index = this.mICD10.findIndex(x => x.value == e)
        if (index !== -1) {
          this.inputICD10Name =  this.mICD10[index]['text']
          this.searchICD10Name = null
        }else{
          this.inputICD10Name =  null
        }
        this.addNewICD10()
      },

      filterICD10: _.debounce(function (e, k) {
        let id = []
        for (let i = 0; i < (this.dataICD10 || []).length; i++) {
          if (this.dataICD10[i]['api10_icd_10']) {
            id.push(this.dataICD10[i]['api10_icd_10'])
          }
        }
        
        this.searchICD10Name = e

        Gen.apiRest('/do/' + 'RekamMedis', {
          data: {
            type: 'select-icd-10',
            search: e,
            id: id
          }
        }, 'POST').then(res => {
          this.$parent.mICD10 = res.data.data
        })
      }, 10),

      changePageICD10(page, k, act, col) {
        if (act == 'min') {
          if (page > 1) {
            page -= 1
            this[col][k]['currentPage'] -= 1
          }
        } else {
          if (page < this.ceilData(this[col][k]['totalRows'] / 5)) {
            page += 1
            this[col][k]['currentPage'] += 1
          }
        }
        
        let data = {
          type: 'select-paging-icd-10',
          page: page,
          search: this.searchICD10Name
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          this.$parent.mICD10 = res.data.data
          this.getmICD10(this.dataICD10, k)
        })
      },

      filterICD9: _.debounce(function (e, k) {
        let id = []
        for (let i = 0; i < (this.dataICD9 || []).length; i++) {
          if (this.dataICD9[i]['api9_icd_9']) {
            id.push(this.dataICD9[i]['api9_icd_9'])
          }
        }

        this.searchICD9Name = e

        Gen.apiRest('/do/' + 'RekamMedis', {
          data: {
            type: 'select-icd-9',
            search: e,
            id: id
          }
        }, 'POST').then(res => {
          this.$parent.mICD9 = res.data.data
        })
      }, 10),

      changePageICD9(page, k, act, col) {
        if (act == 'min') {
          if (page > 1) {
            page -= 1
            this[col][k]['currentPage'] -= 1
          }
        } else {
          if (page < this.ceilData(this[col][k]['totalRows'] / 5)) {
            page += 1
            this[col][k]['currentPage'] += 1
          }
        }
        let data = {
          type: 'select-paging-icd-9',
          page: page,
          search: this.searchICD9Name
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          this.$parent.mICD9 = res.data.data
          this.getmICD9(this.dataICD9, k)
        })
      },

      addNewICD9() {
        if(this.inputICD9){
          // if (!this.row.isEdit) {
          let data = {
            api9_arm_id: this.row.arm_id,
            api9_icd_9: this.inputICD9,
            type: 'add-icd-9'
          }
          Gen.apiRest(
            "/do/" + 'RekamMedis', {
              data: data
            },
            "POST"
          ).then(res => {
            let resp = res.data
            this.dataICD9.push(resp.row)
            this.inputICD9 = null
          })
          // } else {
          //   let data = {
          //     api9_arm_id: this.row.arm_id,
          //     api9_id: null
          //   }
          //   this.dataICD9.push(data)
          // }
        }
      },
      removedataICD9(id, k) {
        // if (!this.row.isEdit) {
        let data = {
          id: id,
          type: 'remove-icd-9'
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(() => {
          this.dataICD9.splice(k, 1)
        })
        // } else {
        //   this.dataICD9.splice(k, 1)
        // }
      },

      addNewICD10() {
        if(this.inputICD10){
          // if (!this.row.isEdit) {
          let data = {
            api10_arm_id: this.row.arm_id,
            api10_icd_10: this.inputICD10,
            type: 'add-icd-10'
          }
          Gen.apiRest(
            "/do/" + 'RekamMedis', {
              data: data
            },
            "POST"
          ).then(res => {
            let resp = res.data
            this.dataICD10.push(resp.row)
            this.inputICD10 = null
          })
          // } else {
          //   let data = {
          //     api10_arm_id: this.row.arm_id,
          //     api10_icd_10: this.inputICD10,
          //   }
          //   this.dataICD10.push(data)
          // }
        }
      },
      removedataICD10(id, k) {
        // if (!this.row.isEdit) {
        let data = {
          id: id,
          type: 'remove-icd-10'
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(() => {
          this.dataICD10.splice(k, 1)
        })
        // } else {
        //   this.dataICD10.splice(k, 1)
        // }
      },

      searchAlergi: _.debounce(function (e) {
        let data = {
          name: e,
          type: 'get-alergi'
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          this.$parent.mAlergi = res.data.data
        })
      }, 100),

      isAlergiLabel(nama) {
        let data = []
        for (let i = 0; i < (this.row.apsd_alergi || []).length; i++) {
          if (this.row.apsd_alergi[i]['jenis'] == 'Obat') {
            data.push(this.row.apsd_alergi[i]['name'])
          }
        }
        let isWarning = 0
        for (let i = 0; i < (data || []).length; i++) {
          let result = stringSimilarity(nama||"", data[i])
          if (result > 0.3) isWarning += 1
        }
        return isWarning
      },

      isAlergi(nama, k) {
        let data = []
        for (let i = 0; i < (this.row.apsd_alergi || []).length; i++) {
          if (this.row.apsd_alergi[i]['jenis'] == 'Obat') {
            data.push(this.row.apsd_alergi[i]['name'])
          }
        }
        let isWarning = 0
        for (let i = 0; i < (data || []).length; i++) {
          let result = stringSimilarity(nama||"", data[i])
          if (result > 0.3) isWarning += 1
        }

        this.resepDokter[k]['ard_is_alergi'] = isWarning ? "Y" : "N"
      },

      ceilData(value) {
        return Math.ceil(value)
      },
      doOpenFormRacikan() {
        let data = {
          type: 'get-all-obat'
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          this.mObat = res.data.data
          this.openFormRacikan = true
          this.openRacikan = false
        })
      },
      selectDokter(e) {
        let data = {
          id: e,
          type: 'get-by-poli'
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          this.mDokter = res.data.mDokter
        })
      },
      
      selectPoliRujukan(e){
        let data = {
          type: 'get-by-poli-fakes',
          id: e
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          this.mPoliRujukan = res.data.mPoliRujukan
        })
      },

      selectDokterRujukan(e) {
        let data = {
          id: this.row.artlr_faskes_rujuk,
          type: 'get-by-faskes',
          poli: e
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          this.mDokterRujukan = res.data.mDokterRujukan
        })
      },
      countLen() {
        setTimeout(() => {
          this.obatLen = $('.sp-col ul li').length
        }, 100)
      },
      changeAlergi(e) {
        if (!(this.row.apsd_has_alergi || []).length) {
          this.addAlergi()
        }
      },
      getConfigDynamic(master, value) {
        let text = ''
        if (value) {
          let index = (master||[]).findIndex(x => x.value == value)
          if (index !== -1) {
            text = master[index]['text']
          }
        }
        return text
      },

      doOpenLab() {
        this.openLab = true
      },
      changeTabLab(e) {
        this.activeTabLab = e
      },
      changeLab(e) {
        if (e == 'N') {
          this.row.appdl_prioritas = null
          this.row.appdl_fasting = null
          this.row.appdl_catatan = null
          this.resetLab()
        } else {
          this.row.appdl_prioritas = 2
          this.row.appdl_fasting = 'N'
        }
      },
      isShowHeadLab(i) {
        let isData = 0
        for (let j = 0; j < (this.row.appdl_data[i]['dubData'] || []).length; j++) {
          for (let k = 0; k < (this.row.appdl_data[i]['dubData'][j]['data'] || []).length; k++) {
            if (this.row.appdl_data[i]['dubData'][j]['data'][k]['selected']) {
              isData += 1
            }
          }
        }
        return isData
      },
      isShowHeadSubHeadLab(i, j) {
        let isData = 0
        for (let k = 0; k < (this.row.appdl_data[i]['dubData'][j]['data'] || []).length; k++) {
          if (this.row.appdl_data[i]['dubData'][j]['data'][k]['selected']) {
            isData += 1
          }
        }
        return isData
      },
      isShowLab(i, j, k) {
        let isData = 0
        if (this.row.appdl_data[i]['dubData'][j]['data'][k]['selected']) {
          isData += 1
        }
        return isData
      },
      resetLab() {
        for (let i = 0; i < (this.row.appdl_data || []).length; i++) {
          for (let j = 0; j < (this.row.appdl_data[i]['dubData'] || []).length; j++) {
            for (let k = 0; k < (this.row.appdl_data[i]['dubData'][j]['data'] || []).length; k++) {
              this.row.appdl_data[i]['dubData'][j]['data'][k]['selected'] = false
            }
          }
        }
      },

      doOpenRadiologi() {
        this.openRadiologi = true
      },
      changeTabRadiologi(e) {
        this.activeTabRadiologi = e
      },
      changeRadiologi(e) {
        const mRadiologi = this.mRadiologi
        if (e == 'N') {
          this.row.appdr_prioritas = null
          this.row.appdr_alergi = null
          this.row.appdr_catatan = null
          this.row.appdr_data = mRadiologi
          this.row.appdr_to_radio = null
          this.resetRadiologi()
        } else {
          this.row.appdr_prioritas = 2
          this.row.appdr_alergi = "N"
        }
      },
      isShowHeadRadiologi(j) {
        let isData = 0
        for (let k = 0; k < (this.row.appdr_data[j]['data'] || []).length; k++) {
          if (this.row.appdr_data[j]['data'][k]['selected']) {
            isData += 1
          }
        }
        return isData
      },
      isShowRadiologi(j, k) {
        let isData = 0
        if (this.row.appdr_data[j]['data'][k]['selected']) {
          isData += 1
        }
        return isData
      },
      resetRadiologi() {
        for (let i = 0; i < (this.row.appdr_data || []).length; i++) {
          for (let j = 0; j < (this.row.appdr_data[i]['data'] || []).length; j++) {
            this.row.appdr_data[i]['data'][j]['selected'] = false
          }
        }
      },

      searchKeluhanData: _.debounce(function () {
        let data = {
          search: this.searchKeluhan,
          ar_ap_id: this.rowReg.ar_ap_id,
          arm_id: this.row.arm_id,
          type: 'search-keluhan'
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          let resp = res.data
          this.$parent.riwayatKeluhan = resp.data
        })
      }, 500),

      saveKeluhan() {
        if (this.selectedKeluhan) {
          let data = {
            id: this.selectedKeluhan,
            apsd_id: this.row.apsd_id,
            type: 'save-riwayat-keluhan'
          }
          Gen.apiRest(
            "/do/" + 'RekamMedis', {
              data: data
            },
            "POST"
          ).then(() => {
            this.openRiwayatKeluhan = false
            this.selectedKeluhan = ''
            this.resetData()
          })
        }
      },


      searchICD10Data: _.debounce(function () {
        let data = {
          search: this.searchICD10,
          ar_ap_id: this.rowReg.ar_ap_id,
          arm_id: this.row.arm_id,
          type: 'search-icd-10'
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          let resp = res.data
          this.$parent.riwayatICD10 = resp.data
        })
      }, 500),

      searchICD9Data: _.debounce(function () {
        let data = {
          search: this.searchICD9,
          ar_ap_id: this.rowReg.ar_ap_id,
          arm_id: this.row.arm_id,
          type: 'search-icd-9'
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          let resp = res.data
          this.$parent.riwayatICD9 = resp.data
        })
      }, 500),


      saveICD10() {
        if (this.selectedICD10) {
          let data = {
            id: this.selectedICD10,
            arm_id: this.row.arm_id,
            type: 'save-riwayat-icd-10'
          }
          Gen.apiRest(
            "/do/" + 'RekamMedis', {
              data: data
            },
            "POST"
          ).then(() => {
            this.openriwayatICD10 = false
            this.resetData()
          })
        }
      },

      saveICD9() {
        if (this.selectedICD9) {
          let data = {
            id: this.selectedICD9,
            arm_id: this.row.arm_id,
            type: 'save-riwayat-icd-9'
          }
          Gen.apiRest(
            "/do/" + 'RekamMedis', {
              data: data
            },
            "POST"
          ).then(() => {
            this.openriwayatICD9 = false
            this.resetData()
          })
        }
      },

      searchObatData: _.debounce(function () {
        let data = {
          search: this.searchObat,
          ar_ap_id: this.rowReg.ar_ap_id,
          arm_id: this.row.arm_id,
          type: 'search-obat'
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          let resp = res.data
          this.$parent.riwayatObat = resp.data
        })
      }, 500),

      saveObat() {
        if (this.selectedObat) {
          let data = {
            id: this.selectedObat,
            arm_id: this.row.arm_id,
            ar_id: this.rowReg.ar_id,
            type: 'save-riwayat-obat'
          }
          Gen.apiRest(
            "/do/" + 'RekamMedis', {
              data: data
            },
            "POST"
          ).then(() => {
            this.openRiwayatObat = false
            this.resetData()
          })
        }
      },


      resetData() {
        this.searchKeluhan = ''
        this.searchObat = ''
        this.searchICD10 = '',
          this.searchICD9 = '',
          this.openFormRacikan = false
        this.$parent.apiGet()
      },

      getObat(val) {
        let index = this.mObatPreferensi.findIndex(x => x.value == val)
        if (index !== -1) {
          return this.mObatPreferensi[index]['text']
        } else {
          return null
        }
      },

      editAnatomi(k) {
        this.row.apod_lokasi_nyeri[k]['isEdit'] = true
      },
      saveAnatomi(k) {
        this.row.apod_lokasi_nyeri[k]['isEdit'] = false
      },
      changeTabAnat(k, isAdd = false) {
        this.activeAnat = k
        setTimeout(() => {
          if (isAdd) {
            this.setPosisionNyeri()
          }
        }, 100)
      },
      deleteAnatomi(key) {
        this.row.apod_lokasi_nyeri.splice(key, 1)
        this.activeAnat = (this.row.apod_lokasi_nyeri || []).length - 1
      },
      addAnatomi() {
        this.row.apod_lokasi_nyeri = this.row.apod_lokasi_nyeri ? this.row.apod_lokasi_nyeri : []
        this.row.apod_lokasi_nyeri.push({
          name: '',
          type: null,
          image: '',
          isEdit: true,
          data: [],
        })
        this.activeAnat = (this.row.apod_lokasi_nyeri || []).length - 1
      },
      selectStatusLokalis(e, k) {
        let idx = this.Config.mr.configStatusLokalis.findIndex(x => x.value == e)
        if (idx !== -1) {
          let data = this.Config.mr.configStatusLokalis[idx]
          this.row.apod_lokasi_nyeri[k]['name'] = data.text
          if (this.row.ap_gender == 2) {
            this.row.apod_lokasi_nyeri[k]['image'] = data.image2
          } else {
            this.row.apod_lokasi_nyeri[k]['image'] = data.image
          }
        }

        this.row.apod_lokasi_nyeri[this.activeAnat]['data'] = []

        if (!$("#anatomiNyeri" + this.activeAnat).length) {
          this.changeTabAnat(k, true)
        } else {
          this.changeTabAnat(k)
        }
      },

      changePageObat(page, k, act, col) {
        if (act == 'min') {
          if (page > 1) {
            page -= 1
            this[col][k]['currentPage'] -= 1
          }
        } else {
          if (page < this.ceilData(this[col][k]['totalRows'] / 10)) {
            page += 1
            this[col][k]['currentPage'] += 1
          }
        }

        let data = {
          type: 'select-paging-obat',
          page: page,
          idDokter: this.rowReg.ar_dokter_id,
          search: this[col][k]['search']
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          this.$parent.mObatPreferensi = res.data.data
        })
      },

      back() {
        this.$router.back()
      },

      openObat(v = null) {
        let data = {
          ard_ar_id: this.row.arm_ar_id,
          ard_arm_id: this.row.arm_id,
          ard_jenis: 1,
          ard_item_id: null,
          ard_nama: null,
          ard_jumlah: null,
          ard_satuan: null,
          ard_frekuensi: null,
          ard_frekuensi_lainnya: null,
          ard_keterangan: null,
          id: null
        }
        this.addMasterAlergi()
        if (!this.row.isEdit) {
          data.type = 'add-resep-dokter'
          Gen.apiRest(
            "/do/" + 'RekamMedis', {
              data: data
            },
            "POST"
          ).then(res => {
            this.$parent.apiGet()
          })
        } else {
          this.resepDokter.push(data)
        }

      },

      getmICD9(diagnosa, k) {
        const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
          return k2 != k
        }), v2 => {
          return v2.api9_icd_9
        })
        return _.filter(this.mICD9, v2 => {
          return sldMenu.indexOf(v2.value) <= -1
        })
      },

      getmICD10(diagnosa, k) {
        const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
          return k2 != k
        }), v2 => {
          return v2.api10_icd_10
        })
        return _.filter(this.mICD10, v2 => {
          return sldMenu.indexOf(v2.value) <= -1
        })
      },

      getStatusLokalis(data, k) {
        const sldMenu = _.map(_.filter(data || [], (v2, k2) => {
          return k2 != k
        }), v2 => {
          return v2.type
        })
        return _.filter(this.Config.mr.configStatusLokalis, v2 => {
          return sldMenu.indexOf(v2.value) <= -1
        })
      },

      getmLab(diagnosa, k) {
        const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
          return k2 != k
        }), v2 => {
          return v2.pemeriksaan
        })
        return _.filter(this.mLab, v2 => {
          return sldMenu.indexOf(v2.value) <= -1
        })
      },

      getmRadiologi(diagnosa, k) {
        const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
          return k2 != k
        }), v2 => {
          return v2.pemeriksaan
        })
        return _.filter(this.mRadiologi, v2 => {
          return sldMenu.indexOf(v2.value) <= -1
        })
      },

      removeAnat(k, kp) {
        this.row.apod_lokasi_nyeri[kp]['data'].splice(k, 1)
      },
      closeAnat(k, kp) {
        if (this.row.apod_lokasi_nyeri[kp]['data'][k]['value']) {
          this.row.apod_lokasi_nyeri[kp]['data'][k]['isOpen'] = false
          this.row.apod_lokasi_nyeri[kp]['data'][k]['y'] += (62 / $('#anatomiNyeri' + this.activeAnat).height()) * 100
        }
      },
      openAnat(k, kp) {
        this.row.apod_lokasi_nyeri[kp]['data'][k]['y'] -= (62 / $('#anatomiNyeri' + this.activeAnat).height()) * 100
        this.row.apod_lokasi_nyeri[kp]['data'][k]['isOpen'] = true
      },

      addAlergi() {
        this.row.apsd_alergi.push({
          jenis: null,
          name: ''
        })
        this.addMasterAlergi()
      },

      addMasterAlergi() {
        for (let i = 0; i < (this.row.apsd_alergi || []).length; i++) {
          let newData = this.row.apsd_alergi[i]

          if ((newData.name || "").length && newData.jenis == "Obat") {
            let dataPost = {
              type: 'auto-save-riwayat',
              name: newData.name
            }
            Gen.apiRest(
              "/do/" + 'RekamMedis', {
                data: dataPost
              },
              "POST"
            )
          }
        }
      },

      removeAlergi(k) {
        this.row.apsd_alergi.splice(k, 1)
      },


      toValidate(val) {
        return {
          ...val
        }
      },

      hitungBMI() {
        if (this.row.apod_ttv_weight && this.row.apod_ttv_height) {
          this.row.apod_ttv_bmi = this.row.apod_ttv_weight / this.row.apod_ttv_height
          this.row.apod_ttv_bmi = this.row.apod_ttv_bmi.toFixed(2)
        }
      },

      changeHPL() {
        let dateHPL = moment(this.row.apod_hpht, "DD-MM-YYYY").format('YYYY-M-D')
        let splDate = dateHPL.split("-")
        let day = splDate[2]
        let month = splDate[1]
        let year = splDate[0]

        day = +day + 7
        if (month == 1) {
          month = 10
        } else if (month == 2) {
          month = 11
        } else if (month == 3) {
          month = 12
        } else {
          month = +month - 3
        }
        year = +year + 1

        let daysOfMonth = moment(+year + "-" + month + "-01").endOf("month").format("D")
        daysOfMonth = +daysOfMonth

        let newDay

        if (day > daysOfMonth) {
          newDay = day - daysOfMonth
          month = month + 1
        } else {
          newDay = day
        }

        let newdateHPL = moment((newDay + '-' + month + '-' + year), "DD-MM-YYYY").format('YYYY-MM-DD')

        this.row.apod_hpl = newdateHPL
      },
      autoSave: _.debounce(function (data) {
        data.type = 'auto-save-dokter'
        if (!data.isEdit) {
          Gen.apiRest(
            "/do/" + 'RekamMedis', {
              data: data
            },
            "POST"
          )
        }
      }, 1000),

      autoSaveICD9: _.debounce(function (data) {
        let dataPost = {
          type: 'auto-save-icd-9',
          data: data
        }
        if (!this.row.isEdit) {
          Gen.apiRest(
            "/do/" + 'RekamMedis', {
              data: dataPost
            },
            "POST"
          )
        }
      }, 1000),

      autoSaveICD10: _.debounce(function (data) {
        let dataPost = {
          type: 'auto-save-icd-10',
          data: data
        }
        if (!this.row.isEdit) {
          Gen.apiRest(
            "/do/" + 'RekamMedis', {
              data: dataPost
            },
            "POST"
          )
        }
      }, 1000),

      autoSaveResepObat: _.debounce(function (data) {
        let dataPost = {
          type: 'auto-save-resep-obat',
          data: data
        }
        if (!this.row.isEdit) {
          Gen.apiRest(
            "/do/" + 'RekamMedis', {
              data: dataPost
            },
            "POST"
          )
        }
      }, 1000),

      setPosisionNyeri() {
        let posX = false
        let posY = false
        let self = this

        $("body").mousemove(function (e) {
          if (!$("body").hasClass("active")) $("body").addClass("active")
          if ($("#anatomiNyeri" + self.activeAnat).length) {
            posX = parseInt(e.pageX - ($("#anatomiNyeri" + self.activeAnat).offset().left + 8))
            posY = parseInt(e.pageY - ($("#anatomiNyeri" + self.activeAnat).offset().top + 52))
          }
        })


        $('#anatomiNyeri' + self.activeAnat).on('click', function (e) {
          e.stopPropagation()
          let index = self.row.apod_lokasi_nyeri[self.activeAnat]['data'].findIndex(x => x.isOpen == true)

          if (self.row.apod_lokasi_nyeri[self.activeAnat]['isEdit']) {
            if (index !== -1) {
              self.row.apod_lokasi_nyeri[self.activeAnat]['data'].splice(index, 1)
            } else {
              self.row.apod_lokasi_nyeri[self.activeAnat]['data'].push({
                x: (posX / $(this).width()) * 100,
                y: (posY / $(this).height()) * 100,
                value: '',
                isOpen: true,
                isReversed: posX > 300 ? true : false,
                reverseCount: `-111%`
                // reverseCount: `-${((posX - 300) / 2) - 16 + 300}px`
              })
            }
          }
        })
      },

      toRacikan(v) {
        this.$parent.loadingOverlay = true
        let data = {
          id: v.ard_item_id,
          type: 'get-racikan-data'
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          this.$parent.loadingOverlay = false
          this.rowRacikan = {
            ...v,
            ...res.data.row
          }

          this.openRacikan = true
        })
      },
      addObat() {
        this.rowRacikan.ard_racikan.push({
          value: null,
          dosis: null,
          satuan_dosis: null,
          jumlah: null,
          satuan_jumlah: null,
        })
      },
      removeObat(v, k) {
        if (!this.row.isEdit) {
          let data = {
            type: 'remove-resep-dokter',
            id: v.ard_id
          }
          Gen.apiRest(
            "/do/" + 'RekamMedis', {
              data: data
            },
            "POST"
          ).then(res => {
            this.resepDokter.splice(k, 1)
          })
        } else {
          this.resepDokter.splice(k, 1)
        }
      },
      selectJumlahSatuan(e, k) {
        let data = {
          id: e,
          type: 'get-by-obat'
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          this.resepDokter[k].ard_satuan = res.data.satuan
          this.resepDokter[k].ard_nama = res.data.nama
          this.resepDokter[k].ard_racikan = null

          this.resepDokter[k].ard_dosis = res.data.dosis
          this.resepDokter[k].ard_satuan_dosis = res.data.satuan_dosis
          this.resepDokter[k].ard_item_lainnya = null

          this.isAlergi(res.data.nama, k)
        })
      },
      selectJumlahSatuanAlkes(e, k) {
        let data = {
          id: e,
          type: 'get-by-alkes'
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          this.resepDokter[k].ard_satuan = res.data.satuan
          this.resepDokter[k].ard_nama = res.data.nama
          this.resepDokter[k].ard_racikan = null

          this.resepDokter[k].ard_dosis = null
          this.resepDokter[k].ard_satuan_dosis = null
          this.resepDokter[k].ard_frekuensi = null
          this.resepDokter[k].ard_frekuensi_lainnya = null
          this.resepDokter[k].ard_item_lainnya = null
        })
      },
      selectJumlahSatuanRacikan(e, k) {
        let data = {
          id: e,
          type: 'get-by-racikan'
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          this.resepDokter[k].ard_satuan = res.data.satuan
          this.resepDokter[k].ard_nama = res.data.nama
          this.resepDokter[k].ard_racikan = res.data.data

          this.resepDokter[k].ard_dosis = null
          this.resepDokter[k].ard_satuan_dosis = null
          this.resepDokter[k].ard_frekuensi = null
          this.resepDokter[k].ard_frekuensi_lainnya = null
          this.resepDokter[k].ard_item_lainnya = null
        })
      },
      changeVital(field, n) {
        if (n == 'N') {
          this.row[field + '_text'] = 'TAK'
        } else {
          this.row[field + '_text'] = ''
        }
      },
      changeJenis(e, k) {
        this.resepDokter[k].ard_item_id = null
        this.resepDokter[k].ard_frekuensi = null
        this.resepDokter[k].ard_frekuensi_lainnya = null
      },
      doSubmitDokter() {

        if (this.row.arm_is_active == 'N') {
          return this.$swal({
            icon: 'error',
            title: 'Data Telah tidak Aktif',
            text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
          })
        }

        if (this.row.appdl_is_lab  == 'Y' && !this.selectedLabInput) {
          return this.$swal({
            icon: 'error',
            title: 'Tidak Bisa Lanjut',
            text: 'Data Tindakan Lab Masih Kosong'
          })
        }

        if (this.row.appdr_is_radio  == 'Y' && !this.selectedRadioInput) {
          return this.$swal({
            icon: 'error',
            title: 'Tidak Bisa Lanjut',
            text: 'Data Tindakan Radiologi Masih Kosong'          
          })
        }

        if (!this.row.apod_desc && !(this.row.apod_lokasi_nyeri || []).length) {
          return this.$swal({
            icon: 'error',
            title: 'Maaf, data yang Anda isi belum lengkap',
            text: 'Mohon Isi Data Keadaan Umum / Status Lokalis'
          }).then(result => {
            if (result.value) {
              setTimeout(() => {
                window.scrollTo(0, 0)
              }, 500)
            }
          })
        }

        this.$refs['VFormDokter'].validate().then(success => {
          if (!success) {
            return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
            }).then(result => {
              if (result.value) {
                setTimeout(() => {
                  let inv = []
                  let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                  for (let i = 0; i < (el || []).length; i++) {
                    if (el[i].style.display !== 'none') {
                      inv.push(el[i].id)
                    }
                  }


                  let elPlanning = document.querySelectorAll("#planning .label_error:not([style='display: none;'])")
                 if((elPlanning||[]).length){
                    this.cardKajianCollapse['planning'] = true        
                  }

                  let elAssesment = document.querySelectorAll("#assesment .label_error:not([style='display: none;'])")
                  if((elAssesment||[]).length){
                    this.cardKajianCollapse['assessment'] = true        
                  }
                  
                  let elObjectif = document.querySelectorAll("#objectif .label_error:not([style='display: none;'])")
                  if((elObjectif||[]).length){
                    this.cardKajianCollapse['objective'] = true        
                  }
                  
                  let elSubjectif = document.querySelectorAll("#subjectif .label_error:not([style='display: none;'])")
                  if((elSubjectif||[]).length){
                    this.cardKajianCollapse['subjective'] = true        
                  }

                  if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                  })
                }, 500)
              }
            })
          }

          if (success) {
            this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
            }).then(result => {
              if (result.value) {
                let data = this.row
                data.type = 'submit-data-dokter'
                data.dataICD9 = this.dataICD9
                data.dataICD10 = this.dataICD10
                data.resepDokter = this.resepDokter
                data.ar_is_rujukan = this.rowReg.ar_is_rujukan                
                
                if(data.artlr_type == 'RI'){
                  data.ar_rujukan_to = this.row.artlr_dokter
                  data.ar_rujukan_poli_to = this.row.artlr_poli
                }else if(data.artlr_type == 'RK' || data.artlr_type == 'RP'){
                  data.ar_rujukan_poli_to = this.row.artlr_poli_rujuk
                }

                this.$parent.loadingOverlay = true
                Gen.apiRest(
                  "/do/" + 'RekamMedis', {
                    data: data
                  },
                  "POST"
                ).then(res => {
                  this.$parent.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  }).then(result => {
                    if (result.value) {
                      if (this.$parent.user.levelId == 1) {
                        this.$router.push({
                          name: 'RekamMedis',
                          params: {
                            pageSlug: this.row.arm_pasien_id
                          },
                          query: {
                            regId: this.row.arm_ar_id,
                            byPassLevel: this.$parent.user.levelId == 1 ? this.$parent.uDokter :
                              null
                          }
                        }).catch(() => {})
                      } else {
                        this.$router.push({
                          name: 'RekamMedis',
                          params: {
                            pageSlug: this.row.arm_pasien_id
                          },
                          query: {
                            regId: this.row.arm_ar_id
                          }
                        }).catch(() => {})
                      }
                      
                      this.$socket.emit('refresh_data', {to : this.$parent.uRadiologi})
                      this.$socket.emit('refresh_data', {to : this.$parent.uLab})
                      this.$socket.emit('refresh_data', {to : this.$parent.uDokterGizi})
                      this.$socket.emit('refresh_data', {to : this.$parent.uFisio})
                      this.$socket.emit('refresh_data', {to : 'ALLPJ'})
                      this.$socket.emit('refresh_data', {to : 'Farmasi'})

                      
                    }
                  })
                }).catch(err => {
                  this.$parent.loadingOverlay = false
                  if (err) {
                    err.statusType = err.status
                    err.status = "error"
                    err.title = err.response?.data?.title
                    err.message = err.response?.data?.message
                    err.messageError = err.message
                  }
                  this.$parent.doSetAlertForm(err)
                })

                // 934?regId=1367
              }
            })
          }
        })
      },
      resetObat() {
        this.$parent.loadingOverlay = true
        let data = {
          id: this.row.arm_id,
          type: 'get-resep-dokter'
        }
        Gen.apiRest(
          "/do/" + 'RekamMedis', {
            data: data
          },
          "POST"
        ).then(res => {
          let resp = res.data
          this.resepDokter = resp.data
          this.$parent.loadingOverlay = false
        })
      },

      doSubmitRacikan() {
        this.$refs['VFormRacikan'].validate().then(success => {
          if (success) {
            let data = this.rowRacikan
            let idx = this.resepDokter.findIndex(x => x.ard_id == this.rowRacikan.ard_id)
            this.resepDokter[idx]['ard_satuan'] = this.rowRacikan.ard_satuan
            this.resepDokter[idx]['ard_jumlah'] = this.rowRacikan.ard_jumlah
            this.resepDokter[idx]['ard_racikan'] = this.rowRacikan.ard_racikan
            this.openFormRacikan = false
          } else {
            setTimeout(() => {
              let inv = []
              let el = document.querySelectorAll('.label_error')
              for (let i = 0; i < (el || []).length; i++) {
                if (el[i].style.display !== 'none') {
                  inv.push(el[i].id)
                }
              }
              if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              })
            }, 500)
          }
        })
      },

      getMasterObat(data, k) {
        const sldMenu = _.map(_.filter(data || [], (v2, k2) => {
          return k2 != k
        }), v2 => {
          return v2.value
        })
        return _.filter(this.mObat, v2 => {
          return sldMenu.indexOf(v2.value) <= -1
        })
      },
      selectObat(e, k) {
        let index = this.mObat.findIndex(x => x.value == e)
        if (index !== -1) {
          let data = this.mObat[index]
          this.rowRacikan.ard_racikan[k]['dosis'] = data.dosis
          this.rowRacikan.ard_racikan[k]['satuan_dosis'] = data.satuan_dosis
          this.rowRacikan.ard_racikan[k]['satuan_jumlah'] = data.satuan
        }
      },

      filterObat: _.debounce(function (e, k) {
        if (e) {
          this.resepDokter[k]['search'] = e
          let id = []
          for (let i = 0; i < (this.resepDokter || []).length; i++) {
            if (this.resepDokter[i]['ard_item_id'] && this.resepDokter[i]['ard_jenis'] == 1) {
              id.push(this.resepDokter[i]['ard_item_id'])
            }
          }
          Gen.apiRest('/do/' + 'RekamMedis', {
            data: {
              type: 'select-obat',
              search: e,
              id: id
            }
          }, 'POST').then(res => {
            this.$parent.mObatPreferensi = res.data.data
            this.resepDokter[k]['currentPage'] = 1
            this.resepDokter[k]['totalRows'] = res.data.totalRows
          })
        }
      }, 10),

      checkDateIsLibur(e){
        let year = moment(e, "YYYY").format('YYYY')
        let data = {
            year: year,
            type: 'get-holiday-config'
        }
        Gen.apiRest(
            "/do/" + 'RekamMedis', {
                data: data
            },
            "POST"
        ).then(res => {
            this.mHariLibur = res.data.mHariLibur
        })
      }

    },
    mounted() {
      document.body.classList.add('sidebar-xs')
      setTimeout(() => {
        this.setPosisionNyeri()
        if (!(this.mRacikan || []).length) {
          this.Config.mr.configResep[2]['disabled'] = true
        }
        this.row.arm_notes = null
      }, 1500)
    },
    watch: {
      'row.atld_is_edukasi'(v){
        if(v == "Y"){
          this.row.atld_cara_pulang.push('P')
        }else{
          let index = this.row.atld_cara_pulang.findIndex(x => x == 'P')
          if(index != -1){
            this.row.atld_cara_pulang.splice(index,1)
          }
        }
      },
      
      'row.atld_is_rawat_inap'(v){
        if(v == "Y"){
          this.row.atld_cara_pulang.push('RI')
        }else{
          let index = this.row.atld_cara_pulang.findIndex(x => x == 'RI')
          if(index != -1){
            this.row.atld_cara_pulang.splice(index,1)
          }
        }
      },

      'row.atld_is_dalam_perawatan'(v){
        if(v != "Y" && this.row.atld_is_done != "Y"){
          this.row.atld_is_kontrol = "N"
          this.rowReg.ar_is_rujukan = "N"
        }

        if(v != "Y" && (this.row.artlr_type == 'RI' || this.row.artlr_type == 'RP')){
            this.row.apdf_is_kontrol = "N"
            this.rowReg.ar_is_rujukan = "N"
        }

        if(v == "Y"){
          this.row.atld_cara_pulang.push('K')
          this.row.atld_is_kontrol = "Y"
        }else{
          let index = this.row.atld_cara_pulang.findIndex(x => x == 'K')
          if(index != -1){
            this.row.atld_cara_pulang.splice(index,1)
          }
        }
      },
      
      'row.atld_is_done'(v){
        if(v != "Y" && this.row.atld_is_dalam_perawatan != "Y"){
          this.row.atld_is_kontrol = "N"
          this.rowReg.ar_is_rujukan = "N"
        }

        if(v == "Y"){
          this.rowReg.ar_is_rujukan = "Y"
        }
        
        if(v != "Y" && (this.row.artlr_type == 'RK' || this.row.artlr_type == 'RB' || this.row.artlr_type == 'RBN')){
          this.row.apdf_is_kontrol = "N"
          this.rowReg.ar_is_rujukan = "N"
        }

        if(v == "Y"){
          this.row.atld_cara_pulang.push('RU')
        }else{
          let index = this.row.atld_cara_pulang.findIndex(x => x == 'RU')
          if(index != -1){
            this.row.atld_cara_pulang.splice(index,1)
          }
        }
      },

      row: {
        handler(v) {
          this.autoSave(v)
        },
        deep: true
      },

      dataICD9: {
        handler(v) {
          this.autoSaveICD9(v)
        },
        deep: true
      },
      dataICD10: {
        handler(v) {
          this.autoSaveICD10(v)
        },
        deep: true
      },

      resepDokter: {
        handler(v) {
          this.autoSaveResepObat(v)
        },
        deep: true
      },
      
      openAddObat(v) {
        if (!v) {
          this.resetObat()
        }
      }
    }
  }
</script>