<template>
    <div class="content">
        <b-form @submit.prevent="handleSubmit(doSubmit())">
        <div class="card">
            <div class="card-header bg-white" style="border-bottom: 1px solid rgba(0,0,0,.125);">
                <h6 class="card-title font-weight-semibold">Kajian Awal Perawat</h6>
            </div>
            <b-card-body>
                <VStepTab :step.sync="stepTab" :last="Steps.length" :config="stepValidate">
                    <template slot="link">
                        <template v-for="(v,k) in Steps">
                            <VStepLink :step.sync="stepTab" :option="k + 1" :key="k" :clickable="(k+1) < stepTab">
                                {{ v.subtitle }}
                            </VStepLink>
                        </template>
                    </template>
                    <template slot="content">
                        <template v-for="(v,k) in Steps">
                            <VStepContent :step.sync="stepTab" :option="k+1" :key="k">
                                <template slot="title">
                                    {{ v.subtitle }}
                                </template>
                                <template slot="fieldset">
                                    <div>
                                        <validation-observer
                                            ref="VFormKajianAwal"
                                        >
                                            <KajianAwal v-if="stepTab == 1" />
                                        </validation-observer>
                                        <validation-observer
                                            ref="VFormKajianNyeri"
                                        >
                                            <KajianNyeri v-if="stepTab == 2" />
                                        </validation-observer>
                                        <validation-observer
                                            ref="VFormResikoJatuh"
                                        >
                                            <ResikoJatuh v-if="stepTab == 3" />
                                        </validation-observer>
                                        <validation-observer
                                            ref="VFormGizi"
                                        >
                                            <SkriningGizi v-if="stepTab == 4" />
                                        </validation-observer>
                                        
                                        <validation-observer
                                            ref="VFormAsuhanKeperawatan"
                                        >
                                            <AsuhanKeperawatan v-if="stepTab == 5" />
                                        </validation-observer>

                                    </div>
                                </template>
                            </VStepContent>
                        </template>
                    </template>
                    <template slot="action">
                        <ul role="menu" aria-label="Pagination">
                            
                            <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>

                            <li :class="(stepTab==1?'disabled':'')" v-bind:aria-disabled="(stepTab==1?true:false)" @click="doPrev"><a href="javascript:" :class="'btn btn-light '+(stepTab==1?'disabled':'')" role="menuitem"><i class="icon-arrow-left13 mr-2"></i> Previous</a></li>

                            <li aria-hidden="false" aria-disabled="false" @click="doNext"><a href="javascript:" :class="'btn btn-primary '+(stepTab!=stepLast?'':'disabled')" role="menuitem">Next <i class="icon-arrow-right14 ml-2"></i></a></li>
                            

                            <li v-if="stepTab == stepLast" aria-hidden="false" aria-disabled="false"><button @click="doSubmit()"  type="button" class="btn btn-primary" role="menuitem">Submit<i class="icon-checkmark3 ml-2"></i></button></li>
                        </ul>
                    </template>
                </VStepTab> 
            </b-card-body>
        </div>
        </b-form>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import VStepTab from '@/components/VStep/VStepTab.vue'
import VStepLink from '@/components/VStep/VStepLink.vue'
import VStepContent from '@/components/VStep/VStepContent.vue'

import KajianAwal from './Awal/KajianAwal.vue'
import KajianNyeri from './Awal/KajianNyeri.vue'
import ResikoJatuh from './Awal/ResikoJatuh.vue'
import SkriningGizi from './Awal/SkriningGizi.vue'
import AsuhanKeperawatan from './Awal/AsuhanKeperawatan.vue'


import Gen from '@/libs/Gen.js'

export default{
    props:{
        row:Object,
        rowReg:Object,
        mrValidation:Object,
        mPekerjaan:Array,
        mAlatBantu:Array,
        mSkrininGizi:Array,
        mSkrininGiziV2:Array,
        diagnosa:Array,
        mSDKI: Array,
        mSLKI: Array,
        mSIKI: Array,
        mPoli: Array,
        mDokter: Array,
        mKesadaran: Array,
        Config:Object
    },
    components: {
      VStepTab, VStepLink, VStepContent, KajianAwal, KajianNyeri, ResikoJatuh, SkriningGizi, AsuhanKeperawatan
    },
    computed: {
        stepValidate(){
            return {
                disabled:false,
                validate:true,
                refs:this.$children
            }
        },
        hitungJatuh(){
            let value = 0
            for(let i = 0; i < (this.row.arj_getup_go||[]).length; i++){
                value += this.row.arj_getup_go[i]['value']
            }
            return value
        },
        ishitungJatuh(){
            let value = 0
            for(let i = 0; i < (this.row.arj_getup_go||[]).length; i++){
                value += this.row.arj_getup_go[i]['value']
            }
            return (value >= 1 || this.row.isSkipJatuh)
        },
        hitungSkala(){
            let value = 0
            for(let i = 0; i < (this.row.akn_skala_nyeri||[]).length; i++){
                value += this.row.akn_skala_nyeri[i]['value']
            }
            return value
        },
        isToGizi(){
            let value = 0
            for(let i = 0; i < (this.row.asg_data||[]).length; i++){
                value += this.row.asg_data[i]['value']
            }
            return value
        },
        isToGiziAdult(){
            let value = 0
            let data = this.mSkrininGizi.concat(this.mSkrininGiziV2)
            if(this.row.asg_value_label){
                let index = data.findIndex(x=>x.idVal == this.row.asg_value_label)
                if(index !== -1){
                    value = data[index]['valueVal']
                }   
            }
            return value
        }
    },
    data(){
        return {
            stepTab: 1,
            stepLast: 5,
            Steps: [
                {
                    icon: 'create',
                    name: 'kajian-awal',
                    subtitle: 'Kajian Awal',
                    component: KajianAwal,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'kajian-nyeri',
                    subtitle: 'Kajian Nyeri',
                    component: KajianNyeri,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'resiko-jatuh',
                    subtitle: 'Resiko Jatuh',
                    component: ResikoJatuh,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'skrining-gizi',
                    subtitle: 'Skrining Gizi',
                    component: SkriningGizi,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'asuhan-keperawatan',
                    subtitle: 'Asuhan Keperawatan',
                    component: AsuhanKeperawatan,
                    completed: false
                }
            ],
        }
    },
    methods: {
        addMasterAlergi(){
            for(let i = 0; i < (this.row.aka_alergi||[]).length; i++){
                let newData = this.row.aka_alergi[i]
                if((newData.name||"").length && newData.jenis == "Obat"){
                    let dataPost = {
                        type : 'auto-save-riwayat',
                        name : newData.name
                    }  
                    Gen.apiRest(
                        "/do/"+'RekamMedis',
                        {data:dataPost}, 
                        "POST"
                    )
                }
            }
        },
        back(){
            this.$router.back()
        },
        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },
        doPrev(){
            if(this.stepTab !== 1){
                this.stepTab = this.stepTab -1
                this.row.arm_last_step = this.stepTab
                this.autoSave(this.row)
                window.scrollTo(0,0)
            }
        },
        doNext(){
            if(this.stepTab < this.stepLast){
                if(this.stepTab == 1){
                    this.$refs['VFormKajianAwal'][0].validate().then(success=>{
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.arm_last_step = this.stepTab
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }
                else if(this.stepTab == 2){
                    this.$refs['VFormKajianNyeri'][0].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.arm_last_step = this.stepTab                
                            this.row.arj_tindakan_tambahan = this.row.arj_tindakan_tambahan ? ['Tidak Ada Tindakan'] : null
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }else if(this.stepTab == 3){
                    this.$refs['VFormResikoJatuh'][0].validate().then(success => {
                        if(success){
                            if(!this.ishitungJatuh){
                                return this.$swal({
                                    icon: 'error',
                                    title: 'Mohon Lengkapi Data Resiko Jatuh Terlebih Dahulu'
                                })
                            }
                            this.stepTab = this.stepTab + 1
                            this.row.arm_last_step = this.stepTab                
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }else if(this.stepTab == 4){
                    this.$refs['VFormGizi'][0].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.arm_last_step = this.stepTab                
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }else if(this.stepTab == 5){
                    this.$refs['VFormAsuhanKeperawatan'][0].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.arm_last_step = this.stepTab                
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }else{
                    console.log("no action")
                }
                window.scrollTo(0,0)
            }
        },
        doSubmit(){
            if(this.row.arm_is_active == 'N'){
                return this.$swal({
                    icon: 'error',
                    title: 'Data Telah tidak Aktif',
                    text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
                }).then(result => {
                    if (result.value) {
                        setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                        if(el[i].style.display !== 'none'){
                            inv.push(el[i].id)
                        }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                    }
                })
            }   
            
            let inval = 0
            this.$refs['VFormKajianAwal'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 1
                    inval = inval + 1
                    this.row.arm_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
            })
            this.$refs['VFormKajianNyeri'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 2
                    inval = inval + 1
                    this.row.arm_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                    
                }
            })
            this.$refs['VFormResikoJatuh'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 3
                    inval = inval + 1
                    this.row.arm_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
            })
            this.$refs['VFormGizi'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 4
                    inval = inval + 1
                    this.row.arm_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
            })
            this.$refs['VFormAsuhanKeperawatan'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 5
                    inval = inval + 1
                    this.row.arm_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
            })
            setTimeout(()=>{
                if(inval){
                    return this.$swal({
                        icon: 'error',
                        title: 'Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                        if(el[i].style.display !== 'none'){
                            inv.push(el[i].id)
                        }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                        }
                    })
                }
                if(!(this.diagnosa||[]).length){
                    return this.$swal({
                        icon: 'error',
                        title: 'Minimal harus ada 1 diagnosa'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                        if(el[i].style.display !== 'none'){
                            inv.push(el[i].id)
                        }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                        }
                    })
                }   

                let invalSiki = 0
                let invalSikiTab = []

                let invalSlki = 0
                let invalSlkiTab = []

                for(let i = 0; i < (this.diagnosa||[]).length; i++){
                    if(!(this.diagnosa[i]['siki']||[]).length){
                        invalSiki += 1
                        invalSikiTab.push(i+1)
                    }
                    if(!(this.diagnosa[i]['slki']||[]).length){
                        invalSlki += 1
                        invalSlkiTab.push(i+1)
                    }
                }
                
                if(invalSlki){
                    return this.$swal({
                        icon: 'error',
                        title: `SLKI Pada Tab Ke ${invalSlkiTab.join(', ')} Kosong`
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                        if(el[i].style.display !== 'none'){
                            inv.push(el[i].id)
                        }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                        }
                    })
                }   
                
                if(invalSiki){
                    return this.$swal({
                        icon: 'error',
                        title: `SIKI Pada Tab Ke ${invalSikiTab.join(', ')} Kosong`
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                        if(el[i].style.display !== 'none'){
                            inv.push(el[i].id)
                        }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                        }
                    })
                }   

                
                if(!inval){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        this.addMasterAlergi()
                        
                        if (result.value) {
                            let data = this.row
                            data.type = 'submit-data'
                            data.diagnosa = this.diagnosa

                            this.$parent.loadingOverlay = true
                            Gen.apiRest(
                                "/do/"+'RekamMedis',
                                {data:data}, 
                                "POST"
                            ).then(res=>{
                                this.$parent.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    if (result.value) {
                                        if(this.row.newPoli == 1 || this.rowReg.ar_mpo_id == 1){
                                            this.$socket.emit('refresh_data', {to : this.$parent.uDokterFisio})                                       
                                        }else{
                                            this.$socket.emit('refresh_data', {to : this.$parent.uDokter})
                                        }
                                        
                                        if(this.$parent.user.levelId == 1){
                                            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.arm_pasien_id }, query: {regId: this.row.arm_ar_id, byPassLevel: this.$parent.user.levelId == 1 ? this.$parent.uPerawat : null } }).catch(()=>{})
                                        }else{
                                            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.arm_pasien_id }, query: {regId: this.row.arm_ar_id} }).catch(()=>{})
                                        }
                                    }
                                })
                            }).catch(err=>{
                                this.$parent.loadingOverlay = false
                                if(err){
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.$parent.doSetAlertForm(err)
                            })
                            
                            // 934?regId=1367
                        }
                    })
                }
            },100)
        },
        autoSave: _.debounce(function (data) {
            data.type = 'auto-save'
            data.arm_last_step = this.row.arm_last_step
            data.arj_value = this.hitungJatuh
            data.asg_is_child = data.ap_usia < 15 ? 'Y' : 'N' 
            data.asg_value = data.ap_usia < 15 ? this.isToGizi : this.isToGiziAdult
            
            if(data.aka_status_psikologi !== 6){
                data.aka_status_psikologi_text = this.getConfigDynamic(this.Config.mr.psikologiPot,data.aka_status_psikologi)
            }   

            if(this.hitungJatuh >= 4){
                this.row.arj_hasil = "Beresiko Tinggi"
                this.row.arj_tindakan = "Pemasangan Pita Kuning, Edukasi"
                // this.row.arj_tindakan_tambahan = ['Pasang pita kuning','edukasi']

            }else{
                this.row.arj_hasil = "Beresiko Rendah"
                this.row.arj_tindakan = "Tindakan Edukasi"
                // this.row.arj_tindakan_tambahan = ['Tidak Ada Tindakan']
            }
            
            if(data.ap_usia < 15){
                data.asg_is_to_gizi = this.isToGizi ? 'Y' : 'N'
                data.arm_is_to_gizi = this.isToGizi ? 'Y' : 'N'
            }else{
                data.asg_is_to_gizi = this.isToGiziAdult ? 'Y' : 'N'
                data.arm_is_to_gizi = this.isToGiziAdult ? 'Y' : 'N'
            }

            if(data.ap_usia <= 6){
                data.akn_skala_nyeri_value = this.hitungSkala
            }

            if(!data.isEdit){
                Gen.apiRest(
                    "/do/"+'RekamMedis',
                    {data:data}, 
                    "POST"
                )
            }
        },1000),

        autoSaveDiagnosa: _.debounce(function (data) {
            let dataPost = {
                type : 'auto-save-diagnosa',
                data : data
            }  
            if(!this.row.isEdit){
                Gen.apiRest(
                    "/do/"+'RekamMedis',
                    {data:dataPost}, 
                    "POST"
                )
            }
        },1000),        
    },
    mounted(){
      document.body.classList.add('sidebar-xs')
        setTimeout(()=>{
            this.stepTab = this.row.arm_last_step||1
            this.row.arm_notes = null
        },1500)
    },
    watch:{
        row: {
            handler(v) {
                this.autoSave(v)
            },
            deep: true
        },
        diagnosa: {
            handler(v) {
                this.autoSaveDiagnosa(v)
            },
            deep: true
        },
    }

}
</script>
