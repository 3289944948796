<template>
    <div>
        <ul class="nav nav-tabs nav-tabs-bottom nav-justified mt-2 mb-0">
            <li class="nav-item"><a href="javascript:;" @click="changeTab(1)" :class="activeTab == 1 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Dokter</a></li>
            <li v-if="Object.keys(dataRiwayatRMDokterGizi||{}).length" class="nav-item"><a href="javascript:;" @click="changeTab(7)" :class="activeTab == 7 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Dietisien </a></li>
            <li class="nav-item" v-if="Object.keys(dataRiwayatRMLab||{}).length"><a href="javascript:;" @click="changeTab(2)" :class="activeTab == 2 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Laboratorium</a></li>
            <li class="nav-item" v-if="Object.keys(dataRiwayatRMRadiologi||{}).length"><a href="javascript:;" @click="changeTab(3)" :class="activeTab == 3 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Radiologi </a></li>
            <li class="nav-item" v-if="Object.keys(dataRiwayatRMFisio||{}).length"><a href="javascript:;" @click="changeTab(4)" :class="activeTab == 4 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Fisioterapi</a></li>
            <li class="nav-item" v-if="(dataRiwayatRMFarmasi||[]).length"><a href="javascript:;" @click="changeTab(6)" :class="activeTab == 6 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Farmasi</a></li>
            <li class="nav-item"><a href="javascript:;" @click="changeTab(5)" :class="activeTab == 5 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Persetujuan Pasien</a></li>
        </ul>
        <div class="tab-content card-body p-0 mt-3">
          <div :class="activeTab == 1 ? 'tab-pane fade show active' : 'tab-pane'" id="rrm-dokter">
            <div class="row">
              <div class="col-md-12">
                <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                    <li v-if="Object.keys(dataRiwayatRMDokter||{}).length" class="nav-item"><a href="javascript:;" @click="changeTabDokter(1)" :class="activeTabDokter == 1 ? 'nav-link active' : 'nav-link'" data-toggle="tab">{{dataRiwayatRMDokter.row.bu_full_name||""}} - {{dataRiwayatRMDokter.row.mpo_name||""}}</a></li>
                    
                    <li v-if="Object.keys(dataRiwayatRMDokterTerapi||{}).length && dataRiwayatRMDokterTerapi.isFound" class="nav-item"><a href="javascript:;" @click="changeTabDokter(2)" :class="activeTabDokter == 2 ? 'nav-link active' : 'nav-link'" data-toggle="tab">{{dataRiwayatRMDokterTerapi.row.bu_full_name||""}} - {{dataRiwayatRMDokterTerapi.row.mpo_name||""}}</a></li>
                </ul>
                <div class="tab-content card-body p-0">
                  <div :class="activeTabDokter == 1 ? 'tab-pane fade show active' : 'tab-pane'"  id="rrm-dr">
                    <template v-if="Object.keys(dataRiwayatRMDokter||{}).length">
                        <div class="row" v-if="dataRiwayatRMDokter.row.arm_notes">
                          <div class="col-md-12">
                            <b-alert show fade>{{dataRiwayatRMDokter.row.arm_notes||"-"}}</b-alert>
                          </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-2">
                            <label for="">Data Kajian</label>
                            <v-select @input="changeKajianDokter($event)" placeholder="Pilih Data Kajian" v-model="dataRiwayatRMDokter.row.arm_id" :options="historyKajianDokter" label="text" :reduce="v=>v.value"></v-select>
                            </div>
                        </div> 
                        <div class="row" v-if="Object.keys(dataRiwayatRMDokter||{}).length">
                            <div class="col-md-6">
                              <b-alert show fade>Nama Inputer : {{dataRiwayatRMDokter.row.bu_full_name||"-"}}</b-alert>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header bg_head_panel">
                            <h5 class="card-title font-weight-semibold">Informasi Pasien</h5>
                            </div>
                            <div class="card-body p-3">
                            <table class="table table-bordered">
                                <tbody>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>No. Rekam Medis</h4>
                                        <p>{{dataRiwayatRMDokter.row.ap_code||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Nama Pasien</h4>
                                        <p>{{dataRiwayatRMDokter.row.ap_fullname||"-"}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Tanggal Lahir</h4>
                                        <p>{{dataRiwayatRMDokter.row.ap_dob | moment("DD MMM YYYY")}}</p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Jenis Kelamin</h4>
                                        <p>{{dataRiwayatRMDokter.row.cg_label||"-"}}</p>
                                    </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                        <div class="card">
                        <div class="card-header bg-info">
                            <h5 class="card-title font-weight-semibold">Subjective</h5>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                <table class="table table-bordered">
                                    <tbody>
                                    <tr>
                                        <td>
                                        <div class="result_tab">
                                            <h4>Keluhan Utama</h4>
                                            <p>{{dataRiwayatRMDokter.row.apsd_keluhan||"-"}}</p>
                                        </div>
                                        </td>
                                        <td>
                                        <div class="result_tab">
                                            <h4>Riwayat Penyakit</h4>
                                            <p>{{dataRiwayatRMDokter.row.apsd_anamnesa||"-"}}</p>
                                        </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            <div class="col-md-6 mt-2">
                              <div class="card">
                                <div class="card-header bg_head_panel">
                                    <h6 class="card-title font-weight-semibold">Catatan Dokter</h6>
                                </div>
                                <div class="card-body">
                                  <div class="result_tab">
                                  <p>{{dataRiwayatRMDokter.row.apsd_catatan_dokter||"-"}}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </div>
                            

                            
                            <div class="form-row mt-2">
                                <div class="col-md-6">
                                    <div class="card mb-0">
                                    <div class="card-header bg_head_panel">
                                        <h6 class="card-title font-weight-semibold">Alergi</h6>
                                    </div>
                                    <div class="card-body p-3">
                                        <div class="form-row" v-if="dataRiwayatRMDokter.row.apsd_has_alergi == 'Y'">
                                        <div class="col-md-12">
                                            <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                    <th><strong>Jenis</strong></th>
                                                    <th><strong>Nama</strong></th>
                                                    </tr>
                                                    <tr v-for="(v,k) in (dataRiwayatRMDokter.row.apsd_alergi||[])" :key="k+'ob'">
                                                    <td>
                                                        <div class="result_tab">
                                                        {{v.jenis||"-"}}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="result_tab">
                                                        {{v.name||"-"}}
                                                        </div>
                                                    </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="form-row" v-else>
                                            <div class="col-md-12">
                                                <span>Tidak Ada Alergi</span>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="card mb-0">
                                    <div class="card-header bg_head_panel">
                                        <h6 class="card-title font-weight-semibold">Obat Rutin</h6>
                                    </div>
                                    <div class="card-body p-3">
                                        <div class="form-row" v-if="dataRiwayatRMDokter.row.apsd_has_obat_rutin == 'Y'">
                                        <div class="col-md-12">
                                            <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <tbody>
                                                <tr>
                                                    <td>
                                                    <div class="result_tab">
                                                        <h4>Keterangan</h4>
                                                        <p>{{dataRiwayatRMDokter.row.apsd_keterangan_obat_rutin||"-"}}</p>
                                                    </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="form-row" v-else>
                                            <div class="col-md-12">
                                                <span>Tidak Ada Alergi</span>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        </div>
                        <div class="card">
                            <div class="card-header bg-info">
                            <h5 class="card-title font-weight-semibold">Objective</h5>
                            </div>
                            <div class="card-body">
                            <div class="row">

                                <div class="col-md-12">
                                <div>
                                    <div class="form-row mt-2">
                                    <div class="col-md-12">
                                        <div class="result_tab mb-2">
                                        <h4>Objective</h4>
                                        <p>{{dataRiwayatRMDokter.row.apod_desc||"-"}}</p>
                                        </div>
                                        <div class="card mb-0">
                                          <div class="card-header bg_head_panel">
                                              <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
                                          </div>
                                          <div class="card-body p-3">
                                              <div class="form-row">
                                              <div class="col-md-12">
                                                  <div class="table-responsive">
                                                  <table class="table table-bordered">
                                                      <tbody>
                                                      <tr>
                                                          <td>
                                                          <div class="result_tab">
                                                              <h4>Tekanan Darah</h4>
                                                              <p v-if="dataRiwayatRMDokter.row.apod_ttv_tekanan_darah_min || dataRiwayatRMDokter.row.apod_ttv_tekanan_darah_max">{{dataRiwayatRMDokter.row.apod_ttv_tekanan_darah_min}} / {{dataRiwayatRMDokter.row.apod_ttv_tekanan_darah_max}} mmHG</p>
                                                              <p v-else> - </p>
                                                          </div>
                                                          </td>
                                                          <td>
                                                          <div class="result_tab">
                                                              <h4>Nadi</h4>
                                                              <p v-if="dataRiwayatRMDokter.row.apod_ttv_nadi">{{dataRiwayatRMDokter.row.apod_ttv_nadi||"-"}}/mnt</p>
                                                              <p v-else> - </p>
                                                          </div>
                                                          </td>
                                                          <td>
                                                          <div class="result_tab">
                                                              <h4>Gula Darah</h4>
                                                              <p v-if="dataRiwayatRMDokter.row.apod_ttv_gula_darah">{{dataRiwayatRMDokter.row.apod_ttv_gula_darah||"-"}}mg/dL</p>
                                                              <p v-else> - </p>
                                                          </div>
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td>
                                                          <div class="result_tab">
                                                              <h4>Pernafasan</h4>
                                                              <p v-if="dataRiwayatRMDokter.row.apod_ttv_pernafasan">{{dataRiwayatRMDokter.row.apod_ttv_pernafasan||"-"}}x/mnt - Reguler</p>
                                                              <p v-else> - </p>
                                                          </div>
                                                          </td>
                                                          <td>
                                                          <div class="result_tab">
                                                              <h4>SPO2</h4>
                                                              <p v-if="dataRiwayatRMDokter.row.apod_ttv_spo2">{{dataRiwayatRMDokter.row.apod_ttv_spo2||"-"}}%</p>
                                                              <p v-else> - </p>
                                                          </div>
                                                          </td>
                                                          <td>
                                                          <div class="result_tab">
                                                              <h4>Suhu</h4>
                                                              <p v-if="dataRiwayatRMDokter.row.apod_ttv_suhu">{{dataRiwayatRMDokter.row.apod_ttv_suhu||"-"}}C</p>
                                                              <p v-else> - </p>
                                                          </div>
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td>
                                                          <div class="result_tab">
                                                              <h4>Berat Badan</h4>
                                                              <p v-if="dataRiwayatRMDokter.row.apod_ttv_weight">{{dataRiwayatRMDokter.row.apod_ttv_weight||"-"}}kg</p>
                                                              <p v-else> - </p>
                                                          </div>
                                                          </td>
                                                          <td>
                                                          <div class="result_tab">
                                                              <h4>Tinggi</h4>
                                                              <p v-if="dataRiwayatRMDokter.row.apod_ttv_height">{{dataRiwayatRMDokter.row.apod_ttv_height||"-"}}Cm</p>
                                                              <p v-else> - </p>
                                                          </div>
                                                          </td>
                                                          <td>
                                                          <div class="result_tab">
                                                              <h4>Lingkar Kepala</h4>
                                                              <p v-if="dataRiwayatRMDokter.row.apod_ttv_lingkar_kepala">{{dataRiwayatRMDokter.row.apod_ttv_lingkar_kepala||"-"}}cm</p>
                                                              <p v-else> - </p>
                                                          </div>
                                                          </td>
                                                      </tr>
                                                      <tr>
                                                          <td v-if="dataRiwayatRMDokter.row.ap_usia >= 1">
                                                          <div class="result_tab">
                                                              <h4>BMI</h4>
                                                              <p v-if="dataRiwayatRMDokter.row.apod_ttv_bmi">{{dataRiwayatRMDokter.row.apod_ttv_bmi}}m2</p>
                                                              <p v-else> - </p>
                                                          </div>
                                                          </td>
                                                          <td v-if="dataRiwayatRMDokter.row.ap_usia <= 15">
                                                              <div class="result_tab">
                                                              <h4>Luas Permukaan Tubuh Anak</h4>
                                                              <p v-if="dataRiwayatRMDokter.row.apod_ttv_luas_permukaan_anak">{{dataRiwayatRMDokter.row.apod_ttv_luas_permukaan_anak}}kg/m2</p>
                                                              <p v-else> - </p>
                                                              </div>
                                                          </td>
                                                          <td>
                                                          <div class="result_tab">
                                                              <h4>Kesadaran</h4>
                                                              <p>{{dataRiwayatRMDokter.row.mk_name
                                                              ||"-"}}</p>
                                                          </div>
                                                          </td>
                                                      </tr>
                                                      </tbody>
                                                  </table>
                                                  </div>
                                              </div>
                                              </div>
                                          </div>
                                        </div>
                                        
                                        <div class="card mb-0" v-if="(row.ap_gender == 2||row.ap_gender == 0) && row.ap_usia >= 12 && row.ap_usia <= 60">
                                          <div class="card-header bg_head_panel">
                                            <h6 class="card-title font-weight-semibold">Status Kehamilan</h6>
                                          </div>
                                          <div class="card-body p-3">
                                            <div class="form-row">
                                              <div class="col-md-12">
                                                <div class="table-responsive">
                                                  <table class="table table-bordered">
                                                    <tbody>
                                                      <tr>
                                                        <td>
                                                          <div class="result_tab">
                                                            <h4>Pasien Sedang Hamil</h4>
                                                            <p>{{dataRiwayatRMDokter.row.apod_is_hamil == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <div class="result_tab">
                                                            <h4>Pasien Menyusui</h4>
                                                            <p>{{dataRiwayatRMDokter.row.apod_is_menyusui == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                                          </div>
                                                        </td>
                                                        <td v-if="dataRiwayatRMDokter.row.apod_is_hamil == 'Y'">
                                                          <div class="result_tab">
                                                            <h4>Tinggi Fungus</h4>
                                                            <p>{{dataRiwayatRMDokter.row.apod_tinggi_fungus || "-"}}</p>
                                                          </div>
                                                        </td>
                                                        <td v-if="dataRiwayatRMDokter.row.apod_is_hamil == 'Y'">
                                                          <div class="result_tab">
                                                            <h4>Gravid</h4>
                                                            <p>{{dataRiwayatRMDokter.row.apod_gravid || "-"}}</p>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td v-if="dataRiwayatRMDokter.row.apod_is_hamil == 'Y'">
                                                          <div class="result_tab">
                                                            <h4>Para</h4>
                                                            <p>{{dataRiwayatRMDokter.row.apod_para || "-"}}</p>
                                                          </div>
                                                        </td>
                                                        <td v-if="dataRiwayatRMDokter.row.apod_is_hamil == 'Y'">
                                                          <div class="result_tab">
                                                            <h4>Abortus</h4>
                                                            <p>{{dataRiwayatRMDokter.row.apod_abortus || "-"}}</p>
                                                          </div>
                                                        </td>
                                                        <td v-if="dataRiwayatRMDokter.row.apod_is_hamil == 'Y'">
                                                          <div class="result_tab">
                                                            <h4>HPHT</h4>
                                                            <p>{{dataRiwayatRMDokter.row.apod_hpht | moment("DD MMM YYYY")}}</p>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td colspan="3" v-if="dataRiwayatRMDokter.row.apod_is_hamil == 'Y'">
                                                          <div class="result_tab">
                                                            <h4>HPL</h4>
                                                            <p>{{dataRiwayatRMDokter.row.apod_hpl | moment("DD MMM YYYY")}}</p>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        
                                        <div class="card">
                                          <div class="card-header bg_head_panel d-flex align-items-center justify-content-between" @click="keadaanUmum = !keadaanUmum">
                                            <h6 class="card-title font-weight-semibold">Keadaan Umum</h6>
                                            <i class="icon-chevron-down"></i>
                                          </div>
                                          <div class="card-body p-3" v-if="keadaanUmum">
                                            <table class="table table-bordered">
                                              <tr>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Kepala</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_kepala == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_kepala == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_kepala_text||"-"}}</p>
                                                  </div>
                                                </td>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Mata</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_mata == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_mata == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_mata_text||"-"}}</p>
                                                  </div>
                                                </td>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Hidung</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_hidung == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_hidung == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_hidung_text||"-"}}</p>
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Rambut</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_rambut == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_rambut == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_rambut_text||"-"}}</p>
                                                  </div>
                                                </td>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Bibir</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_bibir == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_bibir == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_bibir_text||"-"}}</p>
                                                  </div>
                                                </td>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Gigi Geligi</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_gigi == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_gigi == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_gigi_text||"-"}}</p>
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                               <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Lidah</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_lidah == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_lidah == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_lidah_text||"-"}}</p>
                                                  </div>
                                                </td>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Langit-Langit</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_langit == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_langit == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_langit_text||"-"}}</p>
                                                  </div>
                                                </td>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Leher</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_leher == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_leher == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_leher_text||"-"}}</p>
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Tenggorokan</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_tenggorokan == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_tenggorokan == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_tenggorokan_text||"-"}}</p>
                                                  </div>
                                                </td>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Tonsil</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_tonsil == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_tonsil == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_tonsil_text||"-"}}</p>
                                                  </div>
                                                </td>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Dada</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_dada == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_dada == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_dada_text||"-"}}</p>
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Payudara</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_payudara == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_payudara == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_payudara_text||"-"}}</p>
                                                  </div>
                                                </td>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Punggung</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_punggung == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_punggung == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_punggung_text||"-"}}</p>
                                                  </div>
                                                </td>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Perut</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_perut == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_perut == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_perut_text||"-"}}</p>
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Genital</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_genital == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_genital == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_genital_text||"-"}}</p>
                                                  </div>
                                                </td>
                                                
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Anus</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_anus == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_anus == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_anus_text||"-"}}</p>
                                                  </div>
                                                </td>
                                                
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Lengan Atas</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_lengan_atas == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_lengan_atas == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_lengan_atas_text||"-"}}</p>
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Lengan Bawah</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_lengan_bawah == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_lengan_bawah == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_lengan_bawah_text||"-"}}</p>
                                                  </div>
                                                </td>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Jari Tangan</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_jari_tangan == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_jari_tangan == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_jari_tangan_text||"-"}}</p>
                                                  </div>
                                                </td>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Kuku Tangan</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_kuku == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_kuku == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_kuku_text||"-"}}</p>
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Sendi Tangan</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_sendi_tangan == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_sendi_tangan == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_sendi_tangan_text||"-"}}</p>
                                                  </div>
                                                </td>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Tungkai Atas</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_tungkai_atas == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_tungkai_atas == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_tungkai_atas_text||"-"}}</p>
                                                  </div>
                                                </td>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Tungkai Bawah</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_tungkai_bawah == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_tungkai_bawah == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_tungkai_bawah_text||"-"}}</p>
                                                  </div>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Jari Kaki</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_jari_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_jari_kaki == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_jari_kaki_text||"-"}}</p>
                                                  </div>
                                                </td>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Kuku Kaki</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_kuku_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_kuku_kaki == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_kuku_kaki_text||"-"}}</p>
                                                  </div>
                                                </td>
                                                <td width="33%">
                                                  <div class="result_tab">
                                                    <h4>Sendi Kaki</h4>
                                                    <p v-if="dataRiwayatRMDokter.row.apod_vs_persendian_kaki == 'N'">TAK (Tidak Ada Kelainan)</p>
                                                    <p v-else-if="dataRiwayatRMDokter.row.apod_vs_persendian_kaki == 'TP'">Tidak Diperiksa</p>
                                                    <p v-else>{{dataRiwayatRMDokter.row.apod_vs_persendian_kaki_text||"-"}}</p>
                                                  </div>
                                                </td>
                                              </tr>
                                            </table>
                                          </div>
                                        </div>

                                        <div class="card mb-0">
                                          <div class="card-header bg_head_panel">
                                            <h6 class="card-title font-weight-semibold">Status Lokalis</h6>
                                          </div>
                                          <div class="card-body p-3">
                                            <div class="row gx-2">
                                              <div class="col-lg-4">
                                               <ul class="nav nav-pills nav-pills-bordered nav-pills-toolbar flex-column">
                                                <li class="nav-item" @click="changeTabAnat(k)" v-for="(v,k) in dataRiwayatRMDokter.row.apod_lokasi_nyeri||[]" :key="k+'nav'">
                                                  <a href="javascript:;" data-toggle="tab" 
                                                  :class="activeAnat == k ? 'nav-link active':'nav-link'">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                      <span>{{v.name||"(Anatomi Baru)"}}</span>
                                                      <div>
                                                        <i class="icon-chevron-right"></i>
                                                      </div>
                                                    </div>
                                                  </a>
                                                </li>
                                              </ul>
                                              </div>
                                              <div class="col-lg-8">
                                                <div class="tab-content">
                                                  <div v-for="(v,k) in dataRiwayatRMDokter.row.apod_lokasi_nyeri||[]" :key="k+'navAnat'" :class="activeAnat == k ? 'tab-pane fade-show active' : 'tab-pane fade'">
                                                    <div class="wrap_anatomy_info p-0 border-0" v-if="v.type">
                                                      <div class="position-relative" :id="`anatomiNyeri${k}`" @click.stop.prevent>
                                                        <div class="body_wrapper">
                                                          <img :src="$parent.$parent.$parent.assetLocal(v.image)" class="img-fluid w-100" alt="">
                                                        </div>
                                                        <div class="anatomy-info" v-for="(v1,k1) in v.data||[]" :key="k1+'body'"
                                                          :style="`top:${v1.y}%;left:${v1.x}%`">
                                                          <a href="javascript:;" class="ai-point" v-b-tooltip.hover :html="true"
                                                            :title="v1.value"></a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                    
                                    </div>

                                </div>
                                </div>
                                
                            </div>
                            </div>
                        </div>
                        <div class="card">
                          <div class="card-header bg-info">
                              <h5 class="card-title font-weight-semibold">Assessment</h5>
                          </div>
                          <div class="card-body">
                              <table class="table table-bordered">
                              <tbody>
                                  <tr v-for="(v,k) in dataRiwayatRMDokter.dataICD10" :key="k+'diagnosaDok2'">
                                  <td>
                                      <div class="result_tab" v-if="!v.mi10_code">
                                      <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                                      <p>{{v.api10_diagnosis||"-"}}</p>
                                      </div>
                                      <div class="result_tab" v-else>
                                      <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                                      <p>{{v.text||"-"}}</p>
                                      </div>
                                  </td>
                                  </tr>
                                  <tr>
                                      <td>
                                        <div class="result_tab">
                                        <h4>Diagnosa Lainnya</h4>
                                        <p>{{dataRiwayatRMDokter.row.apod_diagnosa||"-"}}</p>
                                        </div>
                                      </td>
                                  </tr>
                              </tbody>
                              </table>
                          </div>

                          <div class="card-body">
                              <table class="table table-bordered">
                              <tbody>
                                  <tr v-for="(v,k) in dataRiwayatRMDokter.dataICD9" :key="k+'diagnosaDok'">
                                  <td>
                                      <div class="result_tab" v-if="!v.mi9_code">
                                      <h4>Tindakan #{{k+1}} (ICD9)</h4>
                                      <p>{{v.api9_tindakan||"-"}}</p>
                                      </div>
                                      <div class="result_tab" v-else>
                                      <h4>Tindakan #{{k+1}} (ICD9)</h4>
                                      <p>{{v.text||"-"}}</p>
                                      </div>
                                  </td>
                                  </tr>
                                  <tr>
                                      <td>
                                        <div class="result_tab">
                                        <h4>Tindakan Lainnya</h4>
                                        <p>{{dataRiwayatRMDokter.row.apod_tindakan||"-"}}</p>
                                        </div>
                                      </td>
                                  </tr>
                              </tbody>
                              </table>
                          </div>
                        </div>

                        <div class="card border mb-3">
                          <div @click="showICDRM = !showICDRM" class="card-header bg-info" style="cursor:pointer;" data-toggle="collapse" data-target="#infoPatient">
                            <div class="d-flex align-items-center justify-content-between">
                              <h6 class="card-title font-weight-semibold">Lihat Hasil Diagnosa & Tindakan Oleh Rekam Medis</h6>
                              <i class="icon-chevron-down"></i>
                            </div>
                          </div>
                          <div v-if="showICDRM" id="infoPatient">
                            <div class="card-body">
                                <table class="table table-bordered">
                                <tbody>
                                    <tr v-for="(v,k) in dataRiwayatRMDokter.dataICD10RM" :key="k+'diagnosaDok2'">
                                    <td>
                                        <div class="result_tab" v-if="!v.mi10_code">
                                        <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                                        <p>{{v.api10r_diagnosis||"-"}}</p>
                                        </div>
                                        <div class="result_tab" v-else>
                                        <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                                        <p>{{v.mi10_code||"-"}} - {{v.mi10_name||"-"}}</p>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                                <span v-if="!(dataRiwayatRMDokter.dataICD10RM||[]).length">Tidak Ada Data</span>
                            </div>
                            <div class="card-body">
                                <table class="table table-bordered">
                                <tbody>
                                    <tr v-for="(v,k) in dataRiwayatRMDokter.dataICD9RM" :key="k+'diagnosaDok'">
                                    <td>
                                        <div class="result_tab" v-if="!v.mi9_code">
                                        <h4>Tindakan #{{k+1}} (ICD9)</h4>
                                        <p>{{v.api9r_tindakan||"-"}}</p>
                                        </div>
                                        <div class="result_tab" v-else>
                                        <h4>Tindakan #{{k+1}} (ICD9)</h4>
                                        <p>{{v.mi9_code||"-"}} - {{v.mi9_name||"-"}}</p>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                                <span v-if="!(dataRiwayatRMDokter.dataICD9RM||[]).length">Tidak Ada Data</span>
                            </div>
                          </div>
                        </div>

                        <div class="card border mb-3">
                          <div @click="showICDUPL = !showICDUPL" class="card-header bg-info" style="cursor:pointer;" data-toggle="collapse" data-target="#infoPatient">
                            <div class="d-flex align-items-center justify-content-between">
                              <h6 class="card-title font-weight-semibold">Lihat Hasil Diagnosa & Tindakan Oleh UPLA</h6>
                              <i class="icon-chevron-down"></i>
                            </div>
                          </div>
                          <div v-if="showICDUPL" id="infoPatient">
                            <div class="card-body">
                                <table class="table table-bordered">
                                <tbody>
                                    <tr v-for="(v,k) in dataRiwayatRMDokter.dataICD10UPL" :key="k+'diagnosaDok2'">
                                    <td>
                                        <div class="result_tab" v-if="!v.mi10_code">
                                        <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                                        <p>{{v.api10u_diagnosis||"-"}}</p>
                                        </div>
                                        <div class="result_tab" v-else>
                                        <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                                        <p>{{v.mi10_code||"-"}} - {{v.mi10_name||"-"}}</p>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                                <span v-if="!(dataRiwayatRMDokter.dataICD10UPL||[]).length">Tidak Ada Data</span>
                            </div>
                            <div class="card-body">
                                <table class="table table-bordered">
                                <tbody>
                                    <tr v-for="(v,k) in dataRiwayatRMDokter.dataICD9UPL" :key="k+'diagnosaDok'">
                                    <td>
                                        <div class="result_tab" v-if="!v.mi9_code">
                                        <h4>Tindakan #{{k+1}} (ICD9)</h4>
                                        <p>{{v.api9u_tindakan||"-"}}</p>
                                        </div>
                                        <div class="result_tab" v-else>
                                        <h4>Tindakan #{{k+1}} (ICD9)</h4>
                                        <p>{{v.mi9_code||"-"}} - {{v.mi9_name||"-"}}</p>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                                <span v-if="!(dataRiwayatRMDokter.dataICD9UPL||[]).length">Tidak Ada Data</span>
                            </div>
                          </div>
                        </div>

                        <div class="card">
                          <div class="card-header bg-info">
                            <h5 class="card-title font-weight-semibold">Planning</h5>
                          </div>

                          <div class="card-body">
                            <div class="card">
                              <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Perencanaan</h6>
                              </div>
                              <div class="card-body p-3">
                                <table class="table table-bordered mb-3">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div class="result_tab">
                                          <p>{{dataRiwayatRMDokter.row.apod_plan||"-"}}</p>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          
                          <div class="card-body" v-if="dataRiwayatRMDokter.row.is_tbc == 'Y' && dataRiwayatRMDokter.row.ap_usia <= 15">
                            <div class="card">
                              <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Kajian TBC</h6>
                              </div>
                              <div class="card-body p-3">
                                <table class="table table-bordered mb-3">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div class="result_tab">
                                          <h4>Hasil Scoring TBC</h4>
                                          <p>{{dataRiwayatRMDokter.row.atld_hasil_scoring_tbc||"-"}}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div class="result_tab">
                                          <h4>Hasil Terapi</h4>
                                          <p>{{dataRiwayatRMDokter.row.atld_tbc_terapi||"-"}}</p>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>


                          <div class="card-body">
                            <div class="card" v-if="dataRiwayatRMDokter.row.appdl_is_lab == 'Y'">
                              <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Laboratorium</h6>
                              </div>
                              <div class="card-body p-3">
                                <table class="table table-bordered mb-3">
                                  <thead>
                                    <tr class="table-light">
                                      <th colspan="4" class="text-uppercase">Informasi Pemeriksaan</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div class="result_tab">
                                          <h4>Prioritas Pemeriksaan</h4>
                                          <p v-if="dataRiwayatRMDokter.row.appdl_prioritas == 1">CITO<strong class="text-danger"></strong></p>
                                          <p v-else>Non CITO</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div class="result_tab">
                                          <h4>Pasien Sedang Berpuasa?</h4>
                                          <p>{{dataRiwayatRMDokter.row.appdl_fasting == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="result_tab">
                                          <h4>Catatan Permintaan</h4>
                                          <p>{{dataRiwayatRMDokter.row.appdl_catatan||"-"}}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div class="result_tab">
                                          <h4>Catatan Pemeriksaan Parsial</h4>
                                          <p>{{dataRiwayatRMDokter.row.appdl_pemeriksaan_parsial||"-"}}</p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="result_tab">
                                          <h4>Tanggal Pemeriksaan</h4>
                                          <p v-if="dataRiwayatRMDokter.row.appdl_penunjang_date">{{dataRiwayatRMDokter.row.appdl_penunjang_date|moment("DD MMM YYYY")}}</p>
                                          <p v-else> - </p>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div class="req-list-resume">
                                  <div class="row g-3">
                                    <template v-for="(v,k) in dataRiwayatRMDokter.row.appdl_data">
                                      <div class="col-md-4 col-lg-3" :key="k+'labData'" v-if="isShowHeadLab(k)">
                                        <div class="card shadow-0 border">
                                          <div class="card-header py-2 bg-light">
                                            <h6 class="card-title text-uppercase font-weight-semibold">{{v.head||"-"}}</h6>
                                          </div>
                                          <div class="card-body py-2">
                                            <template v-for="v1,k1 in (v.dubData||[])" >
                                              <div class="req-list" v-if="isShowHeadSubHeadLab(k,k1)" :key="k1+'labdatas'">
                                                <h6 class="text-uppercase" v-if="v1.sub">{{v1.sub||"-"}}</h6>
                                                <template v-for="v2,k2 in (v1.data||[])">
                                                  <div v-if="isShowLab(k,k1,k2)" :key="k2+'labsubdatas'">
                                                    <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                                    <span class="align-middle font-weight-semibold">{{v2.text||"-"}}</span>
                                                    <span v-if="v2.notes">, {{v2.notes}}</span>
                                                  </div>
                                                </template>
                                              </div>
                                            </template>
                                          </div>
                                        </div>
                                      </div>
                                    </template>
                                    <div class="col-md-4 col-lg-3" v-if="dataRiwayatRMDokter.row.appdl_hasil_lainnya">
                                      <div class="card shadow-0 border">
                                        <div class="card-header py-2 bg-light">
                                          <h6 class="card-title text-uppercase font-weight-semibold">Item Pemeriksaan Lainnya</h6>
                                        </div>
                                        <div class="card-body py-2">
                                            <div class="req-list">
                                                <div>
                                                  <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                                  <span class="align-middle font-weight-semibold">{{dataRiwayatRMDokter.row.appdl_hasil_lainnya||"-"}}</span>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                    <span v-if="!selectedLabInput()" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                                  </div>
                                </div>
                              </div>
                              <div class="card-body p-3">
                                <div class="row">
                                  <div class="col-md-12">
                                    <table class="table table-bordered table-striped table-sm patient-table">
                                      <thead>
                                        <tr>
                                          <th>Hasil Pemeriksaan </th>
                                          <th>Hasil</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="(v,k) in (dataRiwayatRMDokter.row.appdl_hasil||[])" :key="k+'hasil'">
                                          <td>{{v.value}}</td>
                                          <td>
                                              <div class="result_tab form-group mb-0">
                                              <a class="btn_view" :href="$parent.$parent.$parent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                              </div>
                                          </td>
                                        </tr>
                                        <tr v-if="!(dataRiwayatRMDokter.row.appdl_hasil||[]).length">
                                          <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="card" v-if="dataRiwayatRMDokter.row.appdr_is_radio == 'Y'">
                              <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Radiologi</h6>
                              </div>
                              <div class="card-body p-3">
                                <table class="table table-bordered mb-3">
                                  <thead>
                                    <tr class="table-light">
                                      <th colspan="4" class="text-uppercase">Informasi Pemeriksaan</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div class="result_tab">
                                          <h4>Prioritas Pemeriksaan</h4>
                                          <p v-if="dataRiwayatRMDokter.row.appdr_prioritas == 1">CITO<strong class="text-danger"></strong></p>
                                          <p v-else>Non CITO</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div class="result_tab">
                                          <h4>Pasien Memiliki Alergi Bahan Kontras/Zat?</h4>
                                          <p>{{dataRiwayatRMDokter.row.appdr_alergi == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="result_tab">
                                          <h4>Catatan Permintaan</h4>
                                          <p>{{dataRiwayatRMDokter.row.appdr_catatan||"-"}}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div class="result_tab">
                                          <h4>Catatan Pemeriksaan Parsial</h4>
                                          <p>{{dataRiwayatRMDokter.row.appdr_pemeriksaan_parsial||"-"}}</p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div class="result_tab">
                                          <h4>Tanggal Pemeriksaan</h4>
                                          <p v-if="dataRiwayatRMDokter.row.appdr_penunjang_date">{{dataRiwayatRMDokter.row.appdr_penunjang_date|moment("DD MMM YYYY")}}</p>
                                          <p v-else> - </p>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div class="row g-3">
                                  <template v-for="(v,k) in dataRiwayatRMDokter.row.appdr_data">
                                    <div class="col-md-4 col-lg-3" :key="k+'radioData'" v-if="isShowHeadRadiologi(k)">
                                      <div class="card shadow-0 border">
                                        <div class="card-header py-2 bg-light">
                                          <h6 class="card-title text-uppercase font-weight-semibold">{{
                                            getConfigDynamic(dataRiwayatRMDokter.Config.mr.KategoriRadiologi,v.head)||"-"}}</h6>
                                        </div>
                                        <div class="card-body py-2">
                                          <template v-for="v1,k1 in (v.data||[])">
                                            <div v-if="isShowRadiologi(k,k1)" :key="k1+'radiosubdatas'">
                                              <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                              <span class="align-middle font-weight-semibold">{{v1.text||"-"}}</span>
                                              <span v-if="v1.notes">, {{v1.notes}}</span>
                                            </div>
                                          </template>
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                  <div class="col-md-4 col-lg-3" v-if="dataRiwayatRMDokter.row.appdr_hasil_lainnya">
                                    <div class="card shadow-0 border">
                                      <div class="card-header py-2 bg-light">
                                        <h6 class="card-title text-uppercase font-weight-semibold">Item Pemeriksaan Lainnya</h6>
                                      </div>
                                      <div class="card-body py-2">
                                          <div class="req-list">
                                              <div>
                                                <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                                <span class="align-middle font-weight-semibold">{{dataRiwayatRMDokter.row.appdr_hasil_lainnya||"-"}}</span>
                                              </div>
                                          </div>
                                      </div>
                                    </div>
                                  </div>
                                  <span v-if="!selectedRadioInput()" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                                </div>
                              </div>
                              <div class="card-body p-3">
                                <div class="row">
                                  <div class="col-md-12">
                                    <table class="table table-bordered table-striped table-sm patient-table">
                                      <thead>
                                        <tr>
                                          <th>Hasil Pemeriksaan </th>
                                          <th>Hasil</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="(v,k) in (dataRiwayatRMDokter.row.appdr_hasil||[])" :key="k+'hasil'">
                                          <td>{{v.value}}</td>
                                          <td>
                                              <div class="result_tab form-group mb-0">
                                              <a class="btn_view" :href="$parent.$parent.$parent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                              <a class="btn_view ml-1" v-b-tooltip.hover.right title="Hasil Bacaan Radiologi" href="javascript:;" @click="downloadReportBacaanDokter(dataRiwayatRMDokter.row.appdr_arm_id,v)" ><i class="icon-file-download"></i></a>
                                              </div>
                                              
                                          </td>
                                        </tr>
                                        <tr v-if="!(dataRiwayatRMDokter.row.appdr_hasil||[]).length">
                                          <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="card">
                              <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Resep</h6>
                              </div>
                              <div class="card-body p-3">
                                <table class="table table-bordered table-sm">
                                  <thead>
                                    <tr>
                                      <th>Jenis</th>
                                      <th>Nama</th>
                                      <th>Jumlah</th>
                                      <th>Frekuensi</th>
                                      <th>Keterangan</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(v,k) in (dataRiwayatRMDokter.resepDokter||[])" :key="'resepDokter'+k">
                                      <td>
                                        <div class="result_tab">
                                          <p>{{v.ard_jenis == 1 ? 'Obat' : v.ard_jenis == 2 ? 'Alat Kesehatan' : 'Racikan'}}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div class="result_tab">
                                          <p v-if="v.ard_item_id == 99999">
                                          {{v.ard_item_lainnya||"-"}}
                                          </p>
                                          <p v-else>
                                          {{v.ard_nama||"-"}}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div class="result_tab">
                                          <p>{{v.ard_jumlah||"-"}} {{v.ard_satuan||"-"}}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div class="result_tab">
                                          <p>
                                            <span v-if="v.ard_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
                                            <span v-else>{{v.ard_frekuensi_lainnya || "-"}}</span>
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div class="result_tab">
                                          <p>{{v.ard_keterangan || "-"}}</p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr v-if="!(dataRiwayatRMDokter.resepDokter||[]).length" class="table-info">
                                      <td class="text-center" colspan="99">Tidak Ada Data</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="card">
                              <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Tindak Lanjut</h6>
                              </div>
                              <div class="card-body p-3">
                                <table class="table table-bordered">
                                  <thead>
                                    <tr class="table-secondary">
                                      <th colspan="2">Tindak Lanjut</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                     <tr>
                                      <td>
                                        <div class="result_tab">
                                          <h4>Tindak Lanjut</h4>
                                          <p>{{dataRiwayatRMDokter.row.atld_is_edukasi == "Y"?'Edukasi':''}}</p>
                                          <p>{{dataRiwayatRMDokter.row.atld_is_kontrol == "Y"?'Kontrol':''}}</p>
                                          <p>{{dataRiwayatRMDokter.row.atld_is_rawat_inap == "Y"?'Rawat Inap':''}}</p>
                                          <p>{{dataRiwayatRMDokter.row.atld_is_done == "Y"?'Selesai':''}}</p>
                                        </div>
                                        
                                        <div class="result_tab">
                                          <h4>Cara Pulang Pasien</h4>
                                          <p>{{getConfigDynamic(dataRiwayatRMDokter.Config.mr.mCaraPulang,dataRiwayatRMDokter.row.atld_cara_pulang)||"-"}}</p>
                                        </div>
                                      </td>
                                      <td>
                                        <div class="result_tab">
                                          <template v-if="dataRiwayatRMDokter.row.atld_is_edukasi == 'Y'">
                                          <h4>Catatan Edukasi</h4>
                                          <p>{{dataRiwayatRMDokter.row.atld_notes_edukasi||"-"}}</p>
                                          </template>

                                          <template v-if="dataRiwayatRMDokter.row.atld_is_kontrol == 'Y'">
                                          <h4>Tanggal Pemeriksaan</h4>
                                          <p v-if="dataRiwayatRMDokter.row.atld_notes_kontrol">{{dataRiwayatRMDokter.row.atld_notes_kontrol|moment("DD MMM YYYY")}}</p>
                                          <span v-else> - </span>
                                          
                                          <h4 class="mt-2">Catatan Kontrol</h4>
                                          <p>{{dataRiwayatRMDokter.row.atld_catatan_kontrol||"-"}}</p>
                                          </template>
                                          
                                          <template v-if="dataRiwayatRMDokter.row.atld_is_rawat_inap == 'Y'">
                                          <h4>Catatan Rawat Inap</h4>
                                          <p>{{dataRiwayatRMDokter.row.atld_notes_rawat_inap||"-"}}</p>
                                          <h4>Ruangan</h4>
                                          <p>{{dataRiwayatRMDokter.row.mruan_name||"-"}}</p>
                                          <h4>Dokter</h4>
                                          <p>{{dataRiwayatRMDokter.row.mruan_dokter_name||"-"}}</p>
                                          </template>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <!--
                                <div class="mt-3">
                                  <table class="table table-sm table-bordered table-striped">
                                    <thead>
                                      <tr>
                                        <th>Penunjang</th>
                                        <th>Tanggal</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-if="dataRiwayatRMDokter.row.atld_lab == 'Y'">
                                        <td>Laboratorium</td>
                                        <td>{{dataRiwayatRMDokter.row.atld_lab_date|moment("DD MMM YYYY")}}</td>
                                      </tr>
                                      <tr v-if="dataRiwayatRMDokter.row.atld_radiologi == 'Y'">
                                        <td>Radiologi</td>
                                        <td>{{dataRiwayatRMDokter.row.atld_radiologi_date|moment("DD MMM YYYY")}}</td>
                                      </tr>
                                      <tr v-if="dataRiwayatRMDokter.row.atld_lab !== 'Y' && dataRiwayatRMDokter.row.atld_radiologi !== 'Y'">
                                        <td colspan="99" class="text-center">Tidak ada Penunjang</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                -->
                                <div class="mt-3">
                                  <table class="table table-bordered">
                                    <thead>
                                      <tr class="table-secondary">
                                        <th colspan="4">Tindakan Tambahan</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div class="result_tab">
                                            <h4> Apakah pasien mengkonsultasikan ke dietisien</h4>
                                            <p>{{dataRiwayatRMDokter.row.atld_dietisien == 'Y'?'Ya':'Tidak'}}</p>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Apakah pasien dirujuk ke dokter lain?</h4>
                                            <p>{{dataRiwayatRMDokter.rowReg.ar_is_rujukan == 'Y'?'Ya':'Tidak'}}</p>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table class="table table-bordered"  v-if="dataRiwayatRMDokter.rowReg.ar_is_rujukan == 'Y'">
                                    <template>
                                      <thead>
                                        <tr class="table-secondary">
                                          <th colspan="4">
                                          {{getConfigDynamic(dataRiwayatRMDokter.Config.mr.typeRujukan,dataRiwayatRMDokter.row.artlr_type)}}</th>
                                        </tr>
                                      </thead>
                                      <tbody v-if="dataRiwayatRMDokter.row.artlr_type == 'RI'">
                                        <tr>
                                          <td>
                                            <div class="result_tab">
                                              <h4>Dirujuk Ke</h4>
                                              <p>{{getConfigDynamic(dataRiwayatRMDokter.mPoli,dataRiwayatRMDokter.row.artlr_poli)||"-"}}</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="result_tab">
                                              <h4>Dokter Rujukan</h4>
                                              <p>{{getConfigDynamic(dataRiwayatRMDokter.mDokter,dataRiwayatRMDokter.row.artlr_dokter)||"-"}}</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="result_tab">
                                              <h4>Catatan</h4>
                                              <p>{{dataRiwayatRMDokter.row.artlr_notes||"-"}}</p>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                      <tbody v-if="dataRiwayatRMDokter.row.artlr_type == 'RK' || dataRiwayatRMDokter.row.artlr_type == 'RP'">
                                        <tr>
                                          <td>
                                            <div class="result_tab">
                                              <h4>Alasan Merujuk</h4>
                                              <p>{{dataRiwayatRMDokter.row.artlr_alasan_rujuk||"-"}}</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="result_tab">
                                              <h4>{{dataRiwayatRMDokter.row.artlr_alasan_rujuk||"-"}}</h4>
                                              <p>{{dataRiwayatRMDokter.row.artlr_alasan_rujuk_text||"-"}}</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="result_tab">
                                              <h4>Faskes</h4>
                                              <p>{{dataRiwayatRMDokter.row.mrfj_name||"-"}}</p>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div class="result_tab">
                                              <h4>Dokter</h4>
                                              <p>{{dataRiwayatRMDokter.row.artlr_nama_dokter_rujuk||"-"}}</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="result_tab">
                                              <h4>Peralatan Media yang Terpasang</h4>
                                              <p v-if="dataRiwayatRMDokter.row.artlr_is_media == 'Y'">
                                              {{dataRiwayatRMDokter.row.artlr_media||"-"}}</p>
                                              <p v-else> - </p>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="result_tab">
                                              <h4>Perawatan Pasien yang dibutuhkan</h4>
                                              <p>{{dataRiwayatRMDokter.row.artlr_perawatan_pasien||"-"}}</p>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div class="result_tab">
                                              <h4>Persetujuan Biaya RS Rujukan</h4>
                                              <p>{{dataRiwayatRMDokter.row.artlr_is_biaya == "Y"?"Ya":"Tidak"}}</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="result_tab">
                                              <h4>Persetujuan Risiko Perjalanan</h4>
                                              <p>{{dataRiwayatRMDokter.row.artlr_is_risiko == "Y"?"Ya":"Tidak"}}</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="result_tab">
                                              <h4>Kejadian klinis selama dilakukan transfer</h4>
                                              <p v-if="dataRiwayatRMDokter.row.artlr_is_kejadian_klinis == 'Y'">{{dataRiwayatRMDokter.row.artlr_kejadian_klinis||"-"}}</p>
                                              <p v-else> - </p>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                      <tbody v-if="dataRiwayatRMDokter.row.artlr_type == 'RB'">
                                        <tr>
                                          <td>
                                            <div class="result_tab">
                                              <h4>No Rujukan</h4>
                                              <p> - </p>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="result_tab">
                                              <h4>Catatan</h4>
                                              <p>{{dataRiwayatRMDokter.row.artlr_notes||"-"}}</p>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </template>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </template>
                    <span v-else>Tidak Ada Kajian Dokter</span>
                  </div>
                  <div :class="activeTabDokter == 2 ? 'tab-pane fade show active' : 'tab-pane'" id="rrm-dr-fisio">
                    <template v-if="Object.keys(dataRiwayatRMDokterTerapi||{}).length && dataRiwayatRMDokterTerapi.isFound">
                    <div class="row" v-if="dataRiwayatRMDokterTerapi.row.arm_notes">
                      <div class="col-md-12">
                        <b-alert show fade>{{dataRiwayatRMDokterTerapi.row.arm_notes||"-"}}</b-alert>
                      </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-2">
                        <label for="">Data Kajian</label>
                        <v-select @input="changeKajianDokterTerapi($event)" placeholder="Pilih Data Kajian" v-model="dataRiwayatRMDokterTerapi.row.arm_id" :options="historyKajianDokterTerapi" label="text" :reduce="v=>v.value"></v-select>
                        </div>
                    </div>
                    <div class="row" v-if="Object.keys(dataRiwayatRMDokterTerapi||{}).length && dataRiwayatRMDokterTerapi.isFound">
                        <div class="col-md-6">
                          <b-alert show fade>Nama Inputer : {{dataRiwayatRMDokterTerapi.row.bu_full_name||"-"}}</b-alert>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header bg_head_panel">
                        <h5 class="card-title font-weight-semibold">Informasi Pasien</h5>
                        </div>
                        <div class="card-body p-3">
                        <table class="table table-bordered">
                            <tbody>
                            <tr>
                                <td>
                                <div class="result_tab">
                                    <h4>No. Rekam Medis</h4>
                                    <p>{{dataRiwayatRMDokterTerapi.row.ap_code||"-"}}</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Nama Pasien</h4>
                                    <p>{{dataRiwayatRMDokterTerapi.row.ap_fullname||"-"}}</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Tanggal Lahir</h4>
                                    <p>{{dataRiwayatRMDokterTerapi.row.ap_dob | moment("DD MMM YYYY")}}</p>
                                </div>
                                </td>
                                <td>
                                <div class="result_tab">
                                    <h4>Jenis Kelamin</h4>
                                    <p>{{dataRiwayatRMDokterTerapi.row.cg_label||"-"}}</p>
                                </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="card mb-0">
                          <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Anamnesa</h6>
                          </div>
                          <div class="card-body p-3">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="table-responsive">
                                  <table class="table table-bordered">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Anamnesa</h4>
                                            <p v-if="dataRiwayatRMDokterTerapi.row.apdf_anamnesa !== 2">
                                            Pasien Sendiri
                                            </p>
                                            <p v-else>
                                                {{dataRiwayatRMDokterTerapi.row.apdf_anamnesa_lainnya||"-"}}
                                            </p>
                                            
                                          </div>
                                        </td>

                                      </tr>

                                      <tr>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Riwayat Penyakit Sekarang</h4>
                                            <p>{{dataRiwayatRMDokterTerapi.row.apdf_riwayat_penyakit_sekarang||"-"}}
                                            </p>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Diagnosis Klinis</h4>
                                            <p>{{dataRiwayatRMDokterTerapi.row.apdf_riwayat_alergi||"-"}}</p>
                                          </div>
                                        </td>

                                      </tr>

                                      <tr>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Riwayat Penyakit Dahulu</h4>
                                            <p>{{dataRiwayatRMDokterTerapi.row.apdf_riwayat_penyakit_dahulu||"-"}}
                                            </p>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Riwayat Pengobatan</h4>
                                            <p>{{dataRiwayatRMDokterTerapi.row.apdf_riwayat_pengobatan||"-"}}
                                            </p>
                                          </div>
                                        </td>

                                      </tr>

                                      <tr>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Riwayat Penyakit Keluarga</h4>
                                            <p>{{dataRiwayatRMDokterTerapi.row.apdf_riwayat_penyakit_keluarga||"-"}}
                                            </p>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Riwayat Pekerjaan, Sosial Ekonomi, Kejiawaan dan Kebiasaan</h4>
                                            <p>{{dataRiwayatRMDokterTerapi.row.apdf_riwayat_pekerjaan||"-"}}
                                            </p>
                                          </div>
                                        </td>

                                      </tr>

                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="card mb-0">
                          <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Status Gizi</h6>
                          </div>
                          <div class="card-body p-3">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="table-responsive">
                                  <table class="table table-bordered">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Status Gizi</h4>
                                            <p v-if="dataRiwayatRMDokterTerapi.row.apdf_status_gizi !== 'L'">
                                                {{dataRiwayatRMDokterTerapi.row.apdf_status_gizi||"-"}}
                                            </p>
                                            <p v-else>
                                                {{dataRiwayatRMDokterTerapi.row.apdf_status_gizi_lainnya||"-"}}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="card mb-0">
                          <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Pengkajian Nyeri
                            </h6>
                          </div>
                          <div class="card-body p-3">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="wrap_anatomy_info">
                                  <label for="perKeluhan">Lokasi Nyeri<strong class="text-danger">*</strong></label>
                                  <div class="position-relative" id="anatomiNyeri" @click.stop.prevent>
                                    <div class="row">
                                      <div class="col-md-6">
                                        <div class="body_wrapper">
                                          <img :src="$parent.$parent.$parent.assetLocal('img/front-body.png')" class="img-fluid" alt="">
                                        </div>
                                      </div>
                                      <div class="col-md-6">
                                        <div class="body_wrapper">
                                          <img :src="$parent.$parent.$parent.assetLocal('img/back-body.png')" class="img-fluid" alt="">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="anatomy-info" v-for="(v,k) in dataRiwayatRMDokterTerapi.row.apdf_lokasi_nyeri||[]" :key="k"
                                      :style="`top:${v.y}%;left:${v.x}%`">
                                      <a href="javascript:;" class="ai-point" v-b-tooltip.hover :html="true"
                                        :title="v.value"></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="card mb-0">
                          <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Diagnosis</h6>
                          </div>
                          <div class="card-body p-3">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="table-responsive">
                                  <table class="table table-bordered">
                                    <tbody>
                                      <tr>
                                        <td colspan="2">
                                          <div class="result_tab">
                                            <h4>Keluhan</h4>
                                            <p>{{dataRiwayatRMDokterTerapi.row.apdf_keluhan||"-"}}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>

                                      
                                      <tr>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Rasa Nyeri</h4>
                                            <p>{{
                                              getConfigDynamic(dataRiwayatRMDokterTerapi.Config.mr.statusNyeri,dataRiwayatRMDokterTerapi.row.apdf_rasa_nyeri)||"-"}}</p>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Kompres</h4>
                                            <p>{{dataRiwayatRMDokterTerapi.row.apdf_kompres||"-"}}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Aktifitas</h4>
                                            <p>{{dataRiwayatRMDokterTerapi.row.apdf_aktifitas||"-"}}
                                            </p>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Lama Nyeri yang di rasakan</h4>
                                            <p>
                                              {{getConfigDynamic(dataRiwayatRMDokterTerapi.Config.mr.configLamaNyeri,dataRiwayatRMDokterTerapi.row.apdf_lama_nyeri)||"-"}}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Apakah nyerinya berpindah dari satu ke tempat lain</h4>
                                            <p>
                                              {{getConfigDynamic(dataRiwayatRMDokterTerapi.Config.mr.configNilaiNyeri,dataRiwayatRMDokterTerapi.row.apdf_nilai_nyeri)||"-"}}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="card">
                          <div class="card-header bg_head_panel mb-3">
                            <h6 class="card-title font-weight-semibold">Diagnosa (ICD 10)
                            </h6>
                          </div>
                          <div class="card-body">
                              <table class="table table-bordered">
                              <tbody>
                                  <tr v-for="(v,k) in dataRiwayatRMDokterTerapi.dataICD10" :key="k+'diagnosaDok2'">
                                  <td>
                                      <div class="result_tab" v-if="!v.mi10_code">
                                      <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                                      <p>{{v.api10_diagnosis||"-"}}</p>
                                      </div>
                                      <div class="result_tab" v-else>
                                      <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                                      <p>{{v.text||"-"}}</p>
                                      </div>
                                  </td>
                                  </tr>
                                  <tr>
                                      <td>
                                        <div class="result_tab">
                                        <h4>Diagnosa Lainnya</h4>
                                        <p>{{dataRiwayatRMDokterTerapi.row.apdf_diagnosa||"-"}}</p>
                                        </div>
                                      </td>
                                  </tr>
                              </tbody>
                              </table>
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-md-12">
                        <div class="card">
                          <div @click="showICDRMDiagnosa = !showICDRMDiagnosa" class="card-header bg-info" style="cursor:pointer;" data-toggle="collapse" data-target="#infoPatient">
                            <div class="d-flex align-items-center justify-content-between">
                              <h6 class="card-title font-weight-semibold">Diagnosa (ICD 10) (Rekam Medis)</h6>
                              <i class="icon-chevron-down"></i>
                            </div>
                          </div>
                          <div class="card-body" v-if="showICDRMDiagnosa">
                              <table class="table table-bordered">
                              <tbody>
                                  <tr v-for="(v,k) in dataRiwayatRMDokterTerapi.dataICD10RM" :key="k+'diagnosaDok2'">
                                  <td>
                                      <div class="result_tab" v-if="!v.mi10_code">
                                      <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                                      <p>{{v.api10r_diagnosis||"-"}}</p>
                                      </div>
                                      <div class="result_tab" v-else>
                                      <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                                      <p>{{v.mi10_code||"-"}} - {{v.mi10_name||"-"}}</p>
                                      </div>
                                  </td>
                                  </tr>
                              </tbody>
                              </table>
                              <span v-if="!(dataRiwayatRMDokterTerapi.dataICD10RM||[]).length">Tidak Ada Data</span>
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-md-12">
                        <div class="card">
                          <div @click="showICDUPLDiagnosa = !showICDUPLDiagnosa" class="card-header bg-info" style="cursor:pointer;" data-toggle="collapse" data-target="#infoPatient">
                            <div class="d-flex align-items-center justify-content-between">
                              <h6 class="card-title font-weight-semibold">Diagnosa (ICD 10) (UPLA)</h6>
                              <i class="icon-chevron-down"></i>
                            </div>
                          </div>
                          <div class="card-body" v-if="showICDUPLDiagnosa">
                              <table class="table table-bordered">
                              <tbody>
                                  <tr v-for="(v,k) in dataRiwayatRMDokterTerapi.dataICD10UPL" :key="k+'diagnosaDok2'">
                                  <td>
                                      <div class="result_tab" v-if="!v.mi10_code">
                                      <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                                      <p>{{v.api10u_diagnosis||"-"}}</p>
                                      </div>
                                      <div class="result_tab" v-else>
                                      <h4>Diagnosis #{{k+1}} (ICD10)</h4>
                                      <p>{{v.mi10_code||"-"}} - {{v.mi10_name||"-"}}</p>
                                      </div>
                                  </td>
                                  </tr>
                              </tbody>
                              </table>
                              <span v-if="!(dataRiwayatRMDokterTerapi.dataICD10UPL||[]).length">Tidak Ada Data</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="card mb-0">
                          <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Daftar masalah kedokteran fisik dan rehabilitas
                            </h6>
                          </div>
                          <div class="card-body p-3">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="table-responsive">
                                  <table class="table table-bordered">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Mobilisasi</h4>
                                            <p>{{dataRiwayatRMDokterTerapi.row.apdf_mobilisasi||"-"}}
                                            </p>
                                          </div>
                                        </td>

                                        <td>
                                          <div class="result_tab">
                                            <h4>ADL</h4>
                                            <p>{{dataRiwayatRMDokterTerapi.row.apdf_adl||"-"}}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Komunikasi </h4>
                                            <p>{{dataRiwayatRMDokterTerapi.row.apdf_komunikasi||"-"}}
                                            </p>
                                          </div>
                                        </td>

                                        <td>
                                          <div class="result_tab">
                                            <h4>Psikologis</h4>
                                            <p>{{dataRiwayatRMDokterTerapi.row.apdf_psikologi||"-"}}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Sosial Ekonomi </h4>
                                            <p>{{dataRiwayatRMDokterTerapi.row.apdf_sosial_ekonomi||"-"}}
                                            </p>
                                          </div>
                                        </td>

                                        <td>
                                          <div class="result_tab">
                                            <h4>Vokasional</h4>
                                            <p>{{dataRiwayatRMDokterTerapi.row.apdf_vokasional||"-"}}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div class="result_tab">
                                            <h4>Lain-lain </h4>
                                            <p>{{dataRiwayatRMDokterTerapi.row.apdf_lainnya||"-"}}
                                            </p>
                                          </div>
                                        </td>


                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="card mb-0">
                          <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Rencana Kedokteran fisik dan rehabilitasi</h6>
                          </div>
                          <div class="card-body p-3">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="table-responsive">
                                  <table class="table table-bordered">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div class="result_tab">
                                            <p>{{dataRiwayatRMDokterTerapi.row.apdf_rencana_kedokteran||"-"}}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="card mb-0">
                          <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Rencana pemeriksaan penunjang</h6>
                          </div>
                          <div class="card-body p-3">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="table-responsive">
                                  <table class="table table-bordered">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div class="result_tab">
                                            <p>{{dataRiwayatRMDokterTerapi.row.apdf_rencana_pemerikasaan_penunjang||"-"}}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4">
                        <div class="card mb-0">
                          <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Dirujuk</h6>
                          </div>
                          <div class="card-body p-3">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="table-responsive">
                                  <table class="table table-bordered">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div class="result_tab">
                                             
                                            <p v-if="dataRiwayatRMDokterTerapi.row.apdf_dirujuk !== 'L'">
                                                {{dataRiwayatRMDokterTerapi.row.apdf_dirujuk||"-"}}
                                            </p>
                                            <p v-else>
                                                {{dataRiwayatRMDokterTerapi.row.apdf_dirujuk_lainnya||"-"}}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="card mb-0">
                          <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Rencana Tindakan</h6>
                          </div>
                          <div class="card-body p-3">
                            <div class="row">
                              <div class="col-md-4"><div class="wrap_line">
                                  <h3>Elektroterapi</h3>
                                  <div v-for="(v,k) in dataRiwayatRMDokterTerapi.row.apdf_rencana_tindakan_elektroterapi||[]"
                                  :key="k+'elek'" class="form-row">
                                    <div class="col-md-12 mt-2">
                                      <div class="d-flex align-items-center">
                                        <i class="icon-checkmark-circle text-success"></i>
                                        <span class="ml-1">{{v}}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <span v-if="!(dataRiwayatRMDokterTerapi.row.apdf_rencana_tindakan_elektroterapi||[]).length">Tidak Ada Tindakan</span>
                                </div>
                              </div>
                              <div class="col-md-4"><div class="wrap_line">
                                  <h3>Mekanoterapi</h3>
                                  <div v-for="(v,k) in dataRiwayatRMDokterTerapi.row.apdf_rencana_tindakan_mekanoterapi||[]"
                                  :key="k+'meka'" class="form-row">
                                    <div class="col-md-12 mt-2">
                                      <div class="d-flex align-items-center">
                                        <i class="icon-checkmark-circle text-success"></i>
                                        <span class="ml-1">{{v}}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <span v-if="!(dataRiwayatRMDokterTerapi.row.apdf_rencana_tindakan_mekanoterapi||[]).length">Tidak Ada Tindakan</span>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="wrap_line">
                                  <h3>Latihan - latihan</h3>
                                  
                                  <div v-for="(v,k) in dataRiwayatRMDokterTerapi.row.apdf_rencana_tindakan_latihan||[]"
                                  :key="k+'lati'" class="form-row">
                                    <div class="col-md-12 mt-2">
                                      <div class="d-flex align-items-center">
                                        <i class="icon-checkmark-circle text-success"></i>
                                        <span class="ml-1">{{v}}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <span v-if="!(dataRiwayatRMDokterTerapi.row.apdf_rencana_tindakan_latihan||[]).length">Tidak Ada Tindakan</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                
                      <div class="col-md-12">
                        <div class="card">
                          <div class="card-header bg_head_panel">
                            <h6 class="card-title font-weight-semibold">Resep</h6>
                          </div>
                          <div class="card-body p-3">
                            <table class="table table-bordered table-sm">
                              <thead>
                                <tr>
                                  <th>Jenis</th>
                                  <th>Nama</th>
                                  <th>Jumlah</th>
                                  <th>Frekuensi</th>
                                  <th>Keterangan</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(v,k) in (dataRiwayatRMDokterTerapi.resepDokter||[])" :key="'resepDokter'+k">
                                  <td>
                                    <div class="result_tab">
                                      <p>{{v.ard_jenis == 1 ? 'Obat' : v.ard_jenis == 2 ? 'Alat Kesehatan' : 'Racikan'}}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <p v-if="v.ard_item_id == 99999">
                                      {{v.ard_item_lainnya||"-"}}
                                      </p>
                                      <p v-else>
                                      {{v.ard_nama||"-"}}
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <p>{{v.ard_jumlah||"-"}} {{v.ard_satuan||"-"}}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <p>
                                        <span v-if="v.ard_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
                                        <span v-else>{{v.ard_frekuensi_lainnya || "-"}}</span>
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <p>{{v.ard_keterangan || "-"}}</p>
                                    </div>
                                  </td>
                                </tr>
                                <tr v-if="!(dataRiwayatRMDokterTerapi.resepDokter||[]).length" class="table-info">
                                  <td class="text-center" colspan="99">Tidak Ada Data</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="card">
                          <div class="card-header bg_head_panel mb-3">
                            <h6 class="card-title font-weight-semibold">Tindakan (ICD 9)
                            </h6>
                          </div>
                          <div class="card-body">
                              <table class="table table-bordered">
                              <tbody>
                                  <tr v-for="(v,k) in dataRiwayatRMDokterTerapi.dataICD9" :key="k+'diagnosaDok'">
                                  <td>
                                      <div class="result_tab" v-if="!v.mi9_code">
                                      <h4>Tindakan #{{k+1}} (ICD9)</h4>
                                      <p>{{v.api9_tindakan||"-"}}</p>
                                      </div>
                                      <div class="result_tab" v-else>
                                      <h4>Tindakan #{{k+1}} (ICD9)</h4>
                                      <p>{{v.text||"-"}}</p>
                                      </div>
                                  </td>
                                  </tr>
                                  <tr>
                                      <td>
                                        <div class="result_tab">
                                        <h4>Tindakan Lainnya</h4>
                                        <p>{{dataRiwayatRMDokterTerapi.row.apdf_tindakan||"-"}}</p>
                                        </div>
                                      </td>
                                  </tr>
                              </tbody>
                              </table>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="card">
                          <div @click="showICDRMTindakan = !showICDRMTindakan" class="card-header bg-info" style="cursor:pointer;" data-toggle="collapse" data-target="#infoPatient">
                            <div class="d-flex align-items-center justify-content-between">
                              <h6 class="card-title font-weight-semibold">Tindakan (ICD 9) (Rekam Medis)</h6>
                              <i class="icon-chevron-down"></i>
                            </div>
                          </div>
                          <div class="card-body" v-if="showICDRMTindakan">
                              <table class="table table-bordered">
                              <tbody>
                                  <tr v-for="(v,k) in dataRiwayatRMDokterTerapi.dataICD9RM" :key="k+'diagnosaDok'">
                                  <td>
                                      <div class="result_tab" v-if="!v.mi9_code">
                                      <h4>Tindakan #{{k+1}} (ICD9)</h4>
                                      <p>{{v.api9r_tindakan||"-"}}</p>
                                      </div>
                                      <div class="result_tab" v-else>
                                      <h4>Tindakan #{{k+1}} (ICD9)</h4>
                                      <p>{{v.mi9_code||"-"}} - {{v.mi9_name||"-"}}</p>
                                      </div>
                                  </td>
                                  </tr>
                              </tbody>
                              </table>                              
                              <span v-if="!(dataRiwayatRMDokterTerapi.dataICD9RM||[]).length">Tidak Ada Data</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="card">
                          <div @click="showICDUPLTindakan = !showICDUPLTindakan" class="card-header bg-info" style="cursor:pointer;" data-toggle="collapse" data-target="#infoPatient">
                            <div class="d-flex align-items-center justify-content-between">
                              <h6 class="card-title font-weight-semibold">Tindakan (ICD 9) (UPLA)</h6>
                              <i class="icon-chevron-down"></i>
                            </div>
                          </div>
                          <div class="card-body" v-if="showICDUPLTindakan">
                              <table class="table table-bordered">
                              <tbody>
                                  <tr v-for="(v,k) in dataRiwayatRMDokterTerapi.dataICD9UPL" :key="k+'diagnosaDok'">
                                  <td>
                                      <div class="result_tab" v-if="!v.mi9_code">
                                      <h4>Tindakan #{{k+1}} (ICD9)</h4>
                                      <p>{{v.api9u_tindakan||"-"}}</p>
                                      </div>
                                      <div class="result_tab" v-else>
                                      <h4>Tindakan #{{k+1}} (ICD9)</h4>
                                      <p>{{v.mi9_code||"-"}} - {{v.mi9_name||"-"}}</p>
                                      </div>
                                  </td>
                                  </tr>
                              </tbody>
                              </table>                              
                              <span v-if="!(dataRiwayatRMDokterTerapi.dataICD9UPL||[]).length">Tidak Ada Data</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="card">
                          <table class="table table-bordered"  v-if="dataRiwayatRMDokterTerapi.rowReg.ar_is_rujukan == 'Y'">
                            <template>
                              <thead>
                                <tr class="table-secondary">
                                  <th colspan="4">
                                  {{getConfigDynamic(dataRiwayatRMDokterTerapi.Config.mr.typeRujukan,dataRiwayatRMDokterTerapi.row.artlr_type)}}</th>
                                </tr>
                              </thead>
                              <tbody v-if="dataRiwayatRMDokterTerapi.row.artlr_type == 'RI'">
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Dirujuk Ke</h4>
                                      <p>{{getConfigDynamic(dataRiwayatRMDokterTerapi.mPoli,dataRiwayatRMDokterTerapi.row.artlr_poli)||"-"}}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Dokter Rujukan</h4>
                                      <p>{{getConfigDynamic(dataRiwayatRMDokterTerapi.mDokter,dataRiwayatRMDokterTerapi.row.artlr_dokter)||"-"}}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Catatan</h4>
                                      <p>{{dataRiwayatRMDokterTerapi.row.artlr_notes||"-"}}</p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-if="dataRiwayatRMDokterTerapi.row.artlr_type == 'RK' || dataRiwayatRMDokterTerapi.row.artlr_type == 'RP'">
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Alasan Merujuk</h4>
                                      <p>{{dataRiwayatRMDokterTerapi.row.artlr_alasan_rujuk||"-"}}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>{{dataRiwayatRMDokterTerapi.row.artlr_alasan_rujuk||"-"}}</h4>
                                      <p>{{dataRiwayatRMDokterTerapi.row.artlr_alasan_rujuk_text||"-"}}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Faskes</h4>
                                      <p>{{dataRiwayatRMDokterTerapi.row.mrfj_name||"-"}}</p>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Dokter</h4>
                                      <p>{{dataRiwayatRMDokterTerapi.row.artlr_nama_dokter_rujuk||"-"}}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Peralatan Media yang Terpasang</h4>
                                      <p v-if="dataRiwayatRMDokterTerapi.row.artlr_is_media == 'Y'">
                                      {{dataRiwayatRMDokterTerapi.row.artlr_media||"-"}}</p>
                                      <p v-else> - </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Perawatan Pasien yang dibutuhkan</h4>
                                      <p>{{dataRiwayatRMDokterTerapi.row.artlr_perawatan_pasien||"-"}}</p>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Persetujuan Biaya RS Rujukan</h4>
                                      <p>{{dataRiwayatRMDokterTerapi.row.artlr_is_biaya == "Y"?"Ya":"Tidak"}}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Persetujuan Risiko Perjalanan</h4>
                                      <p>{{dataRiwayatRMDokterTerapi.row.artlr_is_risiko == "Y"?"Ya":"Tidak"}}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Kejadian klinis selama dilakukan transfer</h4>
                                      <p v-if="dataRiwayatRMDokterTerapi.row.artlr_is_kejadian_klinis == 'Y'">{{dataRiwayatRMDokterTerapi.row.artlr_kejadian_klinis||"-"}}</p>
                                      <p v-else> - </p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-if="dataRiwayatRMDokterTerapi.row.artlr_type == 'RB'">
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>No Rujukan</h4>
                                      <p> - </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Catatan</h4>
                                      <p>{{dataRiwayatRMDokterTerapi.row.artlr_notes||"-"}}</p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </table>

                          <table class="table table-bordered mt-2">
                            <thead>
                              <tr class="table-secondary">
                                <th colspan="2">Tindak Lanjut</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>Tindak Lanjut</h4>
                                    <template v-if="dataRiwayatRMDokterTerapi.row.apdf_is_edukasi == 'Y'||dataRiwayatRMDokterTerapi.row.apdf_is_kontrol == 'Y'|| dataRiwayatRMDokterTerapi.row.apdf_is_rawat_inap == 'Y'">
                                    <p>{{dataRiwayatRMDokterTerapi.row.apdf_is_edukasi == "Y"?'Edukasi':''}}</p>
                                    <p>{{dataRiwayatRMDokterTerapi.row.apdf_is_kontrol == "Y"?'Kontrol':''}}</p>
                                    <p>{{dataRiwayatRMDokterTerapi.row.apdf_is_rawat_inap == "Y"?'Rawat Inap':''}}</p>
                                    </template>
                                    <span v-else>Tidak ada Tindak Lanjut</span>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <template v-if="dataRiwayatRMDokterTerapi.row.apdf_is_edukasi == 'Y'">
                                    <h4>Catatan Edukasi</h4>
                                    <p>{{dataRiwayatRMDokterTerapi.row.apdf_notes_edukasi||"-"}}</p>
                                    </template>

                                    <template v-if="dataRiwayatRMDokterTerapi.row.apdf_is_kontrol == 'Y'">
                                    <h4>Tanggal Pemeriksaan</h4>
                                    <p v-if="dataRiwayatRMDokterTerapi.row.apdf_notes_kontrol">{{dataRiwayatRMDokterTerapi.row.apdf_notes_kontrol|moment("DD MMM YYYY")}}</p>
                                    <span v-else> - </span>
                                    
                                    <h4 class="mt-2">Catatan Kontrol</h4>
                                    <p>{{dataRiwayatRMDokterTerapi.row.apdf_catatan_kontrol||"-"}}</p>
                                    
                                    </template>
                                    
                                    <template v-if="dataRiwayatRMDokterTerapi.row.apdf_is_rawat_inap == 'Y'">
                                    <h4>Catatan Rawat Inap</h4>
                                    <p>{{dataRiwayatRMDokterTerapi.row.apdf_notes_rawat_inap||"-"}}</p>
                                    </template>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    </template>
                    <span v-else>Tidak Ada Kajian Fisio terapi</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="activeTab == 2 ? 'tab-pane fade show active' : 'tab-pane'" id="lab">
            <div class="row" v-if="Object.keys(dataRiwayatRMLab||{}).length">
                <div class="col-md-6 mb-2">
                <label for="">Data Kajian</label>
                <v-select @input="changeKajianLab($event)" placeholder="Pilih Data Kajian" v-model="dataRiwayatRMLab.row.arm_id" :options="historyKajianLab" label="text" :reduce="v=>v.value"></v-select>
                </div>
                <div class="col-md-12" v-if="dataRiwayatRMLab.row.arm_notes">
                  <b-alert show fade>{{dataRiwayatRMLab.row.arm_notes||"-"}}</b-alert>
                </div>
            </div>
            <div class="row" v-if="Object.keys(dataRiwayatRMLab||{}).length">
                <div class="col-md-6">
                  <b-alert show fade>Nama Inputer : {{dataRiwayatRMLab.row.bu_full_name||"-"}}</b-alert>
                </div>
            </div>
            <div class="row" v-if="Object.keys(dataRiwayatRMLab||{}).length">
              <div class="col-md-12">
                <div class="card">
                    <div class="card-header bg_head_panel">
                    <h5 class="card-title font-weight-semibold">Informasi Pasien</h5>
                    </div>
                    <div class="card-body p-3">
                    <table class="table table-bordered">
                        <tbody>
                        <tr>
                            <td>
                            <div class="result_tab">
                                <h4>No. Rekam Medis</h4>
                                <p>{{dataRiwayatRMLab.row.ap_code||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Nama Pasien</h4>
                                <p>{{dataRiwayatRMLab.row.ap_fullname||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Tanggal Lahir</h4>
                                <p>{{dataRiwayatRMLab.row.ap_dob | moment("DD MMM YYYY")}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Jenis Kelamin</h4>
                                <p>{{dataRiwayatRMLab.row.cg_label||"-"}}</p>
                            </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
              </div>
              <div class="col-md-12">
                <table class="table table-bordered mb-3">
                  <thead>
                    <tr class="table-light">
                      <th colspan="4" class="text-uppercase">Informasi Pemeriksaan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Prioritas Pemeriksaan</h4>
                          <p v-if="dataRiwayatRMLab.dataDokter.appdl_prioritas == 1">CITO<strong class="text-danger"></strong></p>
                          <p v-else>Non CITO</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Catatan Permintaan</h4>
                          <p>{{dataRiwayatRMLab.dataDokter.appdl_catatan||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Catatan Pemeriksaan Parsial</h4>
                          <p>{{dataRiwayatRMLab.dataDokter.appdl_pemeriksaan_parsial||"-"}}</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Tanggal Pemeriksaan</h4>
                          <p v-if="dataRiwayatRMLab.dataDokter.appdl_penunjang_date">{{dataRiwayatRMLab.dataDokter.appdl_penunjang_date|moment("DD MMM YYYY")}}</p>
                          <p v-else> - </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="table-responsive mb-3">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Waktu Pelaksanaan</h4>
                            <p>{{dataRiwayatRMLab.dataDokter.arm_created_date | moment("DD MMMM YYYY, HH:mm")}}</p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Apakah Pasien Berpuasa?</h4>
                            <p>{{dataRiwayatRMLab.dataDokter.appdl_fasting == 'Y' ? 'Ya':'Tidak'}}</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="req-list-resume">
                      <div class="row g-3">
                        <template v-for="(v,k) in dataRiwayatRMLab.dataDokter.appdl_data">
                          <div class="col-md-6 col-lg-6" :key="k+'labData'" v-if="isShowHeadLabV2(k)">
                            <div class="card shadow-0 border">
                              <div class="card-header py-2 bg-light">
                                <h6 class="card-title text-uppercase font-weight-semibold">{{v.head||"-"}}</h6>
                              </div>
                              <div class="card-body py-2">
                                <template v-for="v1,k1 in (v.dubData||[])" >
                                  <div class="req-list" v-if="isShowHeadSubHeadLabV2(k,k1)" :key="k1+'labdatas'">
                                    <h6 class="text-uppercase" v-if="v1.sub">{{v1.sub||"-"}}</h6>
                                    <template v-for="v2,k2 in (v1.data||[])">
                                      <div v-if="isShowLabV2(k,k1,k2)" :key="k2+'labsubdatas'">
                                        <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                        <span class="align-middle font-weight-semibold">{{v2.text||"-"}}</span>
                                        <span v-if="v2.notes">, {{v2.notes}}</span>
                                      </div>
                                    </template>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </div>
                        </template>
                        <div class="col-md-6 col-lg-6" v-if="dataRiwayatRMLab.dataDokter.appdl_hasil_lainnya">
                          <div class="card shadow-0 border">
                            <div class="card-header py-2 bg-light">
                              <h6 class="card-title text-uppercase font-weight-semibold">Item Pemeriksaan Lainnya</h6>
                            </div>
                            <div class="card-body py-2">
                                <div class="req-list">
                                    <div>
                                      <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                      <span class="align-middle font-weight-semibold">{{dataRiwayatRMLab.dataDokter.appdl_hasil_lainnya||"-"}}</span>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        <span v-if="!selectedLabInputV2()" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card">
                      <div class="row">
                        <div class="col-md-12">
                          <table class="table table-bordered table-striped table-sm patient-table">
                            <thead>
                              <tr>
                                <th>Hasil Pemeriksaan </th>
                                <th> Hasil</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(v,k) in (dataRiwayatRMLab.row.appl_hasil||[])" :key="k+'hasil'">
                                <td>{{v.value}}<br/>
                                <small class="text-success" v-if="dataRiwayatRMLab.rowReg.ar_is_verified_lab == 'Y'">
                                Verified</small>
                                <small class="text-danger" v-else>
                                Unverified</small>
                                </td>
                                <td>
                                    <div class="result_tab form-group mb-0">
                                    <a class="btn_view" :href="$parent.$parent.$parent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                    </div>
                                </td>
                              </tr>
                              <tr v-if="!(dataRiwayatRMLab.row.appl_hasil||[]).length">
                                <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span v-else>Tidak Ada Data Laboratorium</span>
          </div>
          <div :class="activeTab == 3 ? 'tab-pane fade show active' : 'tab-pane'" id="radio">
            <div class="row" v-if="Object.keys(dataRiwayatRMRadiologi||{}).length">
                <div class="col-md-6 mb-2">
                <label for="">Data Kajian</label>
                <v-select @input="changeKajianRadiologi($event)" placeholder="Pilih Data Kajian" v-model="dataRiwayatRMRadiologi.row.arm_id" :options="historyKajianRadiologi" label="text" :reduce="v=>v.value"></v-select>
                </div>
                <div class="col-md-12" v-if="dataRiwayatRMRadiologi.row.arm_notes">
                  <b-alert show fade>{{dataRiwayatRMRadiologi.row.arm_notes||"-"}}</b-alert>
                </div>
            </div>
            <div class="row" v-if="Object.keys(dataRiwayatRMRadiologi||{}).length">
                <div class="col-md-6">
                  <b-alert show fade>Nama Inputer : {{dataRiwayatRMRadiologi.row.bu_full_name||"-"}}</b-alert>
                </div>
            </div>
            <div class="row" v-if="Object.keys(dataRiwayatRMRadiologi||{}).length">
              <div class="col-md-12">
                <div class="card">
                    <div class="card-header bg_head_panel">
                    <h5 class="card-title font-weight-semibold">Informasi Pasien</h5>
                    </div>
                    <div class="card-body p-3">
                    <table class="table table-bordered">
                        <tbody>
                        <tr>
                            <td>
                            <div class="result_tab">
                                <h4>No. Rekam Medis</h4>
                                <p>{{dataRiwayatRMRadiologi.row.ap_code||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Nama Pasien</h4>
                                <p>{{dataRiwayatRMRadiologi.row.ap_fullname||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Tanggal Lahir</h4>
                                <p>{{dataRiwayatRMRadiologi.row.ap_dob | moment("DD MMM YYYY")}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Jenis Kelamin</h4>
                                <p>{{dataRiwayatRMRadiologi.row.cg_label||"-"}}</p>
                            </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
              </div>
              
              <div class="col-md-12">
                <table class="table table-bordered mb-3">
                  <thead>
                    <tr class="table-light">
                      <th colspan="4" class="text-uppercase">Informasi Pemeriksaan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Prioritas Pemeriksaan</h4>
                          <p v-if="dataRiwayatRMRadiologi.dataDokter.appdr_prioritas == 1">CITO<strong class="text-danger"></strong></p>
                          <p v-else>Non CITO</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Catatan Permintaan</h4>
                          <p>{{dataRiwayatRMRadiologi.dataDokter.appdr_catatan||"-"}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="result_tab">
                          <h4>Catatan Pemeriksaan Parsial</h4>
                          <p>{{dataRiwayatRMRadiologi.dataDokter.appdr_pemeriksaan_parsial||"-"}}</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="result_tab">
                          <h4>Tanggal Pemeriksaan</h4>
                          <p v-if="dataRiwayatRMRadiologi.dataDokter.appdr_penunjang_date">{{dataRiwayatRMRadiologi.dataDokter.appdr_penunjang_date|moment("DD MMM YYYY")}}</p>
                          <p v-else> - </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="table-responsive mb-3">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Waktu Pelaksanaan</h4>
                            <p>{{dataRiwayatRMRadiologi.dataDokter.arm_created_date | moment("DD MMMM YYYY, HH:mm")}}</p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Pasien Memiliki Alergi Bahan Kontras/Zat?</h4>
                            <p>{{dataRiwayatRMRadiologi.dataDokter.appdr_alergi == 'Y' ? 'Ya':'Tidak'}}</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="req-list-resume">
                      <div class="row g-3">
                        <template v-for="(v,k) in dataRiwayatRMRadiologi.dataDokter.appdr_data">
                          <div class="col-md-6 col-lg-6" :key="k+'radioData'" v-if="isShowHeadRadiologiV2(k)">
                            <div class="card shadow-0 border">
                              <div class="card-header py-2 bg-light">
                                <h6 class="card-title text-uppercase font-weight-semibold">{{
                                  getConfigDynamic(dataRiwayatRMRadiologi.Config.mr.KategoriRadiologi,v.head)||"-"}}</h6>
                              </div>
                              <div class="card-body py-2">
                                <template v-for="v1,k1 in (v.data||[])">
                                  <div v-if="isShowRadiologiV2(k,k1)" :key="k1+'radiosubdatas'">
                                    <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                    <span class="align-middle font-weight-semibold">{{v1.text||"-"}}</span>
                                    <span v-if="v1.notes">, {{v1.notes}}</span>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </div>
                        </template>
                        <div class="col-md-6 col-lg-6" v-if="dataRiwayatRMRadiologi.dataDokter.appdr_hasil_lainnya">
                          <div class="card shadow-0 border">
                            <div class="card-header py-2 bg-light">
                              <h6 class="card-title text-uppercase font-weight-semibold">Item Pemeriksaan Lainnya</h6>
                            </div>
                            <div class="card-body py-2">
                                <div class="req-list">
                                    <div>
                                      <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                      <span class="align-middle font-weight-semibold">{{dataRiwayatRMRadiologi.dataDokter.appdr_hasil_lainnya||"-"}}</span>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        <span v-if="!selectedRadioInputV2()" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card">
                      <div class="row">
                        <div class="col-md-12">
                          <table class="table table-bordered table-striped table-sm patient-table">
                            <thead>
                              <tr>
                                <th>Hasil Pemeriksaan </th>
                                <th> Hasil</th>
                                <th> Nama Dokter</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(v,k) in (dataRiwayatRMRadiologi.row.appr_hasil||[])" :key="k+'hasil'">
                                <td>{{v.value}}</td>
                                <td>
                                    <div class="result_tab form-group mb-0">
                                    <a class="btn_view" v-b-tooltip.hover.right title="Hasil Tindakan Radiologi" :href="$parent.$parent.$parent.uploader(v.file)" target="_blank"><i class="icon-file-download"></i></a>
                                    
                                    <a class="btn_view ml-1" v-b-tooltip.hover.right title="Hasil Bacaan Radiologi" href="javascript:;" @click="downloadReport(dataRiwayatRMRadiologi.row.appr_arm_id,v)" ><i class="icon-file-download"></i></a>

                                    </div>
                                </td>
                                <td>{{v.dokter||"-"}}</td>
                              </tr>
                              <tr v-if="!(dataRiwayatRMRadiologi.row.appr_hasil||[]).length">
                                <td colspan="99" class="text-center">Tidak ada hasil pemeriksaan</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span v-else>Tidak Ada Data Radiologi</span>
          </div>
          <div :class="activeTab == 4 ? 'tab-pane fade show active' : 'tab-pane'" id="fisioterapi">
            <div class="row" v-if="Object.keys(dataRiwayatRMFisio||{}).length">
              <div class="col-md-6 mb-2">
                <label for="">Data Kajian</label>
                <v-select @input="changeKajianFisio($event)" placeholder="Pilih Data Kajian" v-model="dataRiwayatRMFisio.row.arm_id" :options="historyKajianFisio" label="text" :reduce="v=>v.value"></v-select>
              </div>
              
              <div class="col-md-6" v-if="Object.keys(dataRiwayatRMFisio||{}).length">
                <b-alert show fade>Nama Inputer : {{dataRiwayatRMFisio.row.bu_full_name||"-"}}</b-alert>
              </div>
              <div v-if="dataRiwayatRMFisio.row.arm_notes" class="col-md-12">
                <b-alert show fade>{{dataRiwayatRMFisio.row.arm_notes||"-"}}</b-alert>
              </div>
              <div class="col-md-12">
                <div class="card">
                    <div class="card-header bg_head_panel">
                    <h5 class="card-title font-weight-semibold">Informasi Pasien</h5>
                    </div>
                    <div class="card-body p-3">
                    <table class="table table-bordered">
                        <tbody>
                        <tr>
                            <td>
                            <div class="result_tab">
                                <h4>No. Rekam Medis</h4>
                                <p>{{dataRiwayatRMFisio.row.ap_code||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Nama Pasien</h4>
                                <p>{{dataRiwayatRMFisio.row.ap_fullname||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Tanggal Lahir</h4>
                                <p>{{dataRiwayatRMFisio.row.ap_dob | moment("DD MMM YYYY")}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Jenis Kelamin</h4>
                                <p>{{dataRiwayatRMFisio.row.cg_label||"-"}}</p>
                            </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
              </div>
              <template v-if="dataRiwayatRMFisio.row.arm_is_kajian_awal == 'Y'">
                <div class="col-md-12">
                  <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
                    </div>
                    
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="table-responsive">
                            <table class="table table-bordered">
                              <tbody>
                              <tr>
                                  <td>
                                  <div class="result_tab">
                                      <h4>Tekanan Darah</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfa_ttv_tekanan_darah_min || dataRiwayatRMFisio.row.appfa_ttv_tekanan_darah_max">{{dataRiwayatRMFisio.row.appfa_ttv_tekanan_darah_min}} / {{dataRiwayatRMFisio.row.appfa_ttv_tekanan_darah_max}} mmHG</p>
                                      <p v-else> - </p>
                                  </div>
                                  </td>
                                  <td>
                                  <div class="result_tab">
                                      <h4>Nadi</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfa_ttv_nadi">{{dataRiwayatRMFisio.row.appfa_ttv_nadi||"-"}}/mnt</p>
                                      <p v-else> - </p>
                                  </div>
                                  </td>
                                  <td>
                                  <div class="result_tab">
                                      <h4>Gula Darah</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfa_ttv_gula_darah">{{dataRiwayatRMFisio.row.appfa_ttv_gula_darah||"-"}}mg/dL</p>
                                      <p v-else> - </p>
                                  </div>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                  <div class="result_tab">
                                      <h4>Pernafasan</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfa_ttv_pernafasan">{{dataRiwayatRMFisio.row.appfa_ttv_pernafasan||"-"}}x/mnt - Reguler</p>
                                      <p v-else> - </p>
                                  </div>
                                  </td>
                                  <td>
                                  <div class="result_tab">
                                      <h4>SPO2</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfa_ttv_spo2">{{dataRiwayatRMFisio.row.appfa_ttv_spo2||"-"}}%</p>
                                      <p v-else> - </p>
                                  </div>
                                  </td>
                                  <td>
                                  <div class="result_tab">
                                      <h4>Suhu</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfa_ttv_suhu">{{dataRiwayatRMFisio.row.appfa_ttv_suhu||"-"}}C</p>
                                      <p v-else> - </p>
                                  </div>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                  <div class="result_tab">
                                      <h4>Berat Badan</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfa_ttv_weight">{{dataRiwayatRMFisio.row.appfa_ttv_weight||"-"}}kg</p>
                                      <p v-else> - </p>
                                  </div>
                                  </td>
                                  <td>
                                  <div class="result_tab">
                                      <h4>Tinggi</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfa_ttv_height">{{dataRiwayatRMFisio.row.appfa_ttv_height||"-"}}Cm</p>
                                      <p v-else> - </p>
                                  </div>
                                  </td>
                                  <td>
                                  <div class="result_tab">
                                      <h4>Lingkar Kepala</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfa_ttv_lingkar_kepala">{{dataRiwayatRMFisio.row.appfa_ttv_lingkar_kepala||"-"}}cm</p>
                                      <p v-else> - </p>
                                  </div>
                                  </td>
                              </tr>
                              <tr>
                                  <td v-if="dataRiwayatRMFisio.row.ap_usia >= 1">
                                  <div class="result_tab">
                                      <h4>BMI</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfa_ttv_bmi">{{dataRiwayatRMFisio.row.appfa_ttv_bmi}}m2</p>
                                      <p v-else> - </p>
                                  </div>
                                  </td>
                                  <td v-if="dataRiwayatRMFisio.row.ap_usia <= 15">
                                      <div class="result_tab">
                                      <h4>Luas Permukaan Tubuh Anak</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfa_ttv_luas_permukaan_anak">{{dataRiwayatRMFisio.row.appfa_ttv_luas_permukaan_anak}}kg/m2</p>
                                      <p v-else> - </p>
                                      </div>
                                  </td>
                                  <td>
                                  <div class="result_tab">
                                      <h4>Kesadaran</h4>
                                      <p>{{dataRiwayatRMFisio.row.mk_name||"-"}}</p>
                                  </div>
                                  </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Status Fungsional
                      </h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="form-row">
                        <div class="col-md-12">
                          <div class="table-responsive">
                            <table class="table table-bordered">
                              <tbody>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Alat Bantu</h4>
                                      <p>{{dataRiwayatRMFisio.row.appfa_is_alat_bantu == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Alat Bantu</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfa_is_alat_bantu == 'Y'">
                                      {{dataRiwayatRMFisio.row.mab_name||"-"}}
                                      </p>
                                      <p v-else> - </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>ADL (Aktifitas Sehari-hari)</h4>
                                      <p>
                                      {{dataRiwayatRMFisio.row.appfa_adl == 'MANDIRI' ? 'Mandiri' : 'Dibantu'}}
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Pengkajian Nyeri
                      </h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-md-7">
                          <div class="table-responsive">
                            <table class="table table-bordered">
                              <tbody>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Keluhan Utama</h4>
                                      <p>{{dataRiwayatRMFisio.row.appfa_keluhan_utama||"-"}}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Apakah nyerinya berpindah dari satu ke tempat lain</h4>
                                      <p>{{getConfigDynamic(dataRiwayatRMFisio.Config.mr.configNilaiNyeri,dataRiwayatRMFisio.row.appfa_nilai_nyeri)||"-"}}</p>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Berapa Lama nyeri dirasakan</h4>
                                      <p>{{getConfigDynamic(dataRiwayatRMFisio.Config.mr.configLamaNyeri,dataRiwayatRMFisio.row.appfa_lama_nyeri)||"-"}}</p>
                                    </div>
                                  </td>

                                  <td>
                                    <div class="result_tab">
                                      <h4>Rasa Nyeri</h4>
                                      <p>{{
                                        getConfigDynamic(dataRiwayatRMFisio.Config.mr.statusNyeri,dataRiwayatRMFisio.row.appfa_rasa_nyeri)||"-"}}</p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <div class="wrap_anatomy_info">
                            <label for="perKeluhan">Lokasi Nyeri<strong class="text-danger">*</strong></label>
                            <div class="position-relative" id="anatomiNyeri" @click.stop.prevent>
                            <div class="row">
                              <div class="col-md-6">
                                <div class="body_wrapper">
                                  <img :src="$parent.$parent.$parent.assetLocal('img/front-body.png')" class="img-fluid" alt="">
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="body_wrapper">
                                  <img :src="$parent.$parent.$parent.assetLocal('img/back-body.png')" class="img-fluid" alt="">
                                </div>
                              </div>
                            </div>
                            <div class="anatomy-info" v-for="(v,k) in dataRiwayatRMFisio.row.appfa_lokasi_nyeri||[]" :key="k"
                              :style="`top:${v.y}%;left:${v.x}%`">
                              <a href="javascript:;" class="ai-point" v-b-tooltip.hover :html="true"
                                :title="v.value"></a>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Assessment
                      </h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-md-12">
                          <table class="table table-bordered">
                            <tbody>
                              <tr>
                                <td width="30">
                                  <div class="label_code">
                                    <h2>S</h2>
                                  </div>
                                </td>
                                <td>
                                  {{dataRiwayatRMFisio.row.appfa_subjektif||"-"}}
                                </td>
                              </tr>
                              <tr>
                                <td width="30">
                                  <div class="label_code">
                                    <h2>O</h2>
                                  </div>
                                </td>
                                <td>
                                  {{dataRiwayatRMFisio.row.appfa_objektif||"-"}}
                                </td>
                              </tr>
                              <tr>
                                <td width="30">
                                  <div class="label_code">
                                    <h2>A</h2>
                                  </div>
                                </td>
                                <td>
                                  {{dataRiwayatRMFisio.row.appfa_asesmen||"-"}}
                                </td>
                              </tr>
                              <tr>
                                <td width="30">
                                  <div class="label_code">
                                    <h2>P</h2>
                                  </div>
                                </td>
                                <td>
                                  {{dataRiwayatRMFisio.row.appfa_plan||"-"}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Rencana Tindakan
                      </h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-md-12">
                          {{dataRiwayatRMFisio.row.appfa_plan||"-"}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="col-md-6">
                  <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Jadwal Terapi
                      </h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-md-12">
                          <table class="table table-bordered">
                            <tbody>
                              <tr v-for="(v,k) in (dataRiwayatRMFisio.row.appfa_jadwal_permintaan_fisio||[])" :key="k">
                                <td>
                                  <div class="result_tab">
                                    <h4>Jadwal {{k+1}}</h4>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>{{v | moment("DD MMMM YYYY")}}</h4>
                                  </div>
                                </td>
                              </tr>
                              <tr v-if="!(dataRiwayatRMFisio.row.appfa_jadwal_permintaan_fisio||[]).length">
                                <td colspan="99">Tidak ada jadwal terapi</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="col-md-12">
                  <div class="table-responsive mb-3">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Keluhan Utama</h4>
                              <p>{{dataRiwayatRMFisio.row.appfl_keluhan||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Riwayat Penyakit</h4>
                              <p>{{dataRiwayatRMFisio.row.appfl_anamnesa||"-"}}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <div class="result_tab">
                              <h4>Objective</h4>
                              <p>{{dataRiwayatRMFisio.row.appfl_objektif||"-"}}</p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="card">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="table-responsive">
                            <table class="table table-bordered">
                              <tbody>
                              <tr>
                                  <td>
                                  <div class="result_tab">
                                      <h4>Tekanan Darah</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfl_ttv_tekanan_darah_min || dataRiwayatRMFisio.row.appfl_ttv_tekanan_darah_max">{{dataRiwayatRMFisio.row.appfl_ttv_tekanan_darah_min}} / {{dataRiwayatRMFisio.row.appfl_ttv_tekanan_darah_max}} mmHG</p>
                                      <p v-else> - </p>
                                  </div>
                                  </td>
                                  <td>
                                  <div class="result_tab">
                                      <h4>Nadi</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfl_ttv_nadi">{{dataRiwayatRMFisio.row.appfl_ttv_nadi||"-"}}/mnt</p>
                                      <p v-else> - </p>
                                  </div>
                                  </td>
                                  <td>
                                  <div class="result_tab">
                                      <h4>Gula Darah</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfl_ttv_gula_darah">{{dataRiwayatRMFisio.row.appfl_ttv_gula_darah||"-"}}mg/dL</p>
                                      <p v-else> - </p>
                                  </div>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                  <div class="result_tab">
                                      <h4>Pernafasan</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfl_ttv_pernafasan">{{dataRiwayatRMFisio.row.appfl_ttv_pernafasan||"-"}}x/mnt - Reguler</p>
                                      <p v-else> - </p>
                                  </div>
                                  </td>
                                  <td>
                                  <div class="result_tab">
                                      <h4>SPO2</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfl_ttv_spo2">{{dataRiwayatRMFisio.row.appfl_ttv_spo2||"-"}}%</p>
                                      <p v-else> - </p>
                                  </div>
                                  </td>
                                  <td>
                                  <div class="result_tab">
                                      <h4>Suhu</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfl_ttv_suhu">{{dataRiwayatRMFisio.row.appfl_ttv_suhu||"-"}}C</p>
                                      <p v-else> - </p>
                                  </div>
                                  </td>
                              </tr>
                              <tr>
                                  <td>
                                  <div class="result_tab">
                                      <h4>Berat Badan</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfl_ttv_weight">{{dataRiwayatRMFisio.row.appfl_ttv_weight||"-"}}kg</p>
                                      <p v-else> - </p>
                                  </div>
                                  </td>
                                  <td>
                                  <div class="result_tab">
                                      <h4>Tinggi</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfl_ttv_height">{{dataRiwayatRMFisio.row.appfl_ttv_height||"-"}}Cm</p>
                                      <p v-else> - </p>
                                  </div>
                                  </td>
                                  <td>
                                  <div class="result_tab">
                                      <h4>Lingkar Kepala</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfl_ttv_lingkar_kepala">{{dataRiwayatRMFisio.row.appfl_ttv_lingkar_kepala||"-"}}cm</p>
                                      <p v-else> - </p>
                                  </div>
                                  </td>
                              </tr>
                              <tr>
                                  <td v-if="dataRiwayatRMFisio.row.ap_usia >= 1">
                                  <div class="result_tab">
                                      <h4>BMI</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfl_ttv_bmi">{{dataRiwayatRMFisio.row.appfl_ttv_bmi}}m2</p>
                                      <p v-else> - </p>
                                  </div>
                                  </td>
                                  <td v-if="dataRiwayatRMFisio.row.appfl_usia <= 15">
                                      <div class="result_tab">
                                      <h4>Luas Permukaan Tubuh Anak</h4>
                                      <p v-if="dataRiwayatRMFisio.row.appfl_ttv_luas_permukaan_anak">{{dataRiwayatRMFisio.row.appfl_ttv_luas_permukaan_anak}}kg/m2</p>
                                      <p v-else> - </p>
                                      </div>
                                  </td>
                                  <td>
                                  <div class="result_tab">
                                      <h4>Kesadaran</h4>
                                      <p>{{dataRiwayatRMFisio.row.mk_name||"-"}}</p>
                                  </div>
                                  </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Rencana Tindakan</h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-md-6">
                          <div v-for="(v,k) in (dataRiwayatRMFisio.row.appfl_rencana_tindakan||[])" :key="k" class="d-flex align-items-center  mb-2">
                            <i class="icon-checkmark-circle text-success"></i>
                            <span class="ml-1">{{v}}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="result_tab mb-3">
                            <h4>Tanggal Pemeriksaan</h4>
                            <p v-if="dataRiwayatRMFisio.row.appfl_tanggal_kontrol">{{dataRiwayatRMFisio.row.appfl_tanggal_kontrol | moment("DD MMM YYYY")}}</p>
                            <p v-else> - </p>
                          </div>
                          <!--
                          <div class="result_tab mb-3">
                            <h4>Surat Kontrol</h4>
                            <a href="javascript:;" class="btn btn-sm btn-labeled btn-labeled-left bg-green">
                              <b><i class="icon-download"></i></b>
                              Unduh Surat Kontrol
                            </a>
                          </div>
                          -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <span v-else>Tidak Ada Data Fisioterapi</span>
          </div>
          <div :class="activeTab == 5 ? 'tab-pane fade show active' : 'tab-pane'" id="persetujuanpasien">
            <div class="row">
              <div class="col-md-12">
                <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                    <li class="nav-item"><a href="javascript:;" @click="changeTabPersetujuan(0)" :class="activePersetujuanPasien == 0 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Persetujuan Pasien</a></li>
                    <li class="nav-item"><a href="javascript:;" @click="changeTabPersetujuan(1)" :class="activePersetujuanPasien == 1 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Informasi Pasien</a></li>
                    <li v-if="Object.keys(dataRiwayatRMFollowUp||{}).length" class="nav-item"><a href="javascript:;" @click="changeTabPersetujuan(2)" :class="activePersetujuanPasien == 2 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Follow Up Tindakan</a></li>
                </ul>
                <div class="tab-content card-body p-0">
                  <div :class="activePersetujuanPasien == 0 ? 'tab-pane fade show active' : 'tab-pane'"  id="rrm-dr">
                    <table class="table table-bordered table-sm text-uppercase mb-3">
                      <thead>
                        <tr>
                          <th colspan="3">Persetujuan Pasien</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Informasi Ketentuan Pembayaran</h4>
                              <p>Setuju</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Kebutuhan Penerjemah Bahasa</h4>
                              <p>Tidak</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Hasil Pemeriksaan Penunjang Dapat diakses oleh Peserta Didik</h4>
                              <p>Setuju</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Informasi tentang Hak dan Kewajiban</h4>
                              <p>Setuju</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Pelepasan Informasi / Kerahasiaan Informasi</h4>
                              <p>Ya</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Anggota Keluarga Lain yang dapat diberikan Informasi Data-Data Pasien</h4>
                              <p>Yusuf Sumanto, Indra Indraja, Wisnu Bakti</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Informasi Tentang Tata Tertib RS</h4>
                              <p>Setuju</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Hasil Pemeriksaan Penunjang Dapat Diberikan kepada Pihak Penjamin</h4>
                              <p>Setuju</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Fasyankes Tertentu dalam Rangka Rujukan</h4>
                              <p>Setuju</p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="table table-bordered table-sm text-uppercase">
                      <thead>
                        <tr>
                          <th colspan="3">Yang Membuat Pernyataan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Penanggung Jawab,</h4>
                              <p>Nanda Setiawan</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Petugas yang Memberi Penjelasan</h4>
                              <p>Indita Widayanti</p>
                            </div>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div :class="activePersetujuanPasien == 1 ? 'tab-pane fade show active' : 'tab-pane'"  id="rrm-dr">
                    <table class="table table-bordered table-sm text-uppercase">
                      <tbody>
                        <tr>
                          <td width="33%">
                            <div class="result_tab">
                              <h4>No. Rekam Medis</h4>
                              <p>{{row.ap_code||"-"}}</p>
                            </div>
                          </td>
                          <td width="33%">
                            <div class="result_tab">
                              <h4>Nama</h4>
                              <p>{{row.ap_fullname||"-"}}</p>
                            </div>
                          </td>
                          <td width="33%">
                            <div class="result_tab">
                              <h4>NIK</h4>
                              <p>{{row.ap_nik}}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Tempat, Tanggal Lahir</h4>
                              <p>{{row.ap_pob||"-"}}, {{row.ap_dob | moment("DD MMM YYYY")}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Usia</h4>
                              <p>{{row.ap_usia_with_ket||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Kategori Usia</h4>
                              <p>{{row.ap_gol_usia||"-"}}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Jenis Kelamin</h4>
                              <p>{{row.cg_label||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Agama</h4>
                              <p>{{row.ma_name||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Suku</h4>
                              <p>{{row.ap_suku||"-"}}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Pekerjaan</h4>
                              <p>{{row.mp_name||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Pendidikan</h4>
                              <p>{{row.mpen_name||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Nama Ibu Kandung</h4>
                              <p>{{row.ap_nama_ibu||"-"}}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>No. Telpon</h4>
                              <p>{{row.ap_phone_number||"-"}}</p>
                            </div>
                          </td>
                          <td colspan="2">
                            <div class="result_tab">
                              <h4>Email</h4>
                              <p v-if="row.ap_email">{{row.ap_email.trim()||"-"}}</p>
                              <p v-else> - </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Status Pernikahan</h4>
                              <p v-if="row.ap_status_pernikahan == 1">Belum Kawin</p>
                              <p v-else-if="row.ap_status_pernikahan == 2">Kawin</p>
                              <p v-else-if="row.ap_status_pernikahan == 3">Cerai Hidup</p>
                              <p v-else-if="row.ap_status_pernikahan == 4">Cerai Mati</p>
                              <p v-else> - </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Bahasa yang Dikuasai</h4>
                              <p>{{row.ap_language||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Golongan Darah</h4>
                              <p v-if="row.ap_gol_darah">{{row.ap_gol_darah.trim()||"-"}}</p>
                              <p v-else> - </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <div class="result_tab">
                              <h4>Alamat Domisili</h4>
                              <p>{{domisiliAddr||"-"}}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            <div class="result_tab">
                              <h4>Alamat Sesuai KTP</h4>
                              <p>{{ktpAddr||"-"}}</p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div :class="activePersetujuanPasien == 2 ? 'tab-pane fade show active' : 'tab-pane'"  id="rrm-dr">
                    <template v-if="Object.keys(dataRiwayatRMFollowUp||{}).length" >
                      <div class="mb-3">
                          <table class="table table-bordered table-sm">
                              <tbody>
                                  <tr>
                                      <td width="50%">
                                          <div class="result_tab">
                                              <h4>Petugas yang Melaksanakan</h4>
                                              <p>{{dataRiwayatRMFollowUp.row.bu_full_name||"-"}}</p>
                                          </div>
                                      </td>
                                      <td width="50%">
                                          <div class="result_tab">
                                              <h4>Nama Keluarga Pasien </h4>
                                              <p>{{dataRiwayatRMFollowUp.row.appas_pasien_family||"-"}}</p>
                                          </div>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="result_tab">
                                              <h4>Tindakan yang Dilakukan</h4>
                                              <p>{{dataRiwayatRMFollowUp.row.appas_tindakan||"-"}}</p>
                                          </div>
                                      </td>
                                      <td>
                                          <div class="result_tab">
                                              <h4>Konsekuensi dari Tindakan </h4>
                                              <p>{{dataRiwayatRMFollowUp.row.appas_konsekuensi||"-"}}</p>

                                          </div>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="result_tab">
                                              <h4>Tanggal Pemberian Penjelasan Tindakan</h4>
                                              <p>{{dataRiwayatRMFollowUp.row.arm_submit_date | moment("DD MMM YYYY")}}</p>
                                          </div>
                                      </td>
                                      <td>
                                          <div class="result_tab">
                                              <h4>Jam Pemberian Penjelasan Tindakan</h4>
                                              <p>{{dataRiwayatRMFollowUp.row.appas_jam_pemberian_tindakan||"-"}}</p>
                                          </div>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="result_tab">
                                              <h4 class="mb-2">Apakah Pihak Pasien Setuju dengan Tindakan?</h4>
                                              <p>{{dataRiwayatRMFollowUp.row.appas_is_setuju == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                          </div>
                                      </td>
                                      <td>
                                          <div class="result_tab">
                                              <h4 class="mb-1">Unggah Dokumen Persetujuan</h4>
                                              <a class="btn_view" v-if="dataRiwayatRMFollowUp.row.appas_file" :href="$parent.$parent.$parent.uploader(dataRiwayatRMFollowUp.row.appas_file)" target="_blank"><i class="icon-file-download"></i></a>
                                              <span v-else> - </span>
                                          </div>
                                      </td>
                                  </tr>

                                  <tr v-if="dataRiwayatRMFollowUp.row.appas_is_icd9 == 'Y'">
                                      <td>
                                          <div class="result_tab">
                                              <h4 class="mb-2">Waktu Mulai Tindakan</h4>
                                              <p>{{dataRiwayatRMFollowUp.row.appas_start_tindakan||"-"}}</p>
                                          </div>
                                      </td>
                                      <td>
                                          <div class="result_tab">
                                              <h4 class="mb-1">Waktu Selesai Tindakan</h4>
                                              <p>{{dataRiwayatRMFollowUp.row.appas_end_tindakan||"-"}}</p>
                                          </div>
                                      </td>
                                  </tr>
                                  
                                  <tr v-if="dataRiwayatRMFollowUp.row.appas_is_icd9 == 'Y'">
                                      <td>
                                          <div class="result_tab">
                                              <h4 class="mb-2">Alat Medis yang Digunakan</h4>
                                              <p>{{dataRiwayatRMFollowUp.row.appas_alat||"-"}}</p>
                                          </div>
                                      </td>
                                      <td>
                                          <div class="result_tab">
                                              <h4 class="mb-1">Bahan Medis Habis Pakai</h4>
                                              <p>{{dataRiwayatRMFollowUp.row.appas_bahan_medis||"-"}}</p>
                                          </div>
                                      </td>
                                  </tr>

                              </tbody>
                          </table>
                      </div>
                      <div>
                          <table class="table table-bordered table-sm">
                              <thead>
                                  <tr>
                                      <th colspan="4">Yang Membuat Pernyataan,</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td>
                                          <div class="result_tab">
                                              <h4 class="mb-2">Dokter yang Memberi Penjelasan</h4>
                                              <p>{{dataRiwayatRMFollowUp.row.appas_dokter_pemberi_penjelasan||"-"}}</p>
                                          </div>
                                      </td>
                                      <td>
                                          <div class="result_tab">
                                              <h4 class="mb-2">Pasien/Keluarga yang Menerima Penjelasan</h4>
                                              <p>{{dataRiwayatRMFollowUp.row.appas_pasien_penerima_penjelasan||"-"}}</p>
                                          </div>
                                      </td>
                                      <td>
                                          <div class="result_tab">
                                              <h4 class="mb-2">Saksi 1</h4>
                                              <p>{{dataRiwayatRMFollowUp.row.appas_saksi1||"-"}}</p>
                                          </div>
                                      </td>
                                      <td>
                                          <div class="result_tab">
                                              <h4 class="mb-2">Saksi 2</h4>
                                              <p>{{dataRiwayatRMFollowUp.row.appas_saksi2||"-"}}</p>
                                          </div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                    </template>
                    <template v-else>
                      <span class="text-center">Tidak Ada Data</span>
                    </template>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          <div :class="activeTab == 6 ? 'tab-pane fade show active' : 'tab-pane'" id="farmasi">
            <div class="row">
              <div class="col-md-12">
                <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                    <li class="nav-item"><a href="javascript:;" @click="changeTabFarmasi(0)" :class="activeTabFarmasi == 0 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Resep Dari Farmasi</a></li>
                    <li class="nav-item"><a href="javascript:;" @click="changeTabFarmasi(1)" :class="activeTabFarmasi == 1 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Resep Dari Dokter</a></li>
                </ul>
                <div class="tab-content card-body p-0">
                  <div :class="activeTabFarmasi == 0 ? 'tab-pane fade show active' : 'tab-pane'"  id="rrm-dr">
                    <table class="table table-bordered table-sm">
                      <thead>
                        <tr>
                          <th>Jenis</th>
                          <th>Nama</th>
                          <th>Jumlah</th>
                          <th>Frekuensi</th>
                          <th>Keterangan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v,k) in (dataRiwayatRMFarmasi||[])" :key="'resepDokter'+k">
                          <td>
                            <div class="result_tab">
                              <p>{{v.ardf_jenis == 1 ? 'Obat' : v.ardf_jenis == 2 ? 'Alat Kesehatan' : 'Racikan'}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <p v-if="v.ardf_item_id == 99999">
                              {{v.ardf_item_lainnya||"-"}}
                              </p>
                              <p v-else>
                              {{v.ardf_nama||"-"}}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <p>{{v.ardf_jumlah||"-"}} {{v.ardf_satuan||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <p>
                                <span v-if="v.ardf_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
                                <span v-else>{{v.ardf_frekuensi_lainnya || "-"}}</span>
                              </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <p>{{v.ardf_keterangan || "-"}}</p>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="!(dataRiwayatRMFarmasi||[]).length" class="table-info">
                          <td class="text-center" colspan="99">Tidak Ada Data</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div :class="activeTabFarmasi == 1 ? 'tab-pane fade show active' : 'tab-pane'"  id="rrm-dr">
                    <table class="table table-bordered table-sm">
                      <thead>
                        <tr>
                          <th>Jenis</th>
                          <th>Nama</th>
                          <th>Jumlah</th>
                          <th>Frekuensi</th>
                          <th>Keterangan</th>
                        </tr>
                      </thead>
                      <tbody v-if="rowReg.ar_mpo_id != '1'">
                        <tr v-for="(v,k) in (dataRiwayatRMDokter.resepDokter||[])" :key="'resepDokter'+k">
                          <td>
                            <div class="result_tab">
                              <p>{{v.ard_jenis == 1 ? 'Obat' : v.ard_jenis == 2 ? 'Alat Kesehatan' : 'Racikan'}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <p v-if="v.ard_item_id == 99999">
                              {{v.ard_item_lainnya||"-"}}
                              </p>
                              <p v-else>
                              {{v.ard_nama||"-"}}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <p>{{v.ard_jumlah||"-"}} {{v.ard_satuan||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <p>
                              <span v-if="v.ard_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
                              <span v-else>{{v.ard_frekuensi_lainnya || "-"}}</span>
                              </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <p>{{v.ard_keterangan || "-"}}</p>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="!(dataRiwayatRMDokter.resepDokter||[]).length" class="table-info">
                          <td class="text-center" colspan="99">Tidak Ada Data</td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr v-for="(v,k) in (dataRiwayatRMDokterTerapi.resepDokter||[])" :key="'resepDokter'+k">
                          <td>
                            <div class="result_tab">
                              <p>{{v.ard_jenis == 1 ? 'Obat' : v.ard_jenis == 2 ? 'Alat Kesehatan' : 'Racikan'}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <p v-if="v.ard_item_id == 99999">
                              {{v.ard_item_lainnya||"-"}}
                              </p>
                              <p v-else>
                              {{v.ard_nama||"-"}}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <p>{{v.ard_jumlah||"-"}} {{v.ard_satuan||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <p>
                              <span v-if="v.ard_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
                              <span v-else>{{v.ard_frekuensi_lainnya || "-"}}</span>
                              </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <p>{{v.ard_keterangan || "-"}}</p>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="!(dataRiwayatRMDokterTerapi.resepDokter||[]).length" class="table-info">
                          <td class="text-center" colspan="99">Tidak Ada Data</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="activeTab == 7 ? 'tab-pane fade show active' : 'tab-pane'" id="rrm-dr-gizi">
            <div v-if="Object.keys(dataRiwayatRMDokterGizi||{}).length" class="row">
              <div class="col-md-12" v-if="dataRiwayatRMDokterGizi.row.arm_notes">
                <b-alert show fade>{{dataRiwayatRMDokterGizi.row.arm_notes||"-"}}</b-alert>
              </div>
              <div class="col-md-6 mb-2">
              <label for="">Data Kajian</label>
              <v-select @input="changeKajianDokterGizi($event)" placeholder="Pilih Data Kajian" v-model="dataRiwayatRMDokterGizi.row.arm_id" :options="historyKajianDokterGizi" label="text" :reduce="v=>v.value"></v-select>
              </div>
              <div class="col-md-6" v-if="Object.keys(dataRiwayatRMDokterGizi||{}).length">
                <b-alert show fade>Nama Inputer : {{dataRiwayatRMDokterGizi.row.bu_full_name||"-"}}</b-alert>
              </div>
              <div class="col-md-12">
                <div class="card">
                    <div class="card-header bg_head_panel">
                    <h5 class="card-title font-weight-semibold">Informasi Pasien</h5>
                    </div>
                    <div class="card-body p-3">
                    <table class="table table-bordered">
                        <tbody>
                        <tr>
                            <td>
                            <div class="result_tab">
                                <h4>No. Rekam Medis</h4>
                                <p>{{dataRiwayatRMDokterGizi.row.ap_code||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Nama Pasien</h4>
                                <p>{{dataRiwayatRMDokterGizi.row.ap_fullname||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Tanggal Lahir</h4>
                                <p>{{dataRiwayatRMDokterGizi.row.ap_dob | moment("DD MMM YYYY")}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Jenis Kelamin</h4>
                                <p>{{dataRiwayatRMDokterGizi.row.cg_label||"-"}}</p>
                            </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
              </div>

              <div class="col-md-12">

                <div class="card mb-0" v-if="dataRiwayatRMDokterGizi.row.ap_usia > 15">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Anthropometri (Pasien Dewasa)</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table table-sm table-bordered">
                            <tbody>
                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>BB</h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_bb||"-"}} kg</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>TB</h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_tb||"-"}} cm</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>BBI</h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_bbi||"-"}} kg</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>IMT</h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_imt||"-"}} kg/m2</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>LLA </h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_lla||"-"}} %</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>TL</h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_tl||"-"}} %</p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card mb-0" v-else>
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Anthropometri (Pasien Anak)</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table table-sm table-bordered">
                            <tbody>
                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>BB</h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_bb||"-"}} kg</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>TB</h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_tb||"-"}} cm</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>LLA </h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_lla||"-"}} kg</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>LK</h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_lk||"-"}} kg/m2</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>BBI </h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_bbi||"-"}} %</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>BB/U</h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_bbu||"-"}} %</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>TB/U</h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_tbu||"-"}} %</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>BB/TB </h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_bbtb||"-"}} %</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>LLA/U</h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_llau||"-"}} %</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>Hidrat Arang</h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_ha||"-"}} th, {{dataRiwayatRMDokterGizi.row.apg_bln||"-"}}  bulan</p>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">
                                  <div class="result_tab">
                                    <h4>Biokimia</h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_biokimia||"-"}}</p>
                                  </div>
                                </td>
                                <td colspan="2">
                                  <div class="result_tab">
                                    <h4>Fisik</h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_fisik||"-"}}</p>
                                  </div>
                                </td>
                                <td colspan="2">
                                  <div class="result_tab">
                                    <h4>Klinis</h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_klinis||"-"}}</p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Riwayat Gizi</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table table-sm table-bordered">
                            <tbody>
                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>Alergi Makanan</h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_makanan||"-"}}</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>Pola makan</h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_pola_makanan||"-"}}</p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>Total Asupan</h4>
                                    <p>{{dataRiwayatRMDokterGizi.row.apg_total_asupan||"-"}}</p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="col-md-12 mt-3">
                        <div class="table-responsive">
                          <table class="table table-striped table-sm table-bordered">
                            <thead>
                              <tr>
                                <th>Zat Gizi</th>
                                <th>Asupan Aktual</th>
                                <th>Kebutuhan</th>
                                <th>% Kebutuhan</th>
                                <th>Kategori Asupan</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(v,k) in dataRiwayatRMDokterGizi.row.apg_zat_gizi" :key="k+'sss'">
                                <td>
                                  {{v.name||"-"}}
                                </td>
                                <td>
                                  {{v.asupan||"-"}}
                                </td>
                                <td>
                                  {{v.kebutuhan||"-"}}
                                </td>
                                <td>
                                  {{v.kebutuhan_val||"-"}}%
                                </td>
                                <td v-if="k == 0" rowspan="4">
                                  {{dataRiwayatRMDokterGizi.row.apg_kategori_asupan||"-"}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Riwayat Personal</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="table table-sm table-bordered">
                            <tbody>
                              <tr>
                                <td>
                                  <div class="result_tab">
                                    <h4>Deskripsi</h4>
                                    <p>
                                        {{dataRiwayatRMDokterGizi.row.apg_riwayat_personal||"-"}}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div class="result_tab">
                                    <h4>Monitoring dan Evaluasi</h4>
                                    <p>
                                        {{dataRiwayatRMDokterGizi.row.apg_monitoring||"-"}}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header bg-info">
                        <h5 class="card-title font-weight-semibold">Diagnosa dan Inrevensi</h5>
                    </div>
                    <div class="card-body">
                        <table class="table table-bordered">
                        <tbody>
                            <tr v-for="(v,k) in dataRiwayatRMDokterGizi.dataGiziDiagnosa" :key="k+'diagnosaDok2'">
                            <td>
                                <div class="result_tab" v-if="!v.mig_code">
                                <h4>Diagnosis #{{k+1}}</h4>
                                <p>{{v.apgd_diagnosa||"-"}}</p>
                                </div>
                                <div class="result_tab" v-else>
                                <h4>Diagnosis #{{k+1}}</h4>
                                <p>{{v.mig_code ? (v.mig_code + " - " +v.mig_name) : "-"}}</p>
                                </div>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>

                    <div class="card-body">
                        <table class="table table-bordered">
                        <tbody>
                            <tr v-for="(v,k) in dataRiwayatRMDokterGizi.dataGiziTindakan" :key="k+'diagnosaDok'">
                            <td>
                                <div class="result_tab" v-if="!v.migt_code">
                                <h4>Intervensi #{{k+1}}</h4>
                                <p>{{v.apgt_tindakan||"-"}}</p>
                                </div>
                                <div class="result_tab" v-else>
                                <h4>Intervensi #{{k+1}}</h4>
                                <p>{{v.migt_code ? (v.migt_code + " - " +v.migt_name) : "-"}}</p>
                                </div>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span v-else>Tidak Ada Kajian Dokter Gizi</span>
          </div>
        </div>
    </div>
</template>


<script>

import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')
import Config from '@/libs/Config'

export default{
    props: {
        dataRiwayatRMDokter: Object,
        dataRiwayatRMDokterTerapi: Object,
        dataRiwayatRMDokterGizi: Object,
        dataRiwayatRMFisio: Object,
        dataRiwayatRMRadiologi: Object,
        dataRiwayatRMLab: Object,
        dataRiwayatRMFarmasi: Array,
        dataRiwayatRMFollowUp: Object,
        row: Object,
        rowReg: Object,

        historyKajianPerawat: Array,
        historyKajianDokter: Array,
        historyKajianDokterTerapi: Array,
        historyKajianDokterGizi: Array,
        historyKajianFisio: Array,
        historyKajianRadiologi: Array,
        historyKajianLab: Array,
    },
    computed: {    
      Config(){ return Config },
      ktpAddr(){
        if(this.row.ap_address && this.row.ap_rt && this.row.ap_rw && this.row.ap_kelurahan && this.row.ap_kecamatan && this.row.ap_kota){
          return (this.row.ap_address||"-") + "RT."+(this.row.ap_rt||"-")+"/"+(this.row.ap_rw||"-")+", DESA "+
          (this.row.ap_kelurahan||"-")+", KEC."+(this.row.ap_kecamatan||"-")+ "KAB/KOTA"+(this.row.ap_kota||"-")
        }else{
          return "-"
        }
      },
      domisiliAddr(){
        if(this.row.ap_dom_address && this.row.ap_dom_rt && this.row.ap_dom_rw && this.row.ap_dom_kelurahan && this.row.ap_dom_kecamatan && this.row.ap_dom_kota){
          return (this.row.ap_dom_address||"-") + "RT."+(this.row.ap_dom_rt||"-")+"/"+(this.row.ap_dom_rw||"-")+", DESA "+
          (this.row.ap_dom_kelurahan||"-")+", KEC."+(this.row.ap_dom_kecamatan||"-")+ "KAB/KOTA"+(this.row.ap_dom_kota||"-")
        }else{
          return "-"
        }
      },
    },
    data(){
        return {
          activeTab : 1,
          activeTabDokter : 1,
          activeAnat: 0,
          activePersetujuanPasien: 0,
          activeTabFarmasi: 0,
          showICDRM: false,
          showICDRMDiagnosa: false,
          showICDRMTindakan: false,
          
          showICDUPL: false,
          showICDUPLDiagnosa: false,
          showICDUPLTindakan: false,
          keadaanUmum: false
        }
    },
    mounted() {
      if(Object.keys(this.dataRiwayatRMDokterGizi||{}).length){
        this.activeTabDokter = 3
      }else if(Object.keys(this.dataRiwayatRMDokterTerapi||{}).length){
        this.activeTabDokter = 2        
      }else{
        this.activeTabDokter = 1
      }
    },
    methods: {
        downloadReportBacaanDokter(arm_id,v){
          let data = {exptype: 'xlsx', type: "export", id: arm_id, row: v}
          let self = this

          $.ajax({
              type: "POST",
              url: process.env.VUE_APP_API_URL + `/report/${'downloadReportBacaanradiologiDokter'}?token=IXs1029102asoaksoas102901290`,
              data: data,
              cache: false,
              xhrFields:{
                  responseType: 'blob'
              },
              success: data => 
              {
                  self.$parent.$parent.$parent.loadingOverlay = false

                  var link = document.createElement('a')
                  link.href = window.URL.createObjectURL(data)
                  link.download = `Laporan-Hasil-Bacaan-Dokter-${moment().format("YYYY-MM-DD")}.pdf`
                  link.click()
              },
              fail: data => {
                  self.$parent.$parent.$parent.loadingOverlay = false

                  alert('Not downloaded')
              }
          })
        },
        downloadReport(arm_id,v){
          let data = {exptype: 'xlsx', type: "export", id: arm_id, row: v}
          let self = this

          $.ajax({
              type: "POST",
              url: process.env.VUE_APP_API_URL + `/report/${'downloadReportradiologi'}?token=IXs1029102asoaksoas102901290`,
              data: data,
              cache: false,
              xhrFields:{
                  responseType: 'blob'
              },
              success: data => 
              {
                  self.$parent.$parent.$parent.loadingOverlay = false

                  var link = document.createElement('a')
                  link.href = window.URL.createObjectURL(data)
                  link.download = `Laporan-Hasil-Bacaan-${moment().format("YYYY-MM-DD")}.pdf`
                  link.click()
              },
              fail: data => {
                  self.$parent.$parent.$parent.loadingOverlay = false

                  alert('Not downloaded')
              }
          })
        },

        changeTabPersetujuan(k){
          this.activePersetujuanPasien = k
        },
        
        changeTabFarmasi(k){
          this.activeTabFarmasi = k
        },

        changeTabAnat(k){
          this.activeAnat = k
        },

        countVal(data){
          let total = 0
          for(let i = 0; i < (data||[]).length; i++){
              if(data[i]['value'] == "Y"){
                  total += 1
              }
          }
          return total
        },
        changeTab(e){
            this.activeTab = e
        },
        changeTabDokter(e){
            this.activeTabDokter = e
        },
        changeKajianDokter(e){
            this.$parent.$parent.$parent.loadingOverlay = true
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.dataRiwayatRMDokter.row.ap_id+'/'+e+'?regId='+this.$route.query.regId, 
            ).then(res=>{
                this.$parent.$parent.$parent.loadingOverlay = false
                this.$parent.$parent.$parent.dataRiwayatRMDokter = res.data
                this.$parent.$parent.$parent.dataRiwayatRMDokter.Config = this.Config
                this.$parent.$parent.$parent.openRwiayatRM = true
            })
        },
        
        changeKajianDokterTerapi(e){
            this.$parent.$parent.$parent.loadingOverlay = true
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.dataRiwayatRMDokterTerapi.row.ap_id+'/'+e+'?regId='+this.$route.query.regId, 
            ).then(res=>{
                this.$parent.$parent.$parent.loadingOverlay = false
                this.$parent.$parent.$parent.dataRiwayatRMDokterTerapi = res.data
                this.$parent.$parent.$parent.dataRiwayatRMDokterTerapi.Config = this.Config
                this.$parent.$parent.$parent.openRwiayatRM = true
            })
        },
        
        changeKajianDokterGizi(e){
            this.$parent.$parent.$parent.loadingOverlay = true
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.dataRiwayatRMDokterGizi.row.ap_id+'/'+e+'?regId='+this.$route.query.regId, 
            ).then(res=>{
                this.$parent.$parent.$parent.loadingOverlay = false
                this.$parent.$parent.$parent.dataRiwayatRMDokterGizi = res.data
                this.$parent.$parent.$parent.dataRiwayatRMDokterGizi.Config = this.Config
                this.$parent.$parent.$parent.openRwiayatRM = true
            })
        },

        changeKajianFisio(e){
            this.$parent.$parent.$parent.loadingOverlay = true
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.dataRiwayatRMFisio.row.ap_id+'/'+e+'?regId='+this.$route.query.regId, 
            ).then(res=>{
                this.$parent.$parent.$parent.loadingOverlay = false
                this.$parent.$parent.$parent.dataRiwayatRMFisio = res.data
                this.$parent.$parent.$parent.dataRiwayatRMFisio.Config = this.Config
                this.$parent.$parent.$parent.openRwiayatRM = true
            })
        },

        changeKajianRadiologi(e){
            this.$parent.$parent.$parent.loadingOverlay = true
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.dataRiwayatRMRadiologi.row.ap_id+'/'+e+'?regId='+this.$route.query.regId, 
            ).then(res=>{
                this.$parent.$parent.$parent.loadingOverlay = false
                this.$parent.$parent.$parent.dataRiwayatRMRadiologi = res.data
                this.$parent.$parent.$parent.dataRiwayatRMRadiologi.Config = this.Config
                this.$parent.$parent.$parent.openRwiayatRM = true
            })
        },

        changeKajianLab(e){
            this.$parent.$parent.$parent.loadingOverlay = true
            Gen.apiRest(
                "/get/"+'RekamMedis'+'/'+this.dataRiwayatRMLab.row.ap_id+'/'+e+'?regId='+this.$route.query.regId, 
            ).then(res=>{
                this.$parent.$parent.$parent.loadingOverlay = false
                this.$parent.$parent.$parent.dataRiwayatRMLab = res.data
                this.$parent.$parent.$parent.dataRiwayatRMLab.Config = this.Config
                this.$parent.$parent.$parent.openRwiayatRM = true
            })
        },
    
        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },

        isShowHeadLab(i){
          let isData = 0
          for(let j = 0; j < (this.dataRiwayatRMDokter.row.appdl_data[i]['dubData']||[]).length; j++){
            for(let k = 0; k < (this.dataRiwayatRMDokter.row.appdl_data[i]['dubData'][j]['data']||[]).length; k++){  
              if(this.dataRiwayatRMDokter.row.appdl_data[i]['dubData'][j]['data'][k]['selected']){
                isData += 1
              }
            }
          }
          return isData
        },
        isShowHeadSubHeadLab(i,j){
          let isData = 0
          for(let k = 0; k < (this.dataRiwayatRMDokter.row.appdl_data[i]['dubData'][j]['data']||[]).length; k++){  
            if(this.dataRiwayatRMDokter.row.appdl_data[i]['dubData'][j]['data'][k]['selected']){
              isData += 1
            }
          }
          return isData
        },
        isShowLab(i,j,k){
          let isData = 0
          if(this.dataRiwayatRMDokter.row.appdl_data[i]['dubData'][j]['data'][k]['selected']){
            isData += 1
          }
          return isData
        },

        isShowHeadRadiologi(j){
          let isData = 0
          for(let k = 0; k < (this.dataRiwayatRMDokter.row.appdr_data[j]['data']||[]).length; k++){  
            if(this.dataRiwayatRMDokter.row.appdr_data[j]['data'][k]['selected']){
              isData += 1
            }
          }
          return isData
        },
        isShowRadiologi(j,k){
          let isData = 0
          if(this.dataRiwayatRMDokter.row.appdr_data[j]['data'][k]['selected']){
            isData += 1
          }
          return isData
        },
        
        selectedLabInput(){
          let data = []
          for(let ik = 0; ik < (this.dataRiwayatRMDokter.row.appdl_data||[]).length; ik++){
            for(let jk = 0; jk < (this.dataRiwayatRMDokter.row.appdl_data[ik]['dubData']||[]).length; jk++){
              for(let kk = 0; kk < (this.dataRiwayatRMDokter.row.appdl_data[ik]['dubData'][jk]['data']||[]).length; kk++){  
                  if(this.dataRiwayatRMDokter.row.appdl_data[ik]['dubData'][jk]['data'][kk]['selected']){
                    data.push(this.dataRiwayatRMDokter.row.appdl_data[ik]['dubData'][jk]['data'][kk]['text'])
                  }
              }
            }
          }
          if(this.dataRiwayatRMDokter.row.appdl_hasil_lainnya){
            data.push(this.dataRiwayatRMDokter.row.appdl_hasil_lainnya)
          }
          return data.join(", ")
        },
       
        selectedRadioInput(){
          let data = []
          for(let i = 0; i < (this.dataRiwayatRMDokter.row.appdr_data||[]).length; i++){
            for(let j = 0; j < (this.dataRiwayatRMDokter.row.appdr_data[i]['data']||[]).length; j++){  
                if(this.dataRiwayatRMDokter.row.appdr_data[i]['data'][j]['selected']){
                  data.push(this.dataRiwayatRMDokter.row.appdr_data[i]['data'][j]['text'])
                }
            }
          }
          if(this.dataRiwayatRMDokter.row.appdr_hasil_lainnya){
            data.push(this.dataRiwayatRMDokter.row.appdr_hasil_lainnya)
          }
          return data.join(", ")
        },



        isShowHeadLabV2(i){
          let isData = 0
          for(let j = 0; j < (this.dataRiwayatRMLab.dataDokter.appdl_data[i]['dubData']||[]).length; j++){
            for(let k = 0; k < (this.dataRiwayatRMLab.dataDokter.appdl_data[i]['dubData'][j]['data']||[]).length; k++){  
              if(this.dataRiwayatRMLab.dataDokter.appdl_data[i]['dubData'][j]['data'][k]['selected']){
                isData += 1
              }
            }
          }
          return isData
        },
        isShowHeadSubHeadLabV2(i,j){
          let isData = 0
          for(let k = 0; k < (this.dataRiwayatRMLab.dataDokter.appdl_data[i]['dubData'][j]['data']||[]).length; k++){  
            if(this.dataRiwayatRMLab.dataDokter.appdl_data[i]['dubData'][j]['data'][k]['selected']){
              isData += 1
            }
          }
          return isData
        },
        isShowLabV2(i,j,k){
          let isData = 0
          if(this.dataRiwayatRMLab.dataDokter.appdl_data[i]['dubData'][j]['data'][k]['selected']){
            isData += 1
          }
          return isData
        },

        isShowHeadRadiologiV2(j){
          let isData = 0
          for(let k = 0; k < (this.dataRiwayatRMRadiologi.dataDokter.appdr_data[j]['data']||[]).length; k++){  
            if(this.dataRiwayatRMRadiologi.dataDokter.appdr_data[j]['data'][k]['selected']){
              isData += 1
            }
          }
          return isData
        },
        isShowRadiologiV2(j,k){
          let isData = 0
          if(this.dataRiwayatRMRadiologi.dataDokter.appdr_data[j]['data'][k]['selected']){
            isData += 1
          }
          return isData
        },
        
        selectedLabInputV2(){
          let data = []
          for(let ik = 0; ik < (this.dataRiwayatRMLab.dataDokter.appdl_data||[]).length; ik++){
            for(let jk = 0; jk < (this.dataRiwayatRMLab.dataDokter.appdl_data[ik]['dubData']||[]).length; jk++){
              for(let kk = 0; kk < (this.dataRiwayatRMLab.dataDokter.appdl_data[ik]['dubData'][jk]['data']||[]).length; kk++){  
                  if(this.dataRiwayatRMLab.dataDokter.appdl_data[ik]['dubData'][jk]['data'][kk]['selected']){
                    data.push(this.dataRiwayatRMLab.dataDokter.appdl_data[ik]['dubData'][jk]['data'][kk]['text'])
                  }
              }
            }
          }
          if(this.dataRiwayatRMLab.dataDokter.appdl_hasil_lainnya){
            data.push(this.dataRiwayatRMLab.dataDokter.appdl_hasil_lainnya)
          }
          return data.join(", ")
        },
       
        selectedRadioInputV2(){
          let data = []
          for(let i = 0; i < (this.dataRiwayatRMRadiologi.dataDokter.appdr_data||[]).length; i++){
            for(let j = 0; j < (this.dataRiwayatRMRadiologi.dataDokter.appdr_data[i]['data']||[]).length; j++){  
                if(this.dataRiwayatRMRadiologi.dataDokter.appdr_data[i]['data'][j]['selected']){
                  data.push(this.dataRiwayatRMRadiologi.dataDokter.appdr_data[i]['data'][j]['text'])
                }
            }
          }
          if(this.dataRiwayatRMRadiologi.dataDokter.appdr_hasil_lainnya){
            data.push(this.dataRiwayatRMRadiologi.dataDokter.appdr_hasil_lainnya)
          }
          return data.join(", ")
        }
    }
}
</script>
